import React, { useState, useRef } from "react";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import Header2 from "../common/header-component/header2";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import ScrewRotation from "./Screw Rotation Speed/ScrewRotation";
import BackPressure from "./Back Pressure/BackPressure";
import DataService from "../../services/ApiService";
import { Button } from "reactstrap";

const Meltpreparation = () => {

  const history = useHistory();

  // To store the session Id getting from URL
  const [MoldId, setMoldId] = useState();
  const [MoldName, setMoldName] = useState("mold");
  const [Session_Name, setSession_Name] = useState("");

  // Declare the names of the tab's
  let headertext = [
    { text: "Screw Rotation Speed" },
    { text: "Back Pressure" },
  ];

  const showAlert = useRef(false);

  const GoToMolds = useRef(false);

  // ****************** States and functions for all the study ***************************

  // Session Id getting from the URL
  const [Session_Id, setSession_Id] = useState();

  // Boolean variable to switch between the save and update button
  const ToSaveScrewData = useRef(true);
  const ToSaveBackData = useRef(true);

  const ScrewData = useRef([]);
  const BackData = useRef([]);

  // Event to Call the POST request API and save the data
  const saveData = () => {
    return new Promise((resolve, reject) => {
      if (ToSaveScrewData.current) {
        // console.log(ScrewData.current)

        DataService.SaveScrewRotationData(ScrewData.current)
          .then((res) => {

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveScrewData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });
      } else {
        // console.log(ScrewData.current)

        DataService.UpdateScrewRotationData(Session_Id, ScrewData.current)
          .then((res) => {

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveScrewData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });
      }

      if (ToSaveBackData.current) {
        // console.log(BackData.current)

        DataService.SaveBackPressureData(BackData.current)
          .then((res) => {

            // console.log(res)
            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveBackData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });
      } else {
        // console.log(BackData.current)

        DataService.UpdateBackPressureData(Session_Id, BackData.current)
          .then((res) => {

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveBackData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });
      }

      resolve();
    });
  };

  // These are the event's which will get called when clicked on the respective step's tab and once they are called they render the component of that respective tab which we have imported above
  function content0() {
    return (
      <div>
        <ScrewRotation
          setSession_Id={setSession_Id}
          setSession_Name={setSession_Name}
          setMoldId={setMoldId}
          setMoldName={setMoldName}
          ScrewData={ScrewData}
          showAlert={showAlert}
          ToSaveScrewData={ToSaveScrewData}
        />
      </div>
    );
  }

  function content1() {
    return (
      <div>
        <BackPressure
          showAlert={showAlert}
          BackData={BackData}
          ToSaveBackData={ToSaveBackData}
        />
      </div>
    );
  }

  // This function will be called when the user clicks on the save button of the study
  const SavedModal = () => {
    saveData().then(() => {
      const saveddialogBox = document.getElementById("SaveddialogBox");

      saveddialogBox.classList.remove("hidden");

      showAlert.current = false
    });
  };

  // Instance of dialog box Asking for saving data before leaving study
  const dialogBox = document.getElementById("dialogBox");

  // Instance of dialog box which will be shown after saving
  const SavedandExitdialogBox = document.getElementById(
    "SavedandExitdialogBox"
  );

  // Event to get back to Session's
  const BackToMold = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
    } else {
      if (GoToMolds.current) {
        history.push("/dashboard");
      } else {
        history.push(
          `/mold`
        );
      }
    }
  };

  const BackToDashboard = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
      GoToMolds.current = true;
    } else {
      history.push("/dashboard");
    }
  };

  // Event to close the saving confirmation dialog
  const closeDialog = () => {
    const dialogBox = document.getElementById("dialogBox");

    dialogBox.classList.add("hidden");
  };

  // Event to finally close and exit study after saving
  const closeandExitSavedDialog = () => {
    const saveddialogBox = document.getElementById("SavedandExitdialogBox");

    saveddialogBox.classList.add("hidden");

    if (GoToMolds.current) {
      history.push("/dashboard");
    } else {
      history.push(
        `/mold`
      );
    }
  };

  // Event to close the dialog which will be shown after saving
  const closeSavedDialog = () => {
    const saveddialogBox = document.getElementById("SaveddialogBox");

    saveddialogBox.classList.add("hidden");
  };

  const SaveandExit = () => {
    saveData().then(() => {
      closeDialog();

      SavedandExitdialogBox.classList.remove("hidden");
    });
  };

  return (
    <>
      <Header2 Title="Melt Preparation" />

      {/* To alert while going back to sessions page */}
      <div id="dialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Do you want to save Melt Preparation ? </p>
          <Button className="mr-1" id="closeDialogBtn" onClick={SaveandExit}>
            {" "}
            Yes{" "}
          </Button>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeandExitSavedDialog}
          >
            {" "}
            No{" "}
          </Button>
          <Button className="mr-1" id="closeDialogBtn" onClick={closeDialog}>
            {" "}
            Cancel{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved and when clicked on close button exit */}
      <div id="SavedandExitdialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeandExitSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved, when clicked on saved button */}
      <div id="SaveddialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      <div className="mt-2 container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex">
            <div>
              <span
                className="BreadCrum"
                onClick={BackToDashboard}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Dashboard{" "}
              </span>
            </div>

            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>

            <div>
              <span
                className="BreadCrum"
                onClick={BackToMold}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Mold{" "}
              </span>
            </div>

            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>

            <div>
              <span style={{ fontSize: "14px" }}>
                {" "}
                Melt Preparation{" "}
              </span>
            </div>
          </div>

          {/* <div className="d-flex">
            <div>
              <span className="Mold-Session">
                {" "}
                Mold Id : {MoldName ? atob(MoldName) : ""}{" "}
              </span>
            </div>

            <div className="ml-4">
              <span className="Mold-Session">
                {" "}
                Session Name : {Session_Name}{" "}
              </span>
            </div>
          </div> */}
        </div>

        {/* <div className="study-container"> */}
        <div className="d-flex">
          <div /*style={{ width: "82%" }}*/ className="col-md-10">
            {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}
            <TabComponent heightAdjustMode="Auto" id="defaultTab">
              <TabItemsDirective>
                <TabItemDirective header={headertext[0]} content={content0} />
                <TabItemDirective header={headertext[1]} content={content1} />
              </TabItemsDirective>
            </TabComponent>
          </div>

          <div className="col-md-2">

            <button
              className="btn btn-secondary btn-air-secondary"
              type="button"
              onClick={SavedModal}
            >
              {" "}
              Save{" "}
            </button>

            {/* <button
                className="btn btn-secondary btn-air-secondary mr-2 ml-2"
                type="button"
                onClick={SavedModal}
              >
                {" "}
                Print{" "}
              </button> */}

          </div>

        </div>
        {/* </div> */}

        <ToastContainer />
      </div>
    </>
  );
};

export default Meltpreparation;
