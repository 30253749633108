import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const SessionCreationForm = ({
  ModalStates,
  ToggleCreateSessionState,
  handleAddSessionFormChange,
  addSessionData,
  handleAddSessionFormSubmit,
}) => {
  let defaultDate, date;

  let NewDate = new Date();
  let TodaysDate = NewDate.toISOString().split("T")[0];

  const today = new Date().toISOString().split("T")[0];

  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    if (!addSessionData.Session_Name) {
      setError(true);
    } else if (!addSessionData.Date) {
      date = new Date();
      defaultDate = date.toDateString();
      addSessionData.Date = defaultDate;
      ToggleCreateSessionState();
      handleAddSessionFormSubmit(e);
    } else {
      ToggleCreateSessionState();
      handleAddSessionFormSubmit(e);
    }
  };

  const setDate = (e) => {
    if (e.target.value) {
      date = new Date(e.target.value);
      defaultDate = date.toDateString();
      addSessionData.Date = defaultDate;
    }
  };

  const onClose = () => {
    addSessionData.Session_Name = "";
    ToggleCreateSessionState();
    setError(false);
  };

  return (
    <div>
      <form>
        <Modal isOpen={ModalStates.CreateSession.visibility} centered={true}>
          <ModalHeader>
            {" "}
            {ModalStates.CreateSession.ModuleTitle} {"->"} New Session{" "}
          </ModalHeader>
          <ModalBody>
            <div>
              {" "}
              {error ? (
                <span style={{ color: "red" }}>
                  {" "}
                  *Please enter session name{" "}
                </span>
              ) : (
                ""
              )}{" "}
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="lbl_style">Session Name :</label>
                </div>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control"
                  name="Session_Name"
                  type="text"
                  onKeyPress={(event) => {
                    if (/[^a-zA-Z0-9\s]/g.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleAddSessionFormChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="lbl_style">Date :</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="input-group">
                  <input
                    className="form-control"
                    id="dateRequired"
                    type="date"
                    name="Date"
                    defaultValue={TodaysDate}
                    onChange={setDate}
                    onClick={setDate}
                    max={today}
                    required
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" onClick={handleSubmit}>
              {" "}
              Create{" "}
            </Button>
            <Button color="primary" onClick={onClose}>
              {" "}
              Cancel{" "}
            </Button>
          </ModalFooter>
        </Modal>
      </form>
    </div>
  );
};

export default SessionCreationForm;
