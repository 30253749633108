import React from "react";
import ProcessMachineGrid from "./ProcessMachineGrid";

const ProcessMachine = ({
  MachineDataList,
  SelectedMachine,
  ProcessRefMachineData,
  ProcessTgtMachineData,
  PopulateMMGrid, SelectedMold
}) => {
  return (
    <>
      <div>
        <ProcessMachineGrid
          SelectedMold={SelectedMold}
          MachineDataList={MachineDataList}
          SelectedMachine={SelectedMachine}
          ProcessRefMachineData={ProcessRefMachineData}
          ProcessTgtMachineData={ProcessTgtMachineData}
          PopulateMMGrid={PopulateMMGrid}
        />
      </div>
    </>
  );
};

export default ProcessMachine;
