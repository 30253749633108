import React, { useState, useRef, useEffect } from "react";
import { Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// import {
//   TabComponent,
//   TabItemDirective,
//   TabItemsDirective,
// } from "@syncfusion/ej2-react-navigations";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";

import { useParams } from "react-router-dom";
import PCGrid from "./PCGrid";
import CapabilityAnalysis from "./CapabilityAnalysis";
import DataService from "../../../services/ApiService";
import { getRangeIndexes, getRangeAddress } from "@syncfusion/ej2-spreadsheet";
import { getCell } from "@syncfusion/ej2-react-spreadsheet";
import ControlChart from "./ControlChart";
import { toast } from "react-toastify";
import "../../../assets/custom-stylesheet/grid_stylecss.css";
import Attribute from "../Attribute/Attribute";
import PostMold from "../PostMold/PostMold";

import {
  calculateCp,
  calculateCpk,
  calculatePpk,
  calculatePp,
  calculateDataRange,
  calculateAverage,
  calculateOverallSD,
  calculateMean,
  calculatePotentialSD,
  calculatePPM,
} from "./CalcFunctions";

const ProcessCapability = ({
  setSession_Id,
  setSession_Name,
  setMoldId,
  setMoldName,
  SavedModal,
  showAlert,
  ToSaveQualityData,
  QualityData,

  ToSaveAttributeData,
  AttributeData,

  ToSavePostMoldData,
  PostMoldData,
}) => {
  let PCSpreadsheet = useRef();

  // To store the session Id getting from URL
  const [SessionId, setSessionId] = useState();

  // Getting session Id from URL
  var { sessionId, sessionName, moldId, moldName } = useParams();

  const [LimitValidationModal, setLimitValidationModal] = useState(false);

  const ToggleLimitValidationModal = () => {
    setLimitValidationModal(!LimitValidationModal);
  };

  const [SGSizeModal, setSGSizeModal] = useState(false);

  const ToggleSGSizeModal = () => {
    setSGSizeModal(!SGSizeModal);
  };

  const [SelectedColData, setSelectedColData] = useState({
    values: [],
    char_name: null,
    sub_group_size: null,
    upper_limit: null,
    lower_limit: null,
    target: null,
  });

  const [SelectedColHeader, setSelectedColHeader] = useState();

  const [ChartData, setChartData] = useState([]);

  const [computeClicked, setComputeClicked] = useState(false);

  // Boolean variable to switch between the save and update button
  const [showSave, setShowSave] = useState(true);

  const [yMax, setYMax] = useState();
  const [xstart, setxstart] = useState();
  const [xend, setxend] = useState();

  const [Bin_Interval, setBin_Interval] = useState(null);

  const [SelectedCol, setSelectedCol] = useState();

  const [HeaderArray, setHeaderArray] = useState([]);

  const addColumn = () => {
    setPCSheetColCount(PCSheetColCount + 1);
    setColCount(colCount + 1);

    PCSpreadsheet.current.getActiveSheet().colCount = colCount + 1;
  };

  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (tab) => {
    // setActiveTab(tabKey);
    if (activeTab !== tab) setActiveTab(tab);

    if (tab === 2) GetHeaders();
  };

  // State to Toggle the Delete Confirmation Modal.
  const [DeleteAlertToggle, setDeleteAlertToggle] = useState(false);
  const [DeleteConfirmToggle, setDeleteConfirmToggle] = useState(false);

  const DeleteModalAlertToggle = () => {
    setDeleteAlertToggle(!DeleteAlertToggle);
  };

  const DeleteModalConfirmToggle = () => {
    // console.log(SelectedCol)

    if (!SelectedCol || colCount <= 2) {
      setDeleteAlertToggle(!DeleteAlertToggle);
    } else {
      setDeleteConfirmToggle(!DeleteConfirmToggle);
    }
  };

  const deleteColumn = () => {
    PCSpreadsheet.current.delete(SelectedCol, SelectedCol, "Column");

    for (let j = 1; j < PCSheetColCount; j++) {
      for (let i = 0; i < PCGridData.length; i++) {
        delete PCGridData[i][`value${SelectedCol}`];
        // console.log(PCGridData[i][`value${SelectedCol}`])
      }
    }

    // console.log(PCGridData)

    setPCSheetColCount(parseInt(PCSheetColCount) - 1);

    setColCount(parseInt(colCount) - 1);

    PCSpreadsheet.current.refresh();

    setDeleteConfirmToggle(!DeleteConfirmToggle);
  };

  const [PCGridData, setPCGridData] = useState([]);

  const [colCount, setColCount] = useState(2);

  const [PCSheetColCount, setPCSheetColCount] = useState(2);

  const [PCSheetRowCount, setPCSheetRowCount] = useState(105);

  const [rowToBeDeleted, setRowToBeDeleted] = useState();

  // As the user enter's the number of row's it get's set in this variable.
  const [row, setRow] = useState(null);

  // Set's the visibility of the modal which we use to get the number of row's to be generated which is imported in Viscosity Grid.
  const [ScrewAddRowModal, setScrewAddRowModal] = useState();

  const [maxBinFrequency, setMaxBinFrequency] = useState();

  const ToggleAddRowModal = () => {
    setScrewAddRowModal(!ScrewAddRowModal);
  };

  // This is the event to do the above said thing.
  const addRow = (e) => {
    e.preventDefault();

    // Storing the number entered
    setRow(e.target.value);
  };

  const increaseRow = () => {
    // Updating the total rows variable
    if (row) {
      setPCSheetRowCount(parseInt(PCSheetRowCount) + parseInt(row));

      setRow(null);
    } else {
    }
  };

  // This is the event which deletes the row as clicked on the delete icon, id of the row gets passed and using filter method that row is filtered out.
  const deleteRow2 = () => {
    if (rowToBeDeleted && rowToBeDeleted > 6) {
      PCSpreadsheet.current.delete(rowToBeDeleted, rowToBeDeleted, "Row", 0);

      setPCSheetRowCount(parseInt(PCSheetRowCount) - 1);

      PCGridData.splice(rowToBeDeleted, 1);
    } else {
      alert("Select rows to delete.");
    }
  };

  const PopulatePCSheet = (data) => {
    const alphabetArray = [
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    for (let j = 1; j <= Object.keys(data[0]).length; j++) {
      for (let i = 0; i < data.length; i++) {
        PCSpreadsheet.current.updateCell(
          { value: data[i][`value${j}`] },
          `${alphabetArray[j - 1]}${i + 1}`
        );

        // console.log(data[i][`value${j}`], `${alphabetArray[j - 1]}${i+1}`)
      }
    }
  };

  const GetHeaders = () => {
    let TempHeaders = [];

    for (let j = 1; j < colCount; j++) {
      if (getCell(0, j, PCSpreadsheet.current.getActiveSheet()).value) {
        TempHeaders.push(
          getCell(0, j, PCSpreadsheet.current.getActiveSheet()).value
        );
      } else {
        TempHeaders.push(`Characteristics ${j}`);
      }
    }

    setHeaderArray(TempHeaders);
  };

  const getIndex = () => {
    return new Promise((resolve, reject) => {

      // Getting the index of the selected row and storing in a variable
      let cell = PCSpreadsheet.current.getActiveSheet().activeCell;

      let cellIdx = getRangeIndexes(cell);

      setRowToBeDeleted(cellIdx[0]);

      if (colCount < 2 || cellIdx[1] === 0) {
        setSelectedCol(null);
      } else {
        setSelectedCol(cellIdx[1]);
      }

      resolve();
    });
  };

  const GetValues = (TempArray) => {
    return new Promise((resolve, reject) => {
      let cell = PCSpreadsheet.current.getActiveSheet().activeCell;

      let cellIdx = getRangeIndexes(cell);

      let TempData = {};

      const char_name = getCell(
        0,
        SelectedCol ? SelectedCol : 1,
        PCSpreadsheet.current.getActiveSheet()
      ).value
        ? getCell(
            0,
            SelectedCol ? SelectedCol : 1,
            PCSpreadsheet.current.getActiveSheet()
          ).value
        : `Characteristics ${SelectedCol ? SelectedCol : 1}`;

      const upper_limit = getCell(
        1,
        SelectedCol ? SelectedCol : 1,
        PCSpreadsheet.current.getActiveSheet()
      ).value
        ? getCell(
            1,
            SelectedCol ? SelectedCol : 1,
            PCSpreadsheet.current.getActiveSheet()
          ).value
        : null;

      const target = getCell(
        2,
        SelectedCol ? SelectedCol : 1,
        PCSpreadsheet.current.getActiveSheet()
      ).value
        ? getCell(
            2,
            SelectedCol ? SelectedCol : 1,
            PCSpreadsheet.current.getActiveSheet()
          ).value
        : null;

      const lower_limit = getCell(
        3,
        SelectedCol ? SelectedCol : 1,
        PCSpreadsheet.current.getActiveSheet()
      ).value
        ? getCell(
            3,
            SelectedCol ? SelectedCol : 1,
            PCSpreadsheet.current.getActiveSheet()
          ).value
        : null;

      const sub_group_size = getCell(
        4,
        SelectedCol ? SelectedCol : 1,
        PCSpreadsheet.current.getActiveSheet()
      ).value
        ? getCell(
            4,
            SelectedCol ? SelectedCol : 1,
            PCSpreadsheet.current.getActiveSheet()
          ).value
        : null;

      TempData = {
        values: TempArray,
        char_name: char_name,
        sub_group_size: sub_group_size,
        upper_limit: upper_limit,
        lower_limit: lower_limit,
        target: target,
      };

      setSelectedColHeader(char_name);

      resolve(TempData);

      // console.log(TempData)
    });
  };

  const getPCSheetData = () => {
    return new Promise((resolve, reject) => {
      let TempArray = [];

      var usedRowIdx =
        PCSpreadsheet.current.getActiveSheet().usedRange.rowIndex;

      PCSpreadsheet.current
        .getData(
          PCSpreadsheet.current.getActiveSheet().name +
            "!" +
            getRangeAddress([5, SelectedCol || 1, usedRowIdx, SelectedCol || 1])
        )
        .then((cells) => {
          cells.forEach((cell, key) => {
            if (cell.value) {
              TempArray.push(cell.value);
            } else {
            }

            // console.log(key + ' : ' + cell.value);
          });

          resolve(TempArray);
        });
    });
  };

  const CalculateBin = (DataPoints) => {
    return new Promise((resolve, reject) => {
      //Calculate bin interval using Sturges' rule
      const numDataPoints = DataPoints.length;
      const numBins = Math.ceil(1 + Math.log2(numDataPoints)); // Sturges' formula
      const maxData = Math.max(...DataPoints);
      const minData = Math.min(...DataPoints);
      const number = (maxData - minData) / numBins;
      const binInterval = (number * 100) / 100;

      setBin_Interval(binInterval);

      if (binInterval > 0) {
        const maxFrequency = Math.max(
          ...DataPoints.reduce((frequencies, value) => {
            const bin = Math.floor((value - minData) / binInterval);
            frequencies[bin] = (frequencies[bin] || 0) + 1;
            return frequencies;
          }, []).filter((frequency) => frequency > 0)
        );

        setMaxBinFrequency(maxFrequency);

        const newYMax = maxFrequency + 0.2 * maxFrequency;

        const xstart = minData;
        const xend = xstart + numBins * binInterval;
        setxstart(xstart);
        setxend(xend);

        setYMax(newYMax);
        // console.log(newYMax);
      }

      resolve(binInterval);
    });
  };

  const CreateChartData = (data) => {
    return new Promise((resolve, reject) => {
      let HistogramData = [];

      data.values.forEach((value) => {
        HistogramData.push({
          y: value,
        });
      });

      resolve(HistogramData);
    });
  };

  // //Set data to pass in Chart
  const [data, setData] = useState({
    values: [],
    char_name: null,
    sub_group_size: null,
    upper_limit: null,
    lower_limit: null,
    sd: null,
    overall_sd: null,
    mean: null,
    ppm: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const PerformCalcs = (CurrentColumnData) => {
    // console.log(CurrentColumnData);
    // console.log(CurrentColumnData);

    // Calculating Cp
    let cp = calculateCp(
      CurrentColumnData.upper_limit,
      CurrentColumnData.lower_limit,
      CurrentColumnData.values,
      CurrentColumnData.sub_group_size
    );

    // Calculating Pp
    let pp = calculatePp(
      CurrentColumnData.upper_limit,
      CurrentColumnData.lower_limit,
      CurrentColumnData.values,
      CurrentColumnData.sub_group_size
    );

    // Calculating Ppk
    let ppk = calculatePpk(
      CurrentColumnData.upper_limit,
      CurrentColumnData.lower_limit,
      CurrentColumnData.values,
      CurrentColumnData.sub_group_size
    );

    // Calculating Ppm
    let ppm = calculatePPM(
      CurrentColumnData.values,
      CurrentColumnData.sub_group_size,
      CurrentColumnData.upper_limit,
      CurrentColumnData.lower_limit
    );

    // Calculating Potential Standard Deviation (SD)
    let sd = calculatePotentialSD(
      CurrentColumnData.values,
      CurrentColumnData.sub_group_size
    );

    // Calculating Overall Standard Deviation (SD)
    let overall_sd = calculateOverallSD(
      CurrentColumnData.values,
      CurrentColumnData.sub_group_size
    );

    // Calculating Data Range
    let data_range = calculateDataRange(CurrentColumnData.values);

    // Calculating Cpk
    let cpk = calculateCpk(
      CurrentColumnData.values,
      CurrentColumnData.sub_group_size,
      CurrentColumnData.upper_limit,
      CurrentColumnData.lower_limit
    );

    // Calculating Average
    let average = calculateAverage(
      CurrentColumnData.values,
      CurrentColumnData.sub_group_size
    );

    // Calculating Mean
    let mean = calculateMean(
      CurrentColumnData.values,
      CurrentColumnData.sub_group_size
    );

    let Statistics_data = {
      char_name: CurrentColumnData.char_name,
      values: CurrentColumnData.values,
      sub_group_size: CurrentColumnData.sub_group_size,
      upper_limit: CurrentColumnData.upper_limit,
      lower_limit: CurrentColumnData.lower_limit,
      target: CurrentColumnData.target,
      cp: cp,
      pp: pp,
      ppk: ppk,
      ppm: ppm,
      sd: sd,
      overall_sd: overall_sd,
      range: data_range,
      cpk: cpk,
      average: average,
      mean: mean,
    };

    setData(Statistics_data);
  };

  // Event to Call the POST request API and save the data
  const saveData = () => {
    const data = {
      session: SessionId,
      rowCount: PCSheetRowCount,
      colCount: colCount,
      Column_Data: PCGridData,
    };

    // console.log(data)

    DataService.SaveCapabilityData(data)
      .then((res) => {
        // console.log(res)

        setShowSave(false);

        toast("Data has been saved", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {});
  };

  // Event to Call the PUT request API and update the data
  const UpdateData = () => {
    const data = {
      session: SessionId,
      rowCount: PCSheetRowCount,
      colCount: colCount,
      Column_Data: PCGridData,
    };

    // console.log(data)

    DataService.UpdateCapabilityData(SessionId, data)
      .then((res) => {
        // console.log(res)
        setShowSave(false);

        toast("Data has been saved", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const data = {
      session: SessionId,
      rowCount: PCSheetRowCount,
      colCount: colCount,
      Column_Data: PCGridData,
    };

    // console.log(data)

    showAlert.current = true;

    QualityData.current = data;

    // eslint-disable-next-line
  }, [PCGridData, PCSheetRowCount, colCount]);

  const GetData = (SessionId) => {
    DataService.GetCapabilityData(SessionId)
      .then((res) => {
        if (res.data[0]) {
          ToSaveQualityData.current = false;

          setPCGridData(res.data[0].Column_Data);

          PopulatePCSheet(res.data[0].Column_Data);

          // console.log(res.data[0].Column_Data.length)

          setPCSheetRowCount(res.data[0].rowCount);

          setColCount(res.data[0].colCount);

          setPCSheetColCount(res.data[0].colCount);

          setShowSave(false);
        } else {
          setPCGridData([]);
        }
      })
      .catch((err) => {});
  };

  // ******************************* State variables for tab's ********************
  const [showData, setShowData] = useState(true);
  const [showAttribute, setShowAttribute] = useState(false);
  const [showPostMold, setShowPostMold] = useState(false);

  const ToggleData = () => {
    setShowData(true);

    setShowAttribute(false);

    setShowPostMold(false);
  };

  const ToggleAttribute = () => {
    setShowData(true);

    setShowAttribute(!showAttribute);

    setShowPostMold(false);
  };

  const TogglePostMold = () => {
    setShowData(true);

    setShowAttribute(false);

    setShowPostMold(!showPostMold);
  };

  // Event that will be called as soon as the Page load's so that if there is data available will get fetched
  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));
    setSession_Id(atob(sessionId));
    setSession_Name(atob(sessionName));
    setMoldId(moldId);
    setMoldName(moldName);

    if (SessionId) {
      GetData(SessionId);
    } else {
    }

    // console.log(SessionId)

    // eslint-disable-next-line
  }, [sessionId, SessionId, showData]);

  return (
    <div>
      <div>
        <Modal isOpen={DeleteAlertToggle} centered={true}>
          <ModalHeader toggle={DeleteModalAlertToggle}>
            Delete Alert.
          </ModalHeader>

          <ModalBody>
            {PCSheetColCount > 2
              ? "Select a column to delete"
              : "Atleast one column is mandatory."}
          </ModalBody>

          <ModalFooter>
            <Button color="dark" onClick={DeleteModalAlertToggle}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={DeleteConfirmToggle} centered={true}>
          <ModalHeader toggle={DeleteModalConfirmToggle}>
            Delete Confirmation.
          </ModalHeader>

          <ModalBody>
            <span> Are you sure you want to delete column ? </span>
          </ModalBody>

          <ModalFooter>
            <Button color="dark" onClick={deleteColumn}>
              {" "}
              Delete{" "}
            </Button>
            <Button color="dark" onClick={DeleteModalConfirmToggle}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={LimitValidationModal} centered={true}>
          <ModalHeader toggle={ToggleLimitValidationModal}>
            {" "}
            Nautilus.{" "}
          </ModalHeader>
          <ModalBody>
            <span>
              {" "}
              Upper Spec Limit should be greater than Lower Spec Limit.{" "}
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={ToggleLimitValidationModal}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={SGSizeModal} centered={true}>
          <ModalHeader toggle={ToggleSGSizeModal}> Nautilus </ModalHeader>
          <ModalBody>
            <span> Subgroup size should be between 1 to 15. </span>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={ToggleSGSizeModal}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <div>
          <Nav tabs>
            <div
              style={{ maxWidth: "80vw", width: "100vw" }}
              className="d-flex justify-content-between"
            >
              {/* 1st Group */}
              <div className="d-flex justify-content-between">
                <div>
                  <NavItem
                    style={{
                      backgroundColor:
                        activeTab === "1" ? "rgb(22 34 120)" : "",
                    }}
                  >
                    <NavLink
                      className={`custom-tab ${
                        activeTab === "1" ? "active" : ""
                      }`}
                      active={activeTab === "1"}
                      onClick={() => {
                        handleTabChange("1");
                        ToggleData();
                      }}
                    >
                      <span style={{ color: activeTab === "1" ? "#fff" : "" }}>
                        Data Entry
                      </span>
                    </NavLink>
                  </NavItem>
                </div>

                <div>
                  <NavItem
                    style={{
                      backgroundColor:
                        activeTab === "2" ? "rgb(22 34 120)" : "",
                    }}
                    onClick={GetHeaders}
                  >
                    <NavLink
                      className={`custom-tab ${
                        activeTab === "2" ? "active" : ""
                      }`}
                      active={activeTab === "2"}
                      onClick={() => {
                        handleTabChange("2");
                        ToggleData();
                      }}
                    >
                      <span style={{ color: activeTab === "2" ? "#fff" : "" }}>
                        {" "}
                        Capability Analysis{" "}
                      </span>
                    </NavLink>
                  </NavItem>
                </div>

                <div>
                  <NavItem
                    style={{
                      backgroundColor:
                        activeTab === "3" ? "rgb(22 34 120)" : "",
                    }}
                    onClick={GetHeaders}
                  >
                    <NavLink
                      className={`custom-tab ${
                        activeTab === "3" ? "active" : ""
                      }`}
                      active={activeTab === "3"}
                      onClick={() => {
                        handleTabChange("3");
                        ToggleData();
                      }}
                    >
                      <span style={{ color: activeTab === "3" ? "#fff" : "" }}>
                        {" "}
                        Control Chart{" "}
                      </span>
                    </NavLink>
                  </NavItem>
                </div>
              </div>

              {/* 2nd Group */}
              <div className="d-flex">
                <div>
                  <NavItem
                    style={{
                      backgroundColor:
                        activeTab === "4" ? "rgb(22 34 120)" : "",
                    }}
                  >
                    <NavLink
                      className={`custom-tab ${
                        activeTab === "4" ? "active" : ""
                      }`}
                      active={activeTab === "4"}
                      onClick={() => {
                        handleTabChange("4");
                        ToggleAttribute();
                      }}
                    >
                      <span style={{ color: activeTab === "4" ? "#fff" : "" }}>
                        {" "}
                        Attribute{" "}
                      </span>
                    </NavLink>
                  </NavItem>
                </div>

                <div>
                  <NavItem
                    style={{
                      backgroundColor:
                        activeTab === "5" ? "rgb(22 34 120)" : "",
                    }}
                  >
                    <NavLink
                      className={`custom-tab ${
                        activeTab === "5" ? "active" : ""
                      }`}
                      active={activeTab === "5"}
                      onClick={() => {
                        handleTabChange("5");
                        TogglePostMold();
                      }}
                    >
                      <span style={{ color: activeTab === "5" ? "#fff" : "" }}>
                        {" "}
                        Post Mold Shrinkage Study{" "}
                      </span>
                    </NavLink>
                  </NavItem>
                </div>
              </div>

              <div className="col-md-2 mt-2">
                <button
                  className="btn btn-secondary btn-air-secondary"
                  type="button"
                  onClick={SavedModal}
                >
                  {" "}
                  Save{" "}
                </button>
              </div>
            </div>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <div
                    className="card p-3 ml-2 mt-2"
                    style={{ display: showData ? "block" : "none" }}
                  >
                    <PCGrid
                      DeleteModalConfirmToggle={DeleteModalConfirmToggle}
                      addColumn={addColumn}
                      PCSpreadsheet={PCSpreadsheet}
                      PCGridData={PCGridData}
                      setPCGridData={setPCGridData}
                      PCSheetColCount={PCSheetColCount}
                      setPCSheetColCount={setPCSheetColCount}
                      SelectedCol={SelectedCol}
                      setSelectedCol={setSelectedCol}
                      setRowToBeDeleted={setRowToBeDeleted}
                      PCSheetRowCount={PCSheetRowCount}
                      deleteRow2={deleteRow2}
                      ToggleAddRowModal={ToggleAddRowModal}
                      ScrewAddRowModal={ScrewAddRowModal}
                      addRow={addRow}
                      increaseRow={increaseRow}
                      handleTabChange={handleTabChange}
                      setSelectedColData={setSelectedColData}
                      setBin_Interval={setBin_Interval}
                      setChartData={setChartData}
                      setYMax={setYMax}
                      setxstart={setxstart}
                      setxend={setxend}
                      saveData={saveData}
                      UpdateData={UpdateData}
                      showSave={showSave}
                      colCount={colCount}
                      PopulatePCSheet={PopulatePCSheet}
                      setHeaderArray={setHeaderArray}
                      getIndex={getIndex}
                      getPCSheetData={getPCSheetData}
                      GetValues={GetValues}
                      CalculateBin={CalculateBin}
                      CreateChartData={CreateChartData}
                      setComputeClicked={setComputeClicked}
                      SelectedColData={SelectedColData}
                      ToggleLimitValidationModal={ToggleLimitValidationModal}
                      ToggleSGSizeModal={ToggleSGSizeModal}
                      PerformCalcs={PerformCalcs}
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <div className="ml-3">
                    <CapabilityAnalysis
                      PCSpreadsheet={PCSpreadsheet}
                      SelectedColData={SelectedColData}
                      Bin_Interval={Bin_Interval}
                      yMax={yMax}
                      xstart={xstart}
                      xend={xend}
                      ChartData={ChartData}
                      PCSheetRowCount={PCSheetRowCount}
                      setSelectedColData={setSelectedColData}
                      CalculateBin={CalculateBin}
                      CreateChartData={CreateChartData}
                      setBin_Interval={setBin_Interval}
                      setChartData={setChartData}
                      computeClicked={computeClicked}
                      maxBinFrequency={maxBinFrequency}
                      setComputeClicked={setComputeClicked}
                      HeaderArray={HeaderArray}
                      SelectedColHeader={SelectedColHeader}
                      setSelectedColHeader={setSelectedColHeader}
                      SessionId={SessionId}
                      SelectedCol={SelectedCol}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      data={data}
                      setData={setData}
                      PerformCalcs={PerformCalcs}
                      activeTab={activeTab}
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <div>
                    <ControlChart
                      PCSpreadsheet={PCSpreadsheet}
                      SelectedColData={SelectedColData}
                      HeaderArray={HeaderArray}
                      SelectedColHeader={SelectedColHeader}
                      PCSheetRowCount={PCSheetRowCount}
                      setSelectedColData={setSelectedColData}
                      CalculateBin={CalculateBin}
                      CreateChartData={CreateChartData}
                      setBin_Interval={setBin_Interval}
                      setChartData={setChartData}
                      setComputeClicked={setComputeClicked}
                      setSelectedColHeader={setSelectedColHeader}
                      PerformCalcs={PerformCalcs}
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>

      {showAttribute ? (
        <Attribute
          showAlert={showAlert}
          ToSaveAttributeData={ToSaveAttributeData}
          AttributeData={AttributeData}
        />
      ) : (
        <></>
      )}

      {showPostMold ? (
        <PostMold
          showAlert={showAlert}
          ToSavePostMoldData={ToSavePostMoldData}
          PostMoldData={PostMoldData}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProcessCapability;
