import React, { useEffect, useState } from "react";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  RowsDirective,
  RowDirective,
  ColumnsDirective,
  ColumnDirective,
  CellsDirective,
  CellDirective,
  getRangeAddress,
} from "@syncfusion/ej2-react-spreadsheet";
import Loader from "../../../assets/Loader";

const HR = ({ PPS_Data, HRSpreadsheetRef, LoadHRSheet, showAlert }) => {
  const scrollSettings = { isFinite: true };

  const [isLoading, setIsLoading] = useState(true);

  const dialogBeforeOpen = (args) => {
    if (args.dialogName === "EditAlertDialog") {
      args.cancel = true;
    }

    // Edit the dialog content using the dialogBeforeOpen event.
    if (args.dialogName === "ValidationErrorDialog") {
      args.cancel = true;
    }
  };

  const [ModalStates, setModalStates] = useState({
    AddZones: {
      visibility: false,
      NoOfZones: 144,
      ColCount: 2,
    },
    MaxZonesAlert: {
      visibility: false,
      message: "Number Of Zones cannot exceed 144",
    },
    EmptyZonesAlert: {
      visibility: false,
      message: "Please enter number of zones",
    },
    ZeroZonesAlert: {
      visibility: false,
      message: "This is not a Hot Runner Mold",
    },
  });

  const GetAllColData = async () => {
    try {
      let TempArray = [];

      const cells = await HRSpreadsheetRef.current.getData(
        HRSpreadsheetRef.current.getActiveSheet().name +
          "!" +
          getRangeAddress([1, 0, 25, 12])
      );

      cells.forEach((cell, key) => {
        TempArray.push(cell.value ? cell.value : null);
      });

      return TempArray;
    } catch (error) {
      console.error("Error in GetAllColData:", error);
      throw error;
    }
  };

  const ConvertToObject = async (AllColData) => {
    try {
      let TempDataArray = [];
      let NumberOfRow = 0;

      for (let i = 0; i < ModalStates.AddZones.NoOfZones; i++) {
        let TempDataObj = {};

        TempDataObj["ZoneNum"] = AllColData[i + NumberOfRow];
        TempDataObj["SettVal"] = AllColData[i + NumberOfRow + 1];

        NumberOfRow += 12;
        TempDataArray.push(TempDataObj);
      }

      return TempDataArray;
    } catch (error) {
      console.error("Error in ConvertToObject:", error);
      throw error;
    }
  };

  const GatherData = async () => {
    try {
      // Now, Finally getting all the data in the sheet
      const ColData = await GetAllColData();

      // Then converting it to an array of objects
      const ObjData = await ConvertToObject(ColData);

      PPS_Data.HRSett["fldZones"] = ObjData;

      showAlert.current = true
    } catch (error) {
      console.error("Error in GatherData:", error);
      throw error;
    }
  };

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      if (PPS_Data.HRSett.HRJson?.jsonObject) {
        HRSpreadsheetRef.current.openFromJson({
          file: PPS_Data.HRSett.HRJson.jsonObject,
        });
      }
    }, 1500);

    setTimeout(() => {
      setIsLoading(!isLoading);
    }, 1500);
  }, [LoadHRSheet]);

  const onCreated = async () => {
    try {
      HRSpreadsheetRef.current.cellFormat({ textAlign: "center" }, "A1:L25");

      HRSpreadsheetRef.current.lockCells("A2:L25", false);

      HRSpreadsheetRef.current.protectSheet(null, {
        selectCells: true,
      });

      let SettingCols = ["B", "D", "F", "H", "J", "L"];
      let ZoneCols = ["A", "C", "E", "G", "I", "K"];

      let SettingCount = 1;

      for (
        let i = 1;
        i <= Math.ceil(ModalStates.AddZones.NoOfZones / 24);
        i++
      ) {
        HRSpreadsheetRef.current.cellFormat(
          { backgroundColor: "rgba(174,194,221,255)" },
          `${ZoneCols[i - 1]}1`
        );

        HRSpreadsheetRef.current.cellFormat(
          { backgroundColor: "rgba(174,194,221,255)" },
          `${SettingCols[i - 1]}1`
        );

        for (let j = 1; j < 25; j++) {
          HRSpreadsheetRef.current.updateCell(
            {
              value:
                SettingCount > ModalStates.AddZones.NoOfZones
                  ? ""
                  : SettingCount,
            },
            `${ZoneCols[i - 1]}${j + 1}`
          );

          HRSpreadsheetRef.current.cellFormat(
            { backgroundColor: "rgba(250, 240, 225, 255)" },
            `${SettingCols[i - 1]}${j + 1}`
          );

          HRSpreadsheetRef.current.lockCells(
            `${SettingCols[i - 1]}${j + 1}`,
            false
          );

          SettingCount++;
        }
      }
    } catch (error) {
      console.error("Error on sheet creation:", error);
      throw error;
    }
  };

  function actionBegin(args) {
    if (
      args.action === "clipboard" &&
      args.args.eventArgs.requestType === "paste"
    ) {
      //Set the type to 'Values' to paste only the values.
      args.args.eventArgs.type = "Values";
    }
  }

  // useEffect(() => {
  //   setTabIdx(3)
  // }, [])

  return (
    <>
      {isLoading ? <Loader /> : <></>}

      <div
        id="Hr_Sheet"
        className="spreadsheet"
        style={{ width: "85vw", height: "68vh" }}
      >
        <div className="TabCardTitle">
          <span> Hot Runner Controller Settings </span>
        </div>

        <div>
          <SpreadsheetComponent
            style={{
              display: ModalStates.ZeroZonesAlert.visibility ? "none" : "block",
            }}
            ref={HRSpreadsheetRef}
            height={400}
            width={"95%"}
            onBlur={GatherData}
            cellEdit={GatherData}
            cellEdited={GatherData}
            showFormulaBar={false}
            showSheetTabs={false}
            showRibbon={false}
            dialogBeforeOpen={dialogBeforeOpen.bind(this)}
            scrollSettings={scrollSettings}
            created={onCreated}
            allowAutoFill={false}
            enableContextMenu={false}
            allowImage={false}
            actionBegin={actionBegin}
          >
            <SheetsDirective>
              <SheetDirective
                frozenRows={1}
                rowCount={25}
                colCount={12}
                showHeaders={false}
              >
                <RowsDirective>
                  <RowDirective>
                    <CellsDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                    </CellsDirective>
                  </RowDirective>
                </RowsDirective>
                <ColumnsDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                </ColumnsDirective>
              </SheetDirective>
            </SheetsDirective>
          </SpreadsheetComponent>

          {ModalStates.ZeroZonesAlert.visibility ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ color: "red", fontSize: "1.5em" }}>
                {" "}
                *{ModalStates.ZeroZonesAlert.message}{" "}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default HR;
