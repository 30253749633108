import React, { useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  Page,
  Freeze,
  Sort,
} from "@syncfusion/ej2-react-grids";

const AcceptableMachines = ({
  MachineDataList,
  SelectedMachine,
  PopulateMMGrid, SelectedMold
}) => {
  var AMGrid;

  const Suitable_Molds =
    JSON.parse(sessionStorage.getItem("Suitable_Molds")) || [];
  const Suitable_Machines =
    JSON.parse(sessionStorage.getItem("Suitable_Machines")) || [];

  const [Acceptable_Machines, setAcceptable_Machines] = useState([]);

  const GenerateAcceptableMachineArray = () => {
    return new Promise((resolve, reject) => {
      if (MachineDataList.length > 0) {
        let Data = MachineDataList.map((Machine, idx) => {
          return {
            Machine_Number: Machine.Machine_Number,
            Is_Mold_Suitable: Suitable_Molds.some(
              (Mold) => Mold === Machine.Machine_Number
            )
              ? "Yes"
              : "No",
            Is_Machine_Suitable:
              SelectedMachine.Machine_Number === Machine.Machine_Number
                ? "Reference"
                : Suitable_Machines.some(
                    (Mold) => Mold === Machine.Machine_Number
                  )
                ? "Yes"
                : "No",
          };
        });

        resolve(Data);
      }
    });
  };

  useEffect(() => {
    GenerateAcceptableMachineArray().then((MachineData) => {
      // console.log(MachineData)

      setAcceptable_Machines(
        MachineData.map((Machine) => ({
          ...Machine,
          Is_Machine_Acceptable:
            SelectedMachine.Machine_Number === Machine.Machine_Number
              ? "Reference"
              : Machine.Is_Mold_Suitable === "Yes" &&
                Machine.Is_Machine_Suitable === "Yes"
              ? "\u2714"
              : "X",
        })).sort((a, b) => (a.Is_Machine_Acceptable === "Reference" ? -1 : 1))
      );
    });
  }, [MachineDataList]);

  // console.log(Acceptable_Machines)

  let columns = [
    {
      field: "Machine_Number",
      headerText: "Machine Number",
      textAlign: "center",
      width: 150,
      minWidth: 10,
      template: (props) => {
        return (
          <div>
            {props["Machine_Number"] === SelectedMachine.Machine_Number ? (
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ color: "#000", fontWeight: "bold" }}>
                  {" "}
                  {props["Machine_Number"]}{" "}
                </span>
              </div>
            ) : (
              <div style={{ backgroundColor: "#fff" }}>
                <span
                  style={{
                    color:
                      props["Is_Mold_Suitable"] === "Yes" &&
                      props["Is_Machine_Suitable"] === "Yes"
                        ? "green"
                        : "red",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {props["Machine_Number"]}{" "}
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "Is_Mold_Suitable",
      headerText: "Mold-Machine Compatibility",
      textAlign: "center",
      width: 150,
      minWidth: 10,
      template: (props) => {
        return (
          <div>
            {props["Is_Mold_Suitable"] === "Yes" ? (
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ color: "green", fontWeight: "bold" }}>
                  {" "}
                  {props["Is_Mold_Suitable"]}{" "}
                </span>
              </div>
            ) : (
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {" "}
                  {props["Is_Mold_Suitable"]}{" "}
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "Is_Machine_Suitable",
      headerText: "Process-Machine Compatibility",
      textAlign: "center",
      width: 150,
      minWidth: 10,
      template: (props) => {
        return (
          <div>
            {props["Is_Machine_Suitable"] === "Reference" ? (
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ color: "#000", fontWeight: "bold" }}>
                  {" "}
                  {props["Is_Machine_Suitable"]}{" "}
                </span>
              </div>
            ) : props["Is_Machine_Suitable"] === "Yes" ? (
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ color: "green", fontWeight: "bold" }}>
                  {" "}
                  {props["Is_Machine_Suitable"]}{" "}
                </span>
              </div>
            ) : (
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {" "}
                  {props["Is_Machine_Suitable"]}{" "}
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "Is_Machine_Acceptable",
      headerText: "Acceptable Machines",
      textAlign: "center",
      width: 150,
      minWidth: 10,
      template: (props) => {
        return (
          <div>
            {props["Is_Machine_Acceptable"] === "Reference" ? (
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ color: "#000", fontWeight: "bold" }}>
                  {" "}
                  {props["Is_Machine_Acceptable"]}{" "}
                </span>
              </div>
            ) : props["Is_Machine_Acceptable"] === "\u2714" ? (
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ color: "green", fontWeight: "bolder" }}>
                  {" "}
                  {props["Is_Machine_Acceptable"]}{" "}
                </span>
              </div>
            ) : (
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  {" "}
                  {props["Is_Machine_Acceptable"]}{" "}
                </span>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {SelectedMold.length > 0 ? (
        <div
          className="b-primary b-r-4 p-3 ml-2 MMTable"
          id="AMTable"
          style={{ border: "1px grey solid" }}
        >
          <GridComponent
            id="PMGrid"
            ref={(g) => (AMGrid = g)}
            dataSource={PopulateMMGrid ? Acceptable_Machines : []}
            columns={columns}
            allowPaging={true}
            pageSettings={{ pageSize: 50 }}
            width={"70%"}
            height={400}
            allowSorting={true}
          >
            <Inject services={[Page, Freeze, Sort]} />
          </GridComponent>
        </div>
      ) : (
        <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.5rem" }}>
          <span>
            Select the Mold and Process Sheet session to view the Compatibility
            here.
          </span>
        </div>
      )}
    </>
  );
};

export default AcceptableMachines;
