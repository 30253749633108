import React, { useState, useEffect, useRef } from "react";
import {
  ChartComponent,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { Button } from "reactstrap";
import CoolingAddColumn from "../columns&rows/CoolingAddColumn";
import CoolingAddRow from "../columns&rows/CoolingAddRow";
import { nanoid } from "nanoid";
import { data, data2 } from "../data/Cooling_data";
import CoolingGrid from "../Grids/CoolingGrid";
import {
  HtmlEditor,
  Inject,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../App.css";
import CoolingEdit from "../modals/CoolingEdit";

// Event having multiple method's to deal with the back-end
import DataService from "../../services/ApiService";

// Hook to get the parameter's from the URL
import { useParams } from "react-router-dom";

import { useReactToPrint } from "react-to-print";
import CoolingPrint from "../printables/CoolingPrint";

import { getCell } from "@syncfusion/ej2-react-spreadsheet";

export let chartInstance;

const CoolingTimeStudy = ({ showAlert, CoolingData, ToSaveCoolingData }) => {
  let CoolingTimeSpreadsheet = useRef();

  let CoolingTimeChart = useRef();

  const [ColToBeDeleted, setColToBeDeleted] = useState(1);

  const [rowToBeDeleted, setRowToBeDeleted] = useState();

  const [rowCount, setRowCount] = useState(5);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // A state variable to store and set the value of textarea
  const [Comment, setComment] = useState("");

  const [Alert, setAlert] = useState(false);

  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
  };

  // Event to set the data entered in the textarea
  const getComment = (e) => {
    if (e.target) {
      setComment(e.target.value);
    } else {
      setComment(e.value); // Get the RTE value
      showAlert.current = true;
      setAlert(true);
    }
  };

  const [modal, setModal] = useState();

  const toggle = () => {
    setModal(!modal);
  };

  const [modal2, setModal2] = useState();

  const toggle2 = () => {
    setModal2(!modal2);
  };

  const [modal3, setModal3] = useState();

  const toggle3 = () => {
    setModal3(!modal3);
  };

  // ************ Functions to deal with column ************

  const [header, setHeader] = useState();
  const [column, setColumn] = useState(data);
  const [isColumnId, setIsColumnId] = useState(null);
  const [toggleEdit, setToggleEdit] = useState(true);
  const [grid2, setGrid2] = useState("value1");
  const [CoolingGridHeader, setCoolingGridHeader] = useState("Dim 1");
  const [Key, setKey] = useState(1);

  const [colCount, setColCount] = useState(column.length);

  const [chartData, setChartData] = useState([]);

  const [ColHeader, seColHeader] = useState("");

  const [args, setArgs] = useState("");

  const RenderHeaders = () => {
    // Condition to check whether the rendered element is header cell.
    for (let i = 0; i < column.length; i++) {
      if (
        args.colIndex === i &&
        args.element.classList.contains("e-header-cell")
      ) {
        const text = column[i].header;
        args.element.innerText = text;
      }
    }
  };

  const addHeader = (e) => {
    e.preventDefault();
    setHeader(e.target.value);
  };

  const addColumn = () => {
    if (!header) {
    } else {
      const newColumn = { id: nanoid(), header: header };
      setColumn([...column, newColumn]);
      setColCount(colCount + 1);
      setHeader("");
      showAlert.current = true;
      setAlert(true);
    }
  };

  const PopulateSheet = () => {
    const alphabetArray = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    setColCount(column.length);

    for (let j = 1; j <= column.length; j++) {
      for (let i = 1; i <= CoolingTimeGridData.length; i++) {
        CoolingTimeSpreadsheet.current.updateCell(
          { value: CoolingTimeGridData[i - 1][`value${j - 1}`] },
          `${alphabetArray[j - 1]}${i}`
        );
      }
    }
  };

  const editColumnHeader = () => {
    if (header && !toggleEdit) {
      setColumn(
        column.map((element) => {
          if (element.id === isColumnId) {
            return { ...element, header: header };
          }
          return element;
        })
      );
      CoolingTimeSpreadsheet.current.refresh();
      setHeader("");
      setIsColumnId(null);
      setCoolingGridHeader(header);
      showAlert.current = true;
      setAlert(true);
    } else {
    }
  };

  function calculateNiceNumbers(min, max, numTicks) {
    const range = max - min;
    const roughStep = range / numTicks;
    const magnitude = Math.floor(Math.log10(roughStep));
    const magnitudePow = Math.pow(10, magnitude);
    const mantissa = roughStep / magnitudePow;
    let niceStep;

    if (mantissa <= 1.0) {
      niceStep = 1 * magnitudePow;
    } else if (mantissa <= 2.0) {
      niceStep = 2 * magnitudePow;
    } else if (mantissa <= 5.0) {
      niceStep = 5 * magnitudePow;
    } else {
      niceStep = 10 * magnitudePow;
    }

    const niceMin = Math.floor(min / niceStep) * niceStep;
    const niceMax = Math.ceil(max / niceStep) * niceStep;

    const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
    const niceTicks = Array.from(
      { length: numNiceTicks },
      (_, i) => niceMin + i * niceStep
    );

    return {
      min: niceMin,
      max: niceMax,
      step: niceStep,
      ticks: niceTicks,
    };
  }

  const [TimeniceNumbers, setTimeNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const [DimniceNumbers, setDimNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const GetDataFromSheet = () => {
    // Getting the data from the CavitySpreadsheet cells
    let CoolingDataObj = {};
    let CoolingDataArray = [];

    for (let i = 0; i < rowCount; i++) {
      for (let j = 0; j < column.length; j++) {
        CoolingDataObj[`value${j}`] = !getCell(
          i,
          j,
          CoolingTimeSpreadsheet.current.getActiveSheet()
        ).value
          ? null
          : getCell(i, j, CoolingTimeSpreadsheet.current.getActiveSheet())
              .value;
      }

      CoolingDataArray.push(CoolingDataObj);

      CoolingDataObj = {};
    }

    setCoolingTimeGridData(CoolingDataArray);

    // console.log(CoolingDataArray)
  };

  // State to Toggle the Delete Confirmation Modal.
  const [DeleteAlertToggle, setDeleteAlertToggle] = useState(false);
  const [DeleteConfirmToggle, setDeleteConfirmToggle] = useState(false);

  const DeleteModalAlertToggle = () => {
    setDeleteAlertToggle(!DeleteAlertToggle);
  };

  const DeleteModalConfirmToggle = () => {
    if (!ColToBeDeleted || column.length <= 2) {
      setDeleteAlertToggle(!DeleteAlertToggle);
    } else {
      setDeleteConfirmToggle(!DeleteConfirmToggle);
      seColHeader(column[ColToBeDeleted].header);
    }
  };

  const deleteColumn = (id, key) => {
    CoolingTimeSpreadsheet.current.delete(
      ColToBeDeleted,
      ColToBeDeleted,
      "Column"
    );

    setColumn((prevArray) => {
      const newArray = [...prevArray];

      newArray.splice(ColToBeDeleted, 1);

      return newArray;
    });

    for (let j = 1; j < column.length; j++) {
      for (let i = 0; i < CoolingTimeGridData.length; i++) {
        delete CoolingTimeGridData[i][`value${ColToBeDeleted}`];
      }
    }

    GetDataFromSheet();

    setDeleteConfirmToggle(!DeleteConfirmToggle);

    CoolingTimeSpreadsheet.current.refresh();

    setCoolingGridHeader("");

    setChartData([]);

    showAlert.current = true;

    setAlert(true);
  };

  const editColumn = (id) => {
    setIsColumnId(id);
    setToggleEdit(false);
  };

  const editCancel = () => {
    setIsColumnId(null);
    setToggleEdit(true);
  };

  // ************ Functions to deal with row ***************

  const [row, setRow] = useState();
  const [CoolingTimeGridData, setCoolingTimeGridData] = useState(data2);

  const addRow = (e) => {
    e.preventDefault();
    setRow(e.target.value);
  };

  const increaseRow = () => {
    if (row) {
      setRowCount(parseInt(rowCount) + parseInt(row));

      CoolingTimeSpreadsheet.current.getActiveSheet().rowCount =
        parseInt(rowCount) + parseInt(row);

      showAlert.current = true;

      setAlert(true);

      toggle2();
    } else {
      alert("Please enter number of rows.");
    }
  };

  const deleteRow2 = (id) => {
    CoolingTimeSpreadsheet.current.delete(
      rowToBeDeleted,
      rowToBeDeleted,
      "Row",
      0
    );

    setRowCount(parseInt(rowCount) - 1);

    CoolingTimeGridData.splice(rowToBeDeleted - 1, 1);

    showAlert.current = true;

    setAlert(true);
  };

  // To get the Session Id from url using useParams hook
  var { sessionId, moldName, sessionName } = useParams();

  // Variable to store the a Mold/Session Name
  const [Mold_Name, setMold_Name] = useState();
  const [Session_Name, setSession_Name] = useState();

  // Session Id getting from the URL
  const [SessionId, setSessionId] = useState();

  // Event the current session's viscosity data
  const handleGet = (SessionId) => {
    if (SessionId) {
      DataService.FetchCooling(SessionId)
        .then((res) => {
          if (res.data) {
            ToSaveCoolingData.current = false;

            setColumn(res.data.Column_Details);
            setColCount(res.data.Column_Details.length);

            setRowCount(res.data.Column_Data.length);

            setCoolingTimeGridData(res.data.Column_Data);

            setCoolingGridHeader(res.data.Column_Details[1].header);

            // console.log(res.data.Column_Data.length)

            setComment(res.data.Comment);
          } else {
            setColumn(data);
            setCoolingTimeGridData(data2);
          }
        })
        .catch((err) => {});
    } else {
      setColumn(data);
      setCoolingTimeGridData(data2);
    }
  };

  useEffect(() => {
    const data = {
      session: SessionId,
      Column_Details: column,
      Column_Data: CoolingTimeGridData,
      rowCount: rowCount,
      Comment: Comment ? Comment : "N/A",
      PrintData: {
        column: column,
        CoolingTimeGridData: CoolingTimeGridData,
        grid2: grid2,
        Mold_Name: Mold_Name,
        Session_Name: Session_Name,
        CoolingGridHeader: CoolingGridHeader,
        Comment: Comment,
        chartData: chartData,
        TimeniceNumbers: TimeniceNumbers,
        DimniceNumbers: DimniceNumbers,
      },
    };

    CoolingData.current = data;

    // eslint-disable-next-line
  }, [CoolingTimeGridData, column, rowCount, Comment]);

  const setGraph = () => {
    let DimArray = [],
      timeArray = [];

    // This is the event to sort the data based on Time
    const compareValue = (a, b) => {
      return a.value0 - b.value0;
    };

    // This is the event to sort the data based on Weight
    const compareValue2 = (a, b) => {
      return a[`value${Key}`] - b[`value${Key}`];
    };

    for (let i = 0; i < CoolingTimeGridData.length; i++) {
      if (CoolingTimeGridData[i].value0) {
        DimArray.push(CoolingTimeGridData[i]);
        timeArray.push(CoolingTimeGridData[i]);
      } else {
      }
    }

    // CoolingTimeGridData.sort(compareValue);
    timeArray.sort(compareValue);
    DimArray.sort(compareValue2);

    setChartData(timeArray);

    PopulateSheet(timeArray);

    let margin;

    const Data = CoolingTimeGridData.filter((data) => {
      return data.value0 ? data.value0 !== "" : null;
    });

    if (Data.length > 0) {
      margin =
        ((parseInt(timeArray[timeArray.length - 1].value0) -
          parseInt(timeArray[0].value0)) /
          100) *
        5;

      setTimeNiceNumbers(
        calculateNiceNumbers(
          parseFloat(timeArray[0].value0) - margin,
          parseFloat(timeArray[timeArray.length - 1].value0) + margin,
          5
        )
      );

      setDimNiceNumbers(
        calculateNiceNumbers(
          parseFloat(DimArray[0][`value${Key}`]),
          parseFloat(DimArray[DimArray.length - 1][`value${Key}`]),
          5
        )
      );

      // console.log(TimeniceNumbers, margin)
    }
  };

  // Event to Scroll to Chart
  const PlotChart = () => {
    const Data = CoolingTimeGridData.filter((data) => {
      return data.value0 ? data.value0 !== "" : null;
    });

    if (Data.length > 0) setGraph();
  };

  const [ToPlotChart, setToPlotChart] = useState(true);

  useEffect(() => {
    if (ToPlotChart) PlotChart();

    // eslint-disable-next-line
  }, [CoolingTimeGridData]);

  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));

    setMold_Name(atob(moldName));
    setSession_Name(atob(sessionName));

    // After that it call's this event to fetch the data
    handleGet(SessionId);

    // eslint-disable-next-line
  }, [sessionId, SessionId, moldName, sessionName]);

  const [showPrint, setShowPrint] = useState(false);

  const componentRef = useRef();

  const printPage = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setShowPrint(true),
    onAfterPrint: () => setShowPrint(false),
  });

  const handlePrint = () => {
    setShowPrint(true);

    setTimeout(() => {
      printPage();
    }, 100);
  };

  const setGrid = (e) => {
    if (e.target.value) {
      var val = document.getElementById("Cooling-Column");

      var CoolingHeader = val.options[val.selectedIndex].text;

      setGrid2(`value${e.target.value}`);

      if (CoolingHeader != "Select Column") setCoolingGridHeader(CoolingHeader);

      setKey(e.target.value);

      setChartData([]);
    }
  };

  return (
    <>
      <div className="cooling pb-2">
        <div>
          <Modal isOpen={DeleteAlertToggle} centered={true}>
            <ModalHeader toggle={DeleteModalAlertToggle}>
              Delete Alert.
            </ModalHeader>

            <ModalBody>
              {column.length > 2
                ? "Select a column to delete"
                : "Atleast one column is mandatory."}
            </ModalBody>

            <ModalFooter>
              <Button color="dark" onClick={DeleteModalAlertToggle}>
                {" "}
                Close{" "}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={DeleteConfirmToggle} centered={true}>
            <ModalHeader toggle={DeleteModalConfirmToggle}>
              Delete Confirmation.
            </ModalHeader>

            <ModalBody>
              {ColHeader
                ? `Are you sure you want to delete ${ColHeader} column ?`
                : ""}
            </ModalBody>

            <ModalFooter>
              <Button color="dark" onClick={deleteColumn}>
                {" "}
                Delete{" "}
              </Button>
              <Button color="dark" onClick={DeleteModalConfirmToggle}>
                {" "}
                Close{" "}
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <section className="card sixstepCard p-3 ml-2">
          <div className="b-primary b-r-4 ">
            <div className="pt-2 pb-2 pr-2 pl-1">
              <div className="d-flex col-md-12">
                <div className="d-flex">
                  <div>
                    <CoolingAddColumn
                      modal={modal}
                      toggle={toggle}
                      addColumn={addColumn}
                      addHeader={addHeader}
                    />
                  </div>

                  <div>
                    <CoolingAddRow
                      modal2={modal2}
                      toggle2={toggle2}
                      addRow={addRow}
                      increaseRow={increaseRow}
                    />
                  </div>

                  <div>
                    <CoolingEdit
                      modal3={modal3}
                      toggle3={toggle3}
                      column={column}
                      addHeader={addHeader}
                      editColumnHeader={editColumnHeader}
                      editCancel={editCancel}
                      editColumn={editColumn}
                    />
                  </div>
                </div>

                <div>
                  <button
                    className="btn btn-warning btn-air-warning mr-2"
                    type="button"
                    onClick={DeleteModalConfirmToggle}
                  >
                    {" "}
                    Delete Column{" "}
                  </button>

                  <button
                    className="btn btn-warning btn-air-warning mr-2"
                    type="button"
                    onClick={deleteRow2}
                  >
                    Delete Row
                  </button>

                  <button
                    className="btn btn-primary btn-air-primary mr-2"
                    type="button"
                    onClick={handleShow}
                  >
                    Comment
                  </button>

                  {/* <button
                    className="btn btn-secondary btn-air-secondary mr-2"
                    type="button"
                    onClick={handlePrint}
                  >
                    {" "}
                    Print{" "}
                  </button> */}

                  <Modal isOpen={show} centered>
                    <ModalHeader toggle={handleClose}>Add Comment</ModalHeader>
                    <ModalBody>
                      <RichTextEditorComponent
                        change={getComment}
                        value={Comment}
                        saveInterval="1"
                        toolbarSettings={toolbarSettings}
                        height={250}
                      >
                        <Inject services={[Toolbar, HtmlEditor]} />
                      </RichTextEditorComponent>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="dark" onClick={handleClose}>
                        {" "}
                        Save & Close{" "}
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="d-flex p-2">
              <div className="col-md-6" width={"35%"}>
                <div onClick={() => setToPlotChart(false)}>
                  <CoolingGrid
                    className="coldGrid"
                    column={column}
                    CoolingTimeGridData={CoolingTimeGridData}
                    setGrid2={setGrid2}
                    setCoolingGridHeader={setCoolingGridHeader}
                    Alert={Alert}
                    CoolingTimeSpreadsheet={CoolingTimeSpreadsheet}
                    setColToBeDeleted={setColToBeDeleted}
                    setRowToBeDeleted={setRowToBeDeleted}
                    rowCount={rowCount}
                    setCoolingTimeGridData={setCoolingTimeGridData}
                    setColCount={setColCount}
                    colCount={colCount}
                    PopulateSheet={PopulateSheet}
                    GetDataFromSheet={GetDataFromSheet}
                    setRowCount={setRowCount}
                    setArgs={setArgs}
                    RenderHeaders={RenderHeaders}
                    showAlert={showAlert}
                  />
                </div>
              </div>

              <div className="ml-2 col-md-6" width={"65%"}>
                {/* <div> */}

                <ChartComponent
                  className="chartComponent"
                  ref={CoolingTimeChart}
                  tooltip={{ enable: true }}
                  width="100%"
                  height="400"
                  border={{ width: 1, color: "darkblue" }}
                  title="Cooling Time Study"
                  primaryXAxis={{
                    title: "Cooling Time Study",
                    minimum: TimeniceNumbers.min || null,
                    maximum: TimeniceNumbers.max || null,
                    interval: TimeniceNumbers.step || null,
                    lineStyle: { color: "black" },
                  }}
                  primaryYAxis={{
                    title:
                      `${CoolingGridHeader}` === "Cooling Time Study"
                        ? ""
                        : `${CoolingGridHeader}`,
                    minimum: DimniceNumbers.min || null,
                    maximum: DimniceNumbers.max || null,
                    interval: DimniceNumbers.step || null,
                    lineStyle: { color: "black" },
                  }}
                >
                  <Inject
                    services={[LineSeries, Category, DataLabel, Tooltip]}
                  />

                  <SeriesCollectionDirective>
                    {/* chartData is the name of the Array which contains our data and again grid2 will be varying */}

                    <SeriesDirective
                      type="Line"
                      fill="rgb(2,0,4)"
                      width={2.5}
                      dataSource={chartData}
                      xName="value0"
                      yName={grid2 ? grid2 : "value1"}
                      marker={{ visible: true }}
                    ></SeriesDirective>
                  </SeriesCollectionDirective>
                </ChartComponent>

                {/* </div> */}

                <div>
                  <div className="ml-4">
                    <div className="d-flex form-group ">
                      <div className="mt-1 mr-2">
                        <label className="lbl_design" style={{ fontSize: 12 }}>
                          {" "}
                          Y-Axis:{" "}
                        </label>
                      </div>

                      <div style={{ width: "200px" }}>
                        <select
                          style={{ height: 22 }}
                          className="form-control digits mt-1"
                          id="Cooling-Column"
                          onChange={setGrid}
                          onClick={(e) => setGrid2(`value${e.target.value}`)}
                        >
                          <option value={null}>
                            {" "}
                            {column.length > 0 ? "Select Column" : ""}{" "}
                          </option>
                          {column.map((value, key) => (
                            <React.Fragment key={key}>
                              {value.id === 0 ? (
                                "-"
                              ) : (
                                <option value={key === 0 ? key + 1 : key}>
                                  {" "}
                                  {value.header}{" "}
                                </option>
                              )}
                            </React.Fragment>
                          ))}
                        </select>
                      </div>

                      <div className="ml-2">
                        <button
                          className="btn btn-primary btn-air-primary mr-2"
                          onClick={PlotChart}
                        >
                          {" "}
                          Show Graph{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* ************************* Printable Part ********************* */}
      <section
        className={showPrint ? "Printable_Part" : "Showable_Part"}
        ref={componentRef}
      >
        <CoolingPrint
          column={column}
          CoolingTimeGridData={CoolingTimeGridData}
          grid2={grid2}
          Mold_Name={Mold_Name}
          Session_Name={Session_Name}
          CoolingGridHeader={CoolingGridHeader}
          Comment={Comment}
          chartData={chartData}
          TimeniceNumbers={TimeniceNumbers}
          DimniceNumbers={DimniceNumbers}
        />
      </section>
    </>
  );
};

export default CoolingTimeStudy;
