import React, { useState, useRef, useEffect } from "react";
// Tab view component from syncfusion to navigate through six steps study
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import Header2 from "../common/header-component/header2";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import InjSpeed from "./Inj_Speed_Linearity/InjSpeed";
import ShotRepeatability from "./Shot_Repeatability_Study/ShotRepeatability";
import LoadSensitivity from "./Load_Sensitivity_Test/LoadSensitivity";
import DataService from "../../services/ApiService";

import { Button } from "reactstrap";

const EquipmentDashboard = () => {
  const history = useHistory();

  const [MoldId, setMoldId] = useState();
  const [MoldName, setMoldName] = useState("mold");
  const [Session_Name, setSession_Name] = useState("");
  const [Session_Id, setSession_Id] = useState();

  // Declare the names of the tab's
  let headertext = [
    { text: "Inj Speed Linearity" },
    { text: "Shot Repeatability Study" },
    { text: "Load Sensitivity Test" },
  ];

  // eslint-disable-next-line
  const showAlert = useRef(false);

  const GoToMolds = useRef(false);

  // This function will be called when the user clicks on the save button of the study
  const SavedModal = () => {
    saveData().then(() => {
      const saveddialogBox = document.getElementById("SaveddialogBox");
      saveddialogBox.classList.remove("hidden");
    });
  };

  // Instance of dialog box Asking for saving data before leaving study
  const dialogBox = document.getElementById("dialogBox");

  // Instance of dialog box which will be shown after saving
  const SavedandExitdialogBox = document.getElementById(
    "SavedandExitdialogBox"
  );

  // Event to get back to Session's
  const BackToMold = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
    } else {
      if (GoToMolds.current) {
        history.push("/dashboard");
      } else {
        history.push(
          `/sessions/equipmentqualification`
        );
      }
    }
  };

  const BackToDashboard = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
      GoToMolds.current = true;
    } else {
      history.push("/dashboard");
    }
  };

  // Event to close the saving confirmation dialog
  const closeDialog = () => {
    const dialogBox = document.getElementById("dialogBox");

    dialogBox.classList.add("hidden");
  };

  // Event to finally close and exit study after saving
  const closeandExitSavedDialog = () => {
    const saveddialogBox = document.getElementById("SavedandExitdialogBox");

    saveddialogBox.classList.add("hidden");

    if (GoToMolds.current) {
      history.push("/dashboard");
    } else {
      history.push(
        `/sessions/equipmentqualification`
      );
    }
  };

  // Event to close the dialog which will be shown after saving
  const closeSavedDialog = () => {
    const saveddialogBox = document.getElementById("SaveddialogBox");

    saveddialogBox.classList.add("hidden");
  };

  // Boolean variable to switch between the save and update button
  const ToSaveInjSpeedData = useRef(true);
  const ToSaveShotData = useRef(true);
  const ToSaveLoadData = useRef(true);

  const InjSpeedData = useRef([]);
  const ShotData = useRef([]);
  const LoadData = useRef([]);

  // Event to Call the POST request API and save the data
  const saveData = () => {
    return new Promise((resolve, reject) => {
      if (ToSaveInjSpeedData.current) {
        // console.log('Saved', InjSpeedData.current)

        DataService.SaveInjSpeedData(InjSpeedData.current)
          .then((res) => {
            if (res.data.message !== "Please Enter a valid Data") {
              ToSaveInjSpeedData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });
      } else {
        // console.log('Updated', InjSpeedData.current)

        DataService.UpdateInjSpeedData(Session_Id, InjSpeedData.current)
          .then((res) => {
            if (res.data.message !== "Please Enter a valid Data") {
              ToSaveInjSpeedData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });
      }

      if (ToSaveShotData.current) {
        // console.log(ShotData.current)

        DataService.SaveShotData(ShotData.current)
          .then((res) => {
            if (res.data.message !== "Please Enter a valid Data") {
              ToSaveShotData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });
      } else {
        // console.log(ShotData.current)

        DataService.UpdateShotData(Session_Id, ShotData.current)
          .then((res) => {
            if (res.data.message !== "Please Enter a valid Data") {
              ToSaveShotData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });
      }

      if (ToSaveLoadData.current) {
        // console.log(ShotData.current)

        DataService.SaveLoadData(LoadData.current)
          .then((res) => {
            if (res.data.message !== "Please Enter a valid Data") {
              ToSaveLoadData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });
      } else {
        // console.log(ShotData.current)

        DataService.UpdateLoadData(Session_Id, LoadData.current)
          .then((res) => {
            if (res.data.message !== "Please Enter a valid Data") {
              ToSaveLoadData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });
      }

      resolve();
    });
  };

  const SaveandExit = () => {
    saveData().then(() => {
      closeDialog();

      SavedandExitdialogBox.classList.remove("hidden");
    });
  };

  useEffect(() => {
    sessionStorage.removeItem("SelectedMoldData");
  }, []);

  // These are the event's which will get called when clicked on the respective step's tab and once they are called they render the component of that respective tab which we have imported above
  function content0() {
    return (
      <div>
        <InjSpeed
          setSession_Id={setSession_Id}
          setSession_Name={setSession_Name}
          setMoldId={setMoldId}
          setMoldName={setMoldName}
          showAlert={showAlert}
          ToSaveInjSpeedData={ToSaveInjSpeedData}
          InjSpeedData={InjSpeedData}
        />
      </div>
    );
  }

  function content1() {
    return (
      <div>
        <ShotRepeatability
          showAlert={showAlert}
          ShotData={ShotData}
          ToSaveShotData={ToSaveShotData}
        />
      </div>
    );
  }

  function content2() {
    return (
      <div>
        <LoadSensitivity
          showAlert={showAlert}
          ToSaveLoadData={ToSaveLoadData}
          LoadData={LoadData}
        />
      </div>
    );
  }

  return (
    <>
      {/* ************************************************ Saving Modals *******************************************/}
      {/* To alert while going back to sessions page */}
      <div id="dialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Do you want to save Equipment Qualification ? </p>
          <Button className="mr-1" id="closeDialogBtn" onClick={SaveandExit}>
            {" "}
            Yes{" "}
          </Button>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeandExitSavedDialog}
          >
            {" "}
            No{" "}
          </Button>
          <Button className="mr-1" id="closeDialogBtn" onClick={closeDialog}>
            {" "}
            Cancel{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved and when clicked on close button exit */}
      <div id="SavedandExitdialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeandExitSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved, when clicked on saved button */}
      <div id="SaveddialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      <Header2 Title="Equipment Qualification" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex">
            <div>
              <span
                className="BreadCrum"
                onClick={BackToDashboard}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Dashboard{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span
                className="BreadCrum"
                onClick={BackToMold}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Sessions{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "14px" }}>
                {" "}
                Equipment Qualification{" "}
              </span>
            </div>
          </div>

          {/* <div className="d-flex">
            <div>
              <span className="Mold-Session">
                {" "}
               Mold Id : {MoldName ? atob(MoldName) : ""}{" "}
              </span>
            </div>

            <div className="ml-4">
              <span className="Mold-Session">
                {" "}
                Session Name : {Session_Name}{" "}
              </span>
            </div>
          </div> */}
        </div>

        {/* <div className="study-container"> */}

        <div className="d-flex">
          <div className="col-md-10">
            {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}
            <TabComponent heightAdjustMode="Auto" id="defaultTab">
              <TabItemsDirective>
                <TabItemDirective header={headertext[0]} content={content0} />
                <TabItemDirective header={headertext[1]} content={content1} />
                <TabItemDirective header={headertext[2]} content={content2} />
              </TabItemsDirective>
            </TabComponent>
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-secondary btn-air-secondary"
              type="button"
              onClick={SavedModal}
            >
              {" "}
              Save{" "}
            </button>
            {/* <button className="btn btn-secondary btn-air-secondary  mr-2 ml-2" type="button"> Print </button> */}
          </div>
        </div>

        {/* </div> */}

        <ToastContainer />
      </div>
    </>
  );
};

export default EquipmentDashboard;
