import React from "react";
import "../../assets/custom-stylesheet/app2_style.css";
import "../../assets/custom-stylesheet/samplepage_style.css";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import Conversions from "../conversions/Conversions";
import { useHistory } from 'react-router-dom';

const ConversionGrid = () => {

    const history = useHistory();

    return (
        <>
            <div className="container-fluid">
                <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
                    <div className="d-flex">
                    <div>
                        <span className="BreadCrum" style={{ fontSize: '14px', color:'blue' }} onClick={() => history.push('/dashboard/mold')}> Dashboard </span>
                    </div>
                    <div>
                        <span className="BreadCrum" style={{ fontSize: '16px' }}> {">"} </span>
                    </div>
                    <div>
                        <span style={{ fontSize: '14px' }}> Conversions </span>
                    </div>
                    </div>
                </div>

                <div>
                <Conversions />
            </div>

            </div>
            
        </>
    );
};

export default ConversionGrid;

