import React from "react";

const ScrewRotGrid = ({ PPS_Data, Machine_Unit_Data }) => {
  const CreateInputData = (event) => {
    const { name, value, dataset } = event.target;

    let data = {
      paramID: dataset.id,
      paramName: name,
      paramUnit:
        dataset.section === "fldScrewRotationSpeed"
          ? 21
          : dataset.section === "fldBackPress"
          ? Machine_Unit_Data.pressure.unit_id
          : "",
      paramValue: value,
    };

    PPS_Data.Inputs = [
      {
        ...PPS_Data.Inputs[0],
        [dataset.section]: data,
      },
    ];
  };

  return (
    <div id="PPSGrid" className="ScrewRotGrid InputsGrid">
      <table>
        <tbody>
          <tr>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"53"}
                data-section={"fldScrewRotationSpeed"}
                name="fldScrewRotationSpeed"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].fldScrewRotationSpeed?.paramValue
                }
              />
            </td>
          </tr>
          <tr>
            <td> Screw Rot Speed </td>
          </tr>
          <tr>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"54"}
                data-section={"fldBackPress"}
                name="fldBackPress"
                onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].fldBackPress?.paramValue}
              />
            </td>
          </tr>
          <tr>
            <td>Back Pressure </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ScrewRotGrid;
