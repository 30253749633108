import React, { useEffect } from "react";
import "./InputGrids.css";
import MoldTempGrid from "./InputGrids/MoldTempGrid";
import BarrelSettingsGrid from "./InputGrids/BarrelSettingsGrid";
import DecompressionGrid from "./InputGrids/DecompressionGrid";
import PositionGrid from "./InputGrids/PositionGrid";
import PackHoldGrid from "./InputGrids/PackHoldGrid";
import InjectionPhaseGrid from "./InputGrids/InjectionPhaseGrid";
import Screw from "./InputImages/Screw.png";
import brackets from "./InputImages/brackets.png";
import arrow from "./InputImages/arrow.png";
import ScrewRotGrid from "./InputGrids/ScrewRotGrid";

const Inputs = ({ PPS_Data, Machine_Unit_Data, showAlert }) => {
  // useEffect(() => {
  //   setTabIdx(2);
  // }, []);
  return (
    <>
      <div className="mb-2" style={{ width: "94vw", height: "68vh" }}>
        <div className="d-flex">
          <div>
            <MoldTempGrid
              PPS_Data={PPS_Data}
              Machine_Unit_Data={Machine_Unit_Data}
              showAlert={showAlert}
            />
          </div>

          <div>
            <BarrelSettingsGrid PPS_Data={PPS_Data} showAlert={showAlert} />
          </div>

          <div>
            <DecompressionGrid PPS_Data={PPS_Data} showAlert={showAlert} />
          </div>
        </div>

        <div className="Screw_Section">
          <div>
            <img src={Screw} alt="screw" width={770} height={70} />
          </div>
          <div>
            <PositionGrid
              PPS_Data={PPS_Data}
              Machine_Unit_Data={Machine_Unit_Data}
              showAlert={showAlert}
            />
          </div>
        </div>

        <div className="d-flex brackets-section">
          <div>
            <img src={brackets} alt="brackets" width={125} height={25} />
          </div>
          <div>
            <img src={brackets} alt="brackets" width={120} height={25} />
          </div>
          <div>
            <img src={brackets} alt="brackets" width={120} height={25} />
          </div>
          <div>
            <img src={brackets} alt="brackets" width={120} height={25} />
          </div>
          <div>
            <img src={brackets} alt="brackets" width={120} height={25} />
          </div>
          <div>
            <img src={brackets} alt="brackets" width={120} height={25} />
          </div>
        </div>

        <div className="d-flex">
          <div>
            <img
              className="ml-2"
              src={arrow}
              alt="arrow"
              width={580}
              height={15}
            />
            <PackHoldGrid
              PPS_Data={PPS_Data}
              Machine_Unit_Data={Machine_Unit_Data}
            />
          </div>
          <div>
            <img
              className="ml-3"
              src={arrow}
              alt="arrow"
              width={575}
              height={15}
            />
            <InjectionPhaseGrid
              PPS_Data={PPS_Data}
              Machine_Unit_Data={Machine_Unit_Data}
            />
          </div>

          <div>
            <ScrewRotGrid
              PPS_Data={PPS_Data}
              Machine_Unit_Data={Machine_Unit_Data}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Inputs;
