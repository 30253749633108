import http from "./http-common";

// *********************** Auth API's **************************

// Event to call the SignIn Api
const SignInUser = (name, email, password, re_password) => {
  const body = JSON.stringify({ name, email, password, re_password });

  return http.post("/auth/users/", body);
};

// *********************** Mold Api's ***************************

// Event to make GET request which fetches the mold as per user
const FetchMold = (id) => {
  return http.get(`mold_session/mold/${id}`);
};

// Event to make a POST request to save the created mold in the DB.
const SaveMold = (Mold) => {
  return http.post("mold_session/mold_save/", Mold);
};

// Event to Fetch mold details as per id
const GetMold = (id) => {
  return http.get(`mold_session/mold_by_id/${id}`);
};

// Event to Update mold details as per id
const UpdateAllMold = (id, Mold) => {
  return http.put(`mold_session/update_all_mold/${id}`, Mold);
};

const UpdateMold = (id, Mold) => {
  return http.put(`mold_session/mold_update/${id}`, Mold);
};

// Event to Delete mold details as per id
const DeleteMold = (id) => {
  return http.delete(`mold_session/mold_update/${id}`);
};

// *********************** Session Api's ***************************
// Event to make GET request which fetches the session as per mold
const FetchSession = (moldId) => {
  return http.get(`mold_session/session/${moldId}`);
};

// Event to make a POST request to save the created session in the DB.
const SaveSession = (session) => {
  return http.post("mold_session/session_save/", session);
};

// Event to make a POST request to delete the created session from the DB.
const DeleteSession = (sessionId) => {
  return http.delete(`mold_session/update_session/${sessionId}`);
};

// Event to make a POST request to Update the created session from the DB.
const UpdateSession = (sessionId, data) => {
  return http.put(`mold_session/update_session/${sessionId}`, data);
};

// *********************** Melt Prep Api's ***************************
// Event to make GET request which fetches the session as per user
const FetchMeltSession = (user) => {
  return http.get(`meltpreparation/get_session/${user}`);
};

// Event to make a POST request to save the created session in the DB.
const SaveMeltSession = (session) => {
  return http.post("meltpreparation/session_save/", session);
};

// Event to make a POST request to delete the created session from the DB.
const DeleteMeltSession = (sessionId) => {
  return http.delete(`meltpreparation/update_session/${sessionId}`);
};

// Event to make a POST request to Update the created session from the DB.
const UpdateMeltSession = (sessionId, data) => {
  return http.put(`meltpreparation/update_session/${sessionId}`, data);
};

const SaveScrewRotationData = (data) => {
  return http.post("/meltpreparation/screw_rotation_save/", data);
};

const UpdateScrewRotationData = (id, data) => {
  return http.put(`/meltpreparation/get_screw_rotation/${id}`, data);
};

const GetScrewRotationData = (id, data) => {
  return http.get(`/meltpreparation/get_screw_rotation/${id}`, data);
};

const SaveBackPressureData = (data) => {
  return http.post("/meltpreparation/save_pressure_data/", data);
};

const UpdateBackPressureData = (id, data) => {
  return http.put(`/meltpreparation/get_pressure_data/${id}`, data);
};

const GetBackPressureData = (id, data) => {
  return http.get(`/meltpreparation/get_pressure_data/${id}`, data);
};

// ************************ Equipment Qualification Api's ***************************
// Session's*******************************************
const FetchEquipmentSession = (user) => {
  return http.get(`equipment/get_session/${user}`);
};

// Event to make a POST request to save the created session in the DB.
const SaveEquipmentSession = (session) => {
  return http.post("equipment/session_save/", session);
};

// Injection Speed****************************************
const SaveInjSpeedData = (data) => {
  return http.post("/equipment/save_Inj_data/", data);
};

const UpdateInjSpeedData = (id, data) => {
  return http.put(`/equipment/get_Inj_data/${id}`, data);
};

const GetInjSpeedData = (id, data) => {
  return http.get(`/equipment/get_Inj_data/${id}`, data);
};

// Load Sensitivity*********************************************
const SaveLoadData = (data) => {
  return http.post("/equipment/save_Load_data/", data);
};

const UpdateLoadData = (id, data) => {
  return http.put(`/equipment/get_Load_data/${id}`, data);
};

const GetLoadData = (id, data) => {
  return http.get(`/equipment/get_Load_data/${id}`, data);
};

// Shot Repeatability*********************************************
const SaveShotData = (data) => {
  return http.post("/equipment/save_Shot_data/", data);
};

const UpdateShotData = (id, data) => {
  return http.put(`/equipment/get_Shot_data/${id}`, data);
};

const GetShotData = (id, data) => {
  return http.get(`/equipment/get_Shot_data/${id}`, data);
};

// ************************ Quality Data Api's ***************************
// Session's*******************************************
const FetchQualitySession = (user) => {
  return http.get(`quality/get_session/${user}`);
};

// Event to make a POST request to save the created session in the DB.
const SaveQualitySession = (session) => {
  return http.post("quality/session_save/", session);
};

// Event to make a POST request to delete the created session from the DB.
const DeleteQualitySession = (sessionId) => {
  return http.delete(`quality/update_session/${sessionId}`);
};

// Event to make a POST request to Update the created session from the DB.
const UpdateQualitySession = (sessionId, data) => {
  return http.put(`quality/update_session/${sessionId}`, data);
};

// Process Capability ***********************************************
const SaveCapabilityData = (data) => {
  return http.post("/quality/save_capability_data/", data);
};

const UpdateCapabilityData = (id, data) => {
  return http.put(`/quality/get_capability_data/${id}`, data);
};

const GetCapabilityData = (id, data) => {
  return http.get(`/quality/get_capability_data/${id}`, data);
};

// *********************** PPS Api's ***************************
// Event to make GET request which fetches the session as per user
const FetchPPSSession = (user) => {
  return http.get(`PPS/get_session/${user}`);
};

// Event to make a POST request to save the created session in the DB.
const SavePPSSession = (session) => {
  return http.post("PPS/session_save/", session);
};

const FetchPPSData = (sessionId) => {
  return http.get(`PPS/PPSData_by_id/${sessionId}`);
};

// Event to make a POST request to delete the created session from the DB.
const DeletePPSSession = (sessionId) => {
  return http.delete(`PPS/update_session/${sessionId}`);
};

// Event to make a POST request to Update the created session from the DB.
const UpdatePPSSession = (sessionId, data) => {
  return http.put(`PPS/update_session/${sessionId}`, data);
};

const UpdatePPSData = (sessionId, data) => {
  return http.put(`PPS/PPSData_by_id/${sessionId}`, data);
};

// Event to make a POST request to save the created session in the DB.
const SavePPSData = (data) => {
  return http.post("PPS/PPSData_save/", data);
};

// *********************** Doe Api's ***************************
const FetchDoeSession = (user) => {
  return http.get(`doe/get_session/${user}`);
};

// Event to make a POST request to save the created session in the DB.
const SaveDoeSession = (session) => {
  return http.post("doe/session_save/", session);
};

const CreateSession = (sessionId, data) => {
  // console.log(sessionId);
  return http.post(`doe/create-session/${sessionId}`, data);
};

// Event to make a POST request to delete the created session from the DB.
const DeleteDOESession = (sessionId) => {
  return http.delete(`doe/update_session/${sessionId}`);
};

// Event to make a POST request to Update the created session from the DB.
const UpdateDOESession = (sessionId, data) => {
  return http.put(`doe/update_session/${sessionId}`, data);
};

const ExptData = (sessionID) => {
  return http.get(`doe/create-session/${sessionID}`);
};

const PostVariable = (sessionID, data) => {
  return http.post(`doe/define-variable/${sessionID}`, data);
};

const PostResponse = (sessionID, mold_id, data) => {
  return http.post(`doe/define-response/${sessionID}/${mold_id}`, data);
};

const GetDefineResponse = (sessionID, moldID) => {
  return http.get(`doe/define-response/${sessionID}/${moldID}`);
};

const FactorialTable = (sessionID) => {
  return http.get(`doe/exp-runs/${sessionID}`);
};

const ObservationalData = (sessionID, data) => {
  return http.post(`doe/input-worksheet/${sessionID}`, data);
};

const Anova = (sessionID) => {
  return http.get(`doe/summary/${sessionID}`);
};

const GetContour = (sessionID) => {
  return http.get(`doe/contour/${sessionID}`);
};

const PostContour = (sessionID, data) => {
  return http.post(`doe/contour/${sessionID}`, data);
};

const SelectDropdown = (sessionID, data) => {
  return http.post(`doe/select_response/${sessionID}`, data);
};

const MainEffectPlot = (sessionID) => {
  return http.get(`doe/main_effect_plot/${sessionID}`);
};

const GetObservationalData = (sessionID) => {
  return http.get(`doe/input-worksheet/${sessionID}`);
};

const GetVariableData = (sessionID) => {
  return http.get(`doe/define-variable/${sessionID}`);
};

const GetSensitivityOutliers = (sessionID) => {
  return http.get(`doe/sensitivity_outliers/${sessionID}`);
};

const NumericalPrediction = (sessionID) => {
  return http.get(`doe/numerical_prediction/${sessionID}`);
};

const PostNumericalPrediction = (sessionID, data) => {
  return http.post(`doe/numerical_prediction/${sessionID}`, data);
};

// Options and Settings ****************************************
const GetUnits = (user) => {
  return http.get(`/mold_session/units_by_id/${user}`);
};

const SaveUnits = (data) => {
  return http.post(`/mold_session/save_units/`, data);
};

const UpdateUnits = (user, data) => {
  return http.put(`/mold_session/update_units/${user}`, data);
};

const GetStoredUnits = () => {
  return http.get(`/mold_session/stored_units/`);
};

// Material Database
const GetMaterialsData = (user) => {
  return http.get(`/databases/all_materials/${user}`);
};

const DeleteMaterialsData = (id) => {
  return http.delete(`/databases/delete_materials/${id}`);
};

const SaveMaterialData = (data) => {
  return http.post(`/databases/save_materials/`, data);
};

const UpdateMaterialData = (user, data) => {
  return http.put(`/databases/update_materials/${user}`, data);
};

const UpdateAllMaterialData = (user, data) => {
  return http.put(`/databases/update_all_material/${user}`, data);
};

// machine Database
const GetMachinesData = (user) => {
  return http.get(`/databases/all_machine/${user}`);
};

const DeleteMachinesData = (id) => {
  return http.delete(`/databases/delete_machine/${id}`);
};

const SaveMachineData = (data) => {
  return http.post(`/databases/save_machine/`, data);
};

const UpdateMachineData = (user, data) => {
  return http.put(`/databases/update_machine/${user}`, data);
};

const UpdateAllMachineData = (user, data) => {
  return http.put(`/databases/update_all_machine/${user}`, data);
};

// Custom Fields
const GetFieldsData = (user) => {
  return http.get(`/databases/get_fields_data/${user}`);
};

const SaveFieldsData = (data) => {
  return http.post(`/databases/save_fields/`, data);
};

const UpdateFieldsData = (user, data) => {
  return http.put(`/databases/get_fields_data/${user}`, data);
};

// Attribute ***********************************************
const SaveAttributeData = (data) => {
  return http.post("/quality/save_Attribute_data/", data);
};

const UpdateAttributeData = (id, data) => {
  return http.put(`/quality/get_Attribute_data/${id}`, data);
};

const GetAttributeData = (id, data) => {
  return http.get(`/quality/get_Attribute_data/${id}`, data);
};

// PostMold ***********************************************
const SavePostMoldData = (data) => {
  return http.post("/quality/save_PostMold_data/", data);
};

const UpdatePostMoldData = (id, data) => {
  return http.put(`/quality/get_PostMold_data/${id}`, data);
};

const GetPostMoldData = (id, data) => {
  return http.get(`/quality/get_PostMold_data/${id}`, data);
};

// *********************** Viscosity Api's ***************************

// Event to make a GET request to fetch the respective session's Viscosity data
const FetchViscosity = (sessionId) => {
  return http.get(`six-step/viscosity/${sessionId}`);
};

// Event to make a PUT request to update the respective session's Viscosity data
const UpdateViscosity = (sessionId, data) => {
  return http.put(`six-step/viscosity/${sessionId}`, data);
};

// Event to make POST request to save the respective session's Viscosity data
const SaveViscosity = (data) => {
  return http.post("six-step/viscosity_save/", data);
};

// *********************** Cavity Api's ***************************

// Event to make a GET request to fetch the respective session's Cavity data
const FetchCavity = (sessionId) => {
  return http.get(`six-step/cavity/${sessionId}`);
};

// Event to make a PUT request to update the respective session's Cavity data
const UpdateCavity = (sessionId, data) => {
  return http.put(`six-step/cavity/${sessionId}`, data);
};

// Event to make POST request to save the respective session's Cavity data
const SaveCavity = (data) => {
  return http.post("six-step/cavity_save/", data);
};

// *********************** Pressure Drop Api's ***************************

// Event to make a GET request to fetch the respective session's Pressure Drop data
const FetchPressure = (sessionId) => {
  return http.get(`six-step/pressure/${sessionId}`);
};

// Event to make a PUT request to update the respective session's Pressure Drop data
const UpdatePressure = (sessionId, data) => {
  return http.put(`six-step/pressure/${sessionId}`, data);
};

// Event to make POST request to save the respective session's Pressure Drop data
const SavePressure = (data) => {
  return http.post("six-step/pressure_save/", data);
};

// *********************** Cosmetic Pressure Api's ***************************

// Event to make a GET request to fetch the respective session's Cosmetic Pressure data
const FetchCosmetic = (sessionId) => {
  return http.get(`six-step/cosmetic/${sessionId}`);
};

// Event to make a PUT request to update the respective session's Cosmetic Pressure data
const UpdateCosmetic = (sessionId, data) => {
  return http.put(`six-step/cosmetic/${sessionId}`, data);
};

// Event to make POST request to save the respective session's Cosmetic Pressure data
const SaveCosmetic = (data) => {
  return http.post("six-step/cosmetic_save/", data);
};

// *********************** Cold Runner Pressure Api's ***************************

// Event to make a GET request to fetch the respective session's Cold Runner Pressure data
const FetchGateSeal = (sessionId) => {
  return http.get(`six-step/gate_seal/${sessionId}`);
};

// Event to make a PUT request to update the respective session's Cold Runner Pressure data
const UpdateGateSeal = (sessionId, data) => {
  return http.put(`six-step/gate_seal/${sessionId}`, data);
};

// Event to make POST request to save the respective session's Cold Runner Pressure data
const SaveGateSeal = (data) => {
  return http.post("six-step/gate_seal_save/", data);
};

// *********************** Cooling Time Api's ***************************

// Event to make a GET request to fetch the respective session's Cooling Time data
const FetchCooling = (sessionId) => {
  return http.get(`six-step/cooling_time/${sessionId}`);
};

// Event to make a PUT request to update the respective session's Cooling Time data
const UpdateCooling = (sessionId, data) => {
  return http.put(`six-step/cooling_time/${sessionId}`, data);
};

// Event to make POST request to save the respective session's Cooling Time data
const SaveCooling = (data) => {
  return http.post("six-step/cooling_time_save/", data);
};

export default {
  FetchMold,
  SaveMold,
  UpdateAllMold,
  FetchSession,
  SaveSession,
  DeleteSession,
  UpdateSession,
  DeleteMeltSession,
  UpdateMeltSession,
  DeleteQualitySession,
  UpdateQualitySession,
  DeletePPSSession,
  UpdatePPSSession,
  FetchViscosity,
  UpdateViscosity,
  SaveViscosity,
  GetMold,
  FetchCavity,
  UpdateCavity,
  SaveCavity,
  FetchPressure,
  UpdatePressure,
  SavePressure,
  FetchCosmetic,
  UpdateCosmetic,
  SaveCosmetic,
  FetchGateSeal,
  UpdateGateSeal,
  SaveGateSeal,
  FetchCooling,
  UpdateCooling,
  SaveCooling,
  UpdateMold,
  DeleteMold,
  SignInUser,
  FetchMeltSession,
  SaveMeltSession,
  UpdatePPSData,
  FetchPPSSession,
  SavePPSSession,
  FetchPPSData,
  SavePPSData,
  SaveScrewRotationData,
  UpdateScrewRotationData,
  GetScrewRotationData,
  SaveBackPressureData,
  UpdateBackPressureData,
  GetBackPressureData,
  FetchEquipmentSession,
  SaveEquipmentSession,
  SaveInjSpeedData,
  GetInjSpeedData,
  UpdateInjSpeedData,
  SaveLoadData,
  UpdateLoadData,
  GetLoadData,
  SaveShotData,
  GetShotData,
  UpdateShotData,
  FetchQualitySession,
  SaveQualitySession,
  SaveCapabilityData,
  UpdateCapabilityData,
  GetCapabilityData,
  SaveAttributeData,
  UpdateAttributeData,
  GetAttributeData,
  SavePostMoldData,
  UpdatePostMoldData,
  GetPostMoldData,
  GetUnits,
  SaveUnits,
  UpdateUnits,
  GetStoredUnits,
  GetMaterialsData,
  SaveMaterialData,
  UpdateMaterialData,
  DeleteMaterialsData,
  GetFieldsData,
  SaveFieldsData,
  UpdateFieldsData,
  UpdateAllMaterialData,
  GetMachinesData,
  DeleteMachinesData,
  SaveMachineData,
  UpdateMachineData,
  UpdateAllMachineData,
  // *********************************** Doe Api's ***************************
  FetchDoeSession,
  SaveDoeSession,
  CreateSession,
  DeleteDOESession,
  UpdateDOESession,
  ExptData,
  PostVariable,
  PostResponse,
  GetDefineResponse,
  FactorialTable,
  ObservationalData,
  SelectDropdown,
  Anova,
  MainEffectPlot,
  PostContour,
  GetContour,
  GetObservationalData,
  GetVariableData,
  GetSensitivityOutliers,
  NumericalPrediction,
  PostNumericalPrediction,
};
