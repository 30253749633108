import React, { useState, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Header2 from "../../common/header-component/header2";
// CSS
import "../../App.css";
import { Table } from "reactstrap";

// To generate random Id
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { connect } from "react-redux";
import DataService from "../../../services/ApiService";

import { useHistory } from "react-router-dom";

import moldDiagram from "../../../assets/images/Mold_Diagram.jpg";
import ConvertInputFields from "./ConvertInputFields";
import LeftInputSection from "./LeftInputSection";
import RightInputSection from "./RightInputSection";
import BreadCrumb from "../CommonSections/BreadCrumb";

const MoldNew = ({ user }) => {
  const history = useHistory();

  const [EditModal, setEditModal] = useState(false);

  const [header, setHeader] = useState({
    header: "",
    key: null,
  });

  const [column, setColumn] = useState([]);

  const [DuplicateValue, setDuplicatetValue] = useState(false);

  const [EmptyAlert, setEmptyAlert] = useState(false);

  const [isColumnId, setIsColumnId] = useState(null);

  const [isDeleteId, setIsDeleteId] = useState(null);

  const [FieldsExists, setFieldsExists] = useState(false);

  const [SamePage, setSamePage] = useState(false);

  const [ImgModal, setImgModal] = useState(false);

  const ToggleImgModal = () => {
    setImgModal(!ImgModal);
  };

  // State and Event for the Edit and Add Modal
  const [modal, setModal] = useState();

  const toggleEditHeader = () => {
    setModal(!modal);
  };

  let StoredUnits = JSON.parse(sessionStorage.getItem("StoredUnits")) || [];
  let SelectedUnits = JSON.parse(sessionStorage.getItem("SelectedUnits")) || [];
  let MaterialData = JSON.parse(sessionStorage.getItem("MaterialData")) || [];

  // To check for unique mold Id's
  let StoredMoldData = JSON.parse(sessionStorage.getItem("MoldData")) || [];

  const [UserSelectedUnits, setUserSelectedUnits] = useState([]);
  const [BaseUnits, setBaseUnits] = useState([]);
  const [BaseUnitsArray, setBaseUnitsArray] = useState([]);
  const [SelectedUnitsArray, setSelectedUnitsArray] = useState([]);

  const [SelectedPart, setSelectedPart] = useState("Distance");

  const [UnitsForConversion, setUnitsForConversion] = useState({
    FromUnit: "",
    ToUnit: "",
  });

  const [maxLength, setMaxLength] = useState(null);

  const [UnitSettings, setUnitSettings] = useState({
    TimeUnit: {},
    DistanceUnit: {},
    WeightUnit: {},
    RunnerWeightUnit: {},
    AreaUnit: {},
    RunnerAreaUnit: {},
  });

  const [moldData, setmoldData] = useState({
    Mold_No: "",
    Material_Id: "",
    Platen_Orientation: "Horizontal",
    Number_of_Bases: "1",
    Is_This_A_New_Mold: "No",
    Hot_Runner_Volume: "",
    Cycle_Time: "",
    Mold_Stack_Height: "",
    Mold_Vertical_Height: "",
    Req_Mold_Open_Stroke: "",
    Mold_Width: "",
    Number_of_Core_Pulls: "",
    Number_Of_Parts: "1",
    Part_Description: "",
    Part_Number: "",
    No_of_Cavities: "",
    Starting_Cavity_Number: "",
    Weight_of_one_Part: "",
    Number_Of_Runners: "",
    Runner_Weight: "",
    Part_Projected_Area: "",
    Runner_Projected_Area: "",
  });

  const [moldUnitData, setmoldUnitData] = useState({
    Mold_No: {
      value: "",
      unit_id: "",
    },
    Material_Id: {
      value: "",
      unit_id: "",
    },
    Platen_Orientation: {
      value: "Horizontal",
      unit_id: "",
    },
    Number_of_Bases: {
      value: 1,
      unit_id: "",
    },
    Number_Of_Parts: {
      value: 1,
      unit_id: "",
    },
    Hot_Runner_Volume: {
      value: "",
      unit_id: "",
    },
    Cycle_Time: {
      value: "",
      unit_id: "",
    },
    Mold_Stack_Height: {
      value: "",
      unit_id: "",
    },
    Mold_Vertical_Height: {
      value: "",
      unit_id: "",
    },
    Req_Mold_Open_Stroke: {
      value: "",
      unit_id: "",
    },
    Mold_Width: {
      value: "",
      unit_id: "",
    },
    Number_of_Core_Pulls: {
      value: "",
      unit_id: "",
    },
    Is_This_A_New_Mold: {
      value: "No",
      unit_id: "",
    },
    Part_Description: {
      value: "",
      unit_id: "",
    },
    Part_Number: {
      value: "",
      unit_id: "",
    },
    No_of_Cavities: {
      value: "",
      unit_id: "",
    },
    Starting_Cavity_Number: {
      value: "",
      unit_id: "",
    },
    Weight_of_one_Part: {
      value: "",
      unit_id: "",
    },
    Number_Of_Runners: {
      value: "",
      unit_id: "",
    },
    Runner_Weight: {
      value: "",
      unit_id: "",
    },
    Part_Projected_Area: {
      value: "",
      unit_id: "",
    },
    Runner_Projected_Area: {
      value: "",
      unit_id: "",
    },
  });

  const SetButtonRef = useRef(null);

  const [partColumn, setpartColumn] = useState([
    {
      id: 0,
      Part: "",
      edit: false,
      delete: false,
    },
  ]);

  const [PartData, setPartData] = useState([
    {
      id: 0,
      edit: false,
      Cavity_Data: "Part Description *",
    },
    {
      id: 1,
      edit: true,
      Cavity_Data: "Part Number *",
    },
    {
      id: 2,
      edit: true,
      Cavity_Data: "Number of Cavities *",
    },
    {
      id: 3,
      edit: true,
      Cavity_Data: "Starting Cavity Number *",
    },
    {
      id: 4,
      edit: true,
      Cavity_Data: "Weight of one Part",
    },
    {
      id: 5,
      edit: true,
      Cavity_Data: "Number Of Runners",
    },
    {
      id: 6,
      edit: true,
      Cavity_Data: "Runner Weight",
    },
    {
      id: 7,
      edit: true,
      Cavity_Data: "Part Projected Area",
    },
    {
      id: 8,
      edit: true,
      Cavity_Data: "Runner Projected Area",
    },
  ]);

  const [ModalStates, setModalStates] = useState({
    MoldIdConfirm: {
      visibility: false,
      message: "Mold No is mandatory.",
    },
    DeleteConfirm: {
      visibility: false,
      message:
        "The field may have data associated with it. Do you want to continue deleting the same ?",
    },
    MoldIdUnique: {
      visibility: false,
      message: "Mold No should be unique. This Mold No already exists.",
    },
    ConvertConfirm: {
      visibility: false,
      message:
        "You are changing the units, do you want to change the value accordingly ?",
    },
    DeletePartConfirm: {
      visibility: false,
      message: "Information may lost.",
    },
  });

  const ToggleModalStates = (ModalKey) => {
    setModalStates({
      ...ModalStates,
      [ModalKey]: {
        ...ModalStates[ModalKey],
        visibility: !ModalStates[ModalKey].visibility,
      },
    });
  };

  const handleChange = (event) => {
    const { name, value, dataset } = event.target;

    // console.log(value);

    setmoldData(
      name === "Is_This_A_New_Mold" && value === "No"
        ? {
            ...moldData,
            [name]: value,
            Number_Of_Parts: "1",
          }
        : name === "Material_Id"
        ? {
            ...moldData,
            [name]: value === "Select a material" ? "" : value,
          }
        : name === "Number_Of_Parts" && value === "1"
        ? {
            ...moldData,
            [name]: value,
            Is_This_A_New_Mold: "No",
          }
        : {
            ...moldData,
            [name]: value,
          }
    );

    let data = {
      value: value,
      unit_id: dataset.unit ? dataset.unit : null,
    };

    moldUnitData[name] = data;

    setmoldUnitData(
      name === "Is_This_A_New_Mold" && value === "No"
        ? {
            ...moldUnitData,
            [name]: {
              value: value,
              unit_id: dataset.unit ? dataset.unit : null,
            },
            Number_Of_Parts: {
              ...moldUnitData.Number_Of_Parts,
              value: "1",
            },
          }
        : name === "Material_Id"
        ? {
            ...moldUnitData,
            [name]: {
              value: value,
              unit_id: dataset.unit ? dataset.unit : null,
            },
          }
        : {
            ...moldUnitData,
            [name]: {
              value: value === "Select a material" ? "" : value,
              unit_id: dataset.unit ? dataset.unit : null,
            },
          }
    );
  };

  const handleDropDownChange = (event) => {
    const { name, value, dataset } = event.target;

    setSelectedPart(dataset.category);

    setUnitsForConversion({
      FromUnit: UnitSettings.DistanceUnit.unit_id,
      ToUnit: parseInt(value),
    });

    setUnitSettings({
      ...UnitSettings,
      [name]: {
        unit_id: BaseUnits.find((unit) => unit.unit_id === parseInt(value))
          .unit_id,
        unit_name: BaseUnits.find((unit) => unit.unit_id === parseInt(value))
          .unit_name,
        decimals: SelectedUnitsArray.find(
          (unit) => unit.unit_id === parseInt(value)
        )
          ? SelectedUnitsArray.find((unit) => unit.unit_id === parseInt(value))
              .decimals
          : BaseUnits.find((unit) => unit.unit_id === parseInt(value)).decimals,
      },
    });

    let data = {
      value: moldData[dataset.fieldname],
      unit_id: value ? value : null,
    };

    ToggleModalStates("ConvertConfirm");

    setmoldUnitData({
      ...moldUnitData,
      [dataset.fieldname]: data,
    });
  };

  const UpdateInputConversions = () => {
    ConvertInputFields({
      UnitSettings,
      SelectedPart,
      UnitsForConversion,
      setUnitsForConversion,
      moldData,
      moldUnitData,
      PartData,
      partColumn,
      setmoldData,
      setmoldUnitData,
      setPartData,
    });

    ToggleModalStates("ConvertConfirm");
  };

  useEffect(() => {
    // console.log(moldData);
    // console.log(moldUnitData);
  }, [moldUnitData]);

  const UpdateUnitsAndClose = () => {
    ToggleModalStates("ConvertConfirm");

    setmoldUnitData({
      ...moldUnitData,
      Mold_Stack_Height: {
        ...moldUnitData.Mold_Stack_Height,
        unit_id: UnitsForConversion.ToUnit,
      },
      Mold_Vertical_Height: {
        ...moldUnitData.Mold_Vertical_Height,
        unit_id: UnitsForConversion.ToUnit,
      },
      Req_Mold_Open_Stroke: {
        ...moldUnitData.Req_Mold_Open_Stroke,
        unit_id: UnitsForConversion.ToUnit,
      },
      Mold_Width: {
        ...moldUnitData.Mold_Width,
        unit_id: UnitsForConversion.ToUnit,
      },
    });
  };

  const setPart = () => {
    let PartNumber = moldData.Number_Of_Parts;

    let col = [
      {
        id: 0,
        Part: "",
        edit: false,
        delete: false,
      },
    ];

    for (let i = 0; i < parseInt(PartNumber); i++) {
      col.push({
        id: nanoid(),
        Part_No: `Part${i + 1}`,
        delete: true,
      });
    }

    setpartColumn([...col]);

    // if (partColumn.length > 1) ToggleModalStates("DeletePartConfirm");
  };

  const handlePartData = (event) => {
    const { name, value, dataset } = event.target;

    let data = {
      [name]: value,
    };

    setPartData((prevPartData) => {
      const updatedPartData = [...prevPartData];
      updatedPartData[dataset.index] = {
        ...updatedPartData[dataset.index],
        ...data,
      };
      return updatedPartData;
    });
  };

  useEffect(() => {
    // console.log(moldUnitData)
    // console.log(BaseUnits)
  }, [moldUnitData, BaseUnits]);

  useEffect(() => {
    if (SetButtonRef.current) {
      SetButtonRef.current.click();
    }
  }, []);

  const SaveData = () => {
    const newMold = {
      user: user.id,
      Mold_Id: moldData.Mold_No,
      Platen_Orientation: moldData.Platen_Orientation,
      Number_Of_Bases: moldData.Number_of_Bases,
      Is_This_A_New_Mold: moldData.Is_This_A_New_Mold,
      Number_Of_Parts: moldData.Number_Of_Parts,
      Mold_Data: moldData,
      Part_Details: partColumn,
      Part_Data: PartData,
      Units: moldUnitData,
    };

    // console.log(newMold);

    let ColumnData;

    DataService.SaveMold(newMold).then((res) => {
      // console.log(res.data);

      let RowId = btoa(parseInt(res.data.id));

      if (SamePage) {
        history.push({
          pathname: `/database/Options/${RowId}/MoldView`,
        });
      } else {
        history.push({
          pathname: "/database/Database",
          TabIdx: 1,
        });
      }
    });

    if (!FieldsExists) {
      ColumnData = {
        user: user.id,
        field_to_be_updated: "Mold",
        Material_Custom_Fields: [],
        Mold_Custom_Fields: column,
        Machine_Custom_Fields: [],
      };

      // console.log(ColumnData);

      DataService.SaveFieldsData(ColumnData).then((res) => {});
    } else {
      ColumnData = {
        user: user.id,
        field_to_be_updated: "Mold",
        Material_Custom_Fields: [],
        Mold_Custom_Fields: column,
        Machine_Custom_Fields: [],
      };

      // console.log(ColumnData);

      DataService.UpdateFieldsData(user.id, ColumnData).then((res) => {});
    }
  };

  const CheckForMoldId = () => {
    return new Promise((resolve, reject) => {
      let allFieldsFilled = true;

      // Check each field of each material object in the array
      if (!moldData["Mold_No"]) {
        allFieldsFilled = false;
      }

      if (allFieldsFilled) {
        resolve();
      } else {
        ToggleModalStates("MoldIdConfirm");
      }
    });
  };

  const CheckForDuplicateMoldId = () => {
    return new Promise((resolve, reject) => {
      let MoldIdExists;

      if (isNaN(moldData["Mold_No"])) {
        MoldIdExists = StoredMoldData.some(
          (mold) =>
            String(mold.Mold_Id).toLowerCase() ===
            String(moldData["Mold_No"]).toLowerCase()
        );
      } else {
        MoldIdExists = StoredMoldData.some(
          (mold) => mold.Mold_Id === moldData["Mold_No"]
        );
      }

      if (!MoldIdExists) {
        resolve();
      } else {
        ToggleModalStates("MoldIdUnique");
      }

      // console.log(MaterialIdExists)
    });
  };

  const SubmitData = () => {
    CheckForMoldId().then(() => {
      CheckForDuplicateMoldId().then(() => {
        SaveData();
      });
    });
  };

  const ToggleEditModal = () => {
    setEditModal(!EditModal);

    setHeader({
      header: "",
      key: null,
    });

    setEmptyAlert(false);

    setDuplicatetValue(false);
  };

  const addHeader = (e) => {
    e.preventDefault();

    setHeader({
      header: e.target.value,
      key: column.length > 0 ? column[column.length - 1].field.slice(5) : 0,
    });

    // console.log(e.target.value);

    setEmptyAlert(false);

    setDuplicatetValue(false);
  };

  const CheckForHeaderName = () => {
    return new Promise((resolve, reject) => {
      let HeaderExists;

      // console.log(column);

      if (column.length > 0) {
        HeaderExists = column.some(
          (headers) =>
            headers.headerText?.toLowerCase() === header.header.toLowerCase()
        );
      }

      resolve(HeaderExists);
    });
  };

  const addColumn = () => {
    if (!header.header) {
      setHeader({
        header: "",
        key: null,
      });

      setEmptyAlert(!EmptyAlert);
    } else {
      CheckForHeaderName().then((HeaderExists) => {
        if (!HeaderExists) {
          const newColumn = {
            id: nanoid(),
            field: `value${parseInt(header.key) + 1}`,
            headerText: header.header,
          };

          setColumn([...column, newColumn]);

          setHeader({
            header: "",
            key: null,
          });

          setEmptyAlert(false);

          setDuplicatetValue(false);

          // console.log(column);
        } else {
          setDuplicatetValue(!DuplicateValue);

          setHeader({
            header: "",
            key: null,
          });
        }
      });
    }
  };

  useEffect(() => {
    // console.log(header)
  }, [header]);

  const editColumn = (data, key) => {
    setIsColumnId({
      data: data,
      key: key,
    });
  };

  const DeleteColumnId = (HeaderData) => {
    setIsDeleteId(HeaderData);
  };

  const editColumnHeader = () => {
    if (header) {
      // console.log(header);

      setColumn(
        column.map((element) => {
          if (element.id === isColumnId.data.id) {
            return { ...element, headerText: header.header };
          }
          return element;
        })
      );

      setHeader({
        header: "",
        key: null,
      });

      setIsColumnId(null);
    } else {
    }
  };

  const deleteColumn = () => {
    setColumn((prevArray) => {
      const newArray = prevArray.filter((HeaderData) => {
        return HeaderData.id !== isDeleteId.id;
      });

      return newArray;
    });

    let TempArray = [moldData];

    const newArray = TempArray.some((obj) =>
      obj.hasOwnProperty(isDeleteId.headerText)
    );

    if (newArray) {
      delete moldData[isDeleteId.field];

      setIsDeleteId(null);

      // console.log(materialData);
    }

    ToggleModalStates("DeleteConfirm");
  };

  const GetData = (id) => {
    DataService.GetFieldsData(id).then((res) => {
      if (res.data.length > 0) {
        if (res.data[0].Mold_Custom_Fields) {
          // console.log(res.data[0].Material_Custom_Fields);

          setColumn(res.data[0].Mold_Custom_Fields);

          setFieldsExists(true);
        }
      }
    });
  };

  useEffect(() => {
    if (user) {
      GetData(user.id);

      let allUnits = [],
        selectedUnits = [];

      // Iterate through each category in the data
      for (const category in StoredUnits.Unit_Data[0]) {
        // Add each unit object to the allUnits array
        allUnits = allUnits.concat(StoredUnits.Unit_Data[0][category]);
      }

      for (const category in SelectedUnits.Unit_Data[0]) {
        // Add each unit object to the allUnits array
        selectedUnits = selectedUnits.concat(
          SelectedUnits.Unit_Data[0][category]
        );
      }

      setSelectedUnitsArray(selectedUnits);
      setUserSelectedUnits(SelectedUnits.Unit_Data[0]);

      setBaseUnits(allUnits);
      setBaseUnitsArray(StoredUnits.Unit_Data[0]);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    // console.log(BaseUnits)
    // console.log(SelectedUnitsArray)
  }, [BaseUnits]);

  useEffect(() => {
    setUnitSettings({
      ...UnitSettings,
      TimeUnit: {
        unit_id: BaseUnitsArray.Time ? BaseUnitsArray?.Time[0].unit_id : "",
        unit_name: BaseUnitsArray.Time ? BaseUnitsArray?.Time[0].unit_name : "",
        decimals: BaseUnitsArray.Time ? BaseUnitsArray.Time[0].decimals : "",
      },
      DistanceUnit: {
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
            ).unit_id
          : "",
        unit_name: UserSelectedUnits.Distance
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
            ).unit_name
          : "",
        decimals: UserSelectedUnits.Distance
          ? UserSelectedUnits.Distance.decimals
          : "",
      },
      WeightUnit: {
        unit_id: UserSelectedUnits.Weight
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
            ).unit_id
          : "",
        unit_name: UserSelectedUnits.Weight
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
            ).unit_name
          : "",
        decimals: UserSelectedUnits.Weight
          ? UserSelectedUnits.Weight.decimals
          : "",
      },
      RunnerWeightUnit: {
        unit_id: UserSelectedUnits.Weight
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
            ).unit_id
          : "",
        unit_name: UserSelectedUnits.Weight
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
            ).unit_name
          : "",
        decimals: UserSelectedUnits.Weight
          ? UserSelectedUnits.Weight.decimals
          : "",
      },
      AreaUnit: {
        unit_id: UserSelectedUnits.Area
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Area.unit_id
            ).unit_id
          : "",
        unit_name: UserSelectedUnits.Area
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Area.unit_id
            ).unit_name
          : "",
        decimals: UserSelectedUnits.Area ? UserSelectedUnits.Area.decimals : "",
      },
      RunnerAreaUnit: {
        unit_id: UserSelectedUnits.Area
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Area.unit_id
            ).unit_id
          : "",
        unit_name: UserSelectedUnits.Area
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Area.unit_id
            ).unit_name
          : "",
        decimals: UserSelectedUnits.Area ? UserSelectedUnits.Area.decimals : "",
      },
    });

    setmoldUnitData({
      ...moldUnitData,
      Cycle_Time: {
        value: "",
        unit_id: BaseUnitsArray.Time ? BaseUnitsArray?.Time[0].unit_id : "",
      },
      Hot_Runner_Volume: {
        value: "",
        unit_id: BaseUnitsArray.Volume ? BaseUnitsArray?.Volume[0].unit_id : "",
      },
      Mold_Stack_Height: {
        value: "",
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
            ).unit_id
          : "",
      },
      Mold_Vertical_Height: {
        value: "",
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
            ).unit_id
          : "",
      },
      Req_Mold_Open_Stroke: {
        value: "",
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
            ).unit_id
          : "",
      },
      Mold_Width: {
        value: "",
        unit_id: UserSelectedUnits.Distance
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
            ).unit_id
          : "",
      },
      Weight_of_one_Part: {
        value: "",
        unit_id: UserSelectedUnits.Weight
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
            ).unit_id
          : "",
      },
      Runner_Weight: {
        value: "",
        unit_id: UserSelectedUnits.Weight
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
            ).unit_id
          : "",
      },
      Part_Projected_Area: {
        value: "",
        unit_id: UserSelectedUnits.Area
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Area.unit_id
            ).unit_id
          : "",
      },
      Runner_Projected_Area: {
        value: "",
        unit_id: UserSelectedUnits.Area
          ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Area.unit_id
            ).unit_id
          : "",
      },
    });

    // eslint-disable-next-line
  }, [UserSelectedUnits, BaseUnitsArray]);

  return (
    <>
      <Modal
        isOpen={ModalStates.MoldIdConfirm.visibility}
        centered={true}
        style={{ width: "500" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.MoldIdConfirm.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("MoldIdConfirm")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ModalStates.DeleteConfirm.visibility}
        centered={true}
        style={{ width: "400px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.DeleteConfirm.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteColumn}>
            {" "}
            Delete{" "}
          </Button>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("DeleteConfirm")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ModalStates.MoldIdUnique.visibility}
        centered={true}
        style={{ width: "600px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.MoldIdUnique.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("MoldIdUnique")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ModalStates.ConvertConfirm.visibility}
        centered={true}
        style={{ width: "600px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.ConvertConfirm.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={UpdateInputConversions}>
            {" "}
            Yes{" "}
          </Button>
          <Button color="primary" onClick={UpdateUnitsAndClose}>
            {" "}
            No{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ModalStates.DeletePartConfirm.visibility}
        centered={true}
        style={{ width: "200px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.DeletePartConfirm.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={setPart}>
            {" "}
            Yes{" "}
          </Button>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("DeletePartConfirm")}
          >
            {" "}
            No{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Header2 Title="Database" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <BreadCrumb DB_Name={"Mold"} Current_Page={"New"} TabIdx={1} />
        </div>

        <div className="container-fluid">
          <div className="card p-3  ml-2">
            <div className="d-flex col-md-12">
              <div className="pt-2 pb-2">
                <button
                  className="btn btn-secondary btn-air-secondary mr-2"
                  onClick={SubmitData}
                >
                  Save
                </button>
              </div>

              <div className="pt-2 pb-2 text-left">
                <button
                  className="btn btn-warning btn-air-warning mr-2"
                  onClick={() => {
                    history.push({
                      pathname: "/database/Database",
                      TabIdx: 1,
                    });
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="pt-2 pb-2 text-left ml-3">
                <div className="form-group m-t-8 m-checkbox-inline mb-0 custom-check-ml pt-2">
                  <input
                    type="checkbox"
                    id="checkbox-primary-1"
                    className="checkbox checkbox-primary"
                    value="option1"
                    onClick={() => setSamePage(!SamePage)}
                  />
                  <label
                    className="mb-0 mt-1 ml-1"
                    htmlFor="checkbox-primary-1"
                  >
                    <span className="digits"> {"Stay on same page"}</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="d-flex col-md-12 mt-3">
              <div className="col-md-6">
                <div className="form-inline">
                  <LeftInputSection
                    SelectedMoldUnitData={moldUnitData}
                    MaterialData={MaterialData}
                    BaseUnitsArray={BaseUnitsArray}
                    handleChange={handleChange}
                    ToggleImgModal={ToggleImgModal}
                  />
                </div>
              </div>

              <div className="ml-1 col-md-6">
                <div className="form-inline">
                  <RightInputSection
                    SelectedMoldUnitData={moldUnitData}
                    BaseUnits={BaseUnits}
                    BaseUnitsArray={BaseUnitsArray}
                    handleChange={handleChange}
                    handleDropDownChange={handleDropDownChange}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex col-md-12 mt-3">
              <div
                className="ml-2 col-md-8 b-l-6 b-r-6"
                style={{ background: "#ebebeb" }}
              >
                <div className="form-inline">
                  <Table
                    className="table-bordernone table-responsive"
                    style={{ background: "#ebebeb" }}
                    cellPadding="0"
                    cellSpacing="0"
                    color="none"
                    width={100}
                    height={150}
                  >
                    <tr>
                      <td align="left" style={{ background: "#ebebeb" }}>
                        Is This a Family Mold :
                      </td>
                      <td align="left" style={{ background: "#ebebeb" }}>
                        <select
                          name="Is_This_A_New_Mold"
                          data-fieldname={"Is_This_A_New_Mold"}
                          className="form-control b-b-primary"
                          width="70"
                          value={moldData.Is_This_A_New_Mold}
                          onChange={handleChange}
                        >
                          <option> No </option>
                          <option> Yes </option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style={{ background: "#ebebeb" }}>
                        Number of Parts :
                      </td>
                      <td align="left" style={{ background: "#ebebeb" }}>
                        <input
                          type="text"
                          className="form-control b-b-primary"
                          name="Number_Of_Parts"
                          value={moldData.Number_Of_Parts}
                          onChange={handleChange}
                          style={{ width: 70 }}
                          onKeyPress={(event) => {
                            if (!/[1-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={1}
                          {...(moldData.Is_This_A_New_Mold === "No"
                            ? { readOnly: true }
                            : {})}
                        />
                        <button
                          type="text"
                          className="form-control b-b-primary ml-1 text-center"
                          style={{ width: 40 }}
                          onClick={setPart}
                          ref={SetButtonRef}
                        >
                          set
                        </button>
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td align="left" style={{ background: "#ebebeb" }}>
                        Parts
                        <br />
                        <span className="text-danger">
                          *Fields can not be edited once saved
                        </span>
                      </td>
                    </tr>
                  </Table>

                  <Table className="table-responsive table-bordered">
                    <tr>
                      {partColumn.map((value, key1) => (
                        <React.Fragment key={key1}>
                          <th key={value.id}>
                            {key1 === 0 ? (
                              <span> {value.Part} </span>
                            ) : (
                              <span> {value.Part_No} </span>
                            )}
                          </th>
                        </React.Fragment>
                      ))}
                    </tr>

                    {PartData.map((value, key1) => (
                      <tr key={key1}>
                        {partColumn.map((value2, key2) => (
                          <React.Fragment key={value2.id}>
                            {value2.edit === false ? (
                              <td style={{ backgroundColor: "#fff" }}>
                                {key1 === 4 ? (
                                  <select
                                    name="WeightUnit"
                                    data-fieldname={"Weight_of_one_Part"}
                                    data-category={"Weight"}
                                    className="form-control w-100"
                                    onChange={handleDropDownChange}
                                    value={UnitSettings.WeightUnit.unit_id}
                                  >
                                    {BaseUnitsArray.Weight ? (
                                      BaseUnitsArray.Weight.map(
                                        (value, key) => (
                                          <option
                                            value={value.unit_id}
                                            key={key}
                                          >
                                            {" "}
                                            Weight of one Part (
                                            {value.unit_name}){" "}
                                          </option>
                                        )
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                ) : key1 === 6 ? (
                                  <select
                                    name="RunnerWeightUnit"
                                    data-fieldname={"Runner_Weight"}
                                    data-category={"RunnerWeight"}
                                    className="form-control w-100"
                                    onChange={handleDropDownChange}
                                    value={
                                      UnitSettings.RunnerWeightUnit.unit_id
                                    }
                                  >
                                    {BaseUnitsArray.Weight ? (
                                      BaseUnitsArray.Weight.map(
                                        (value, key) => (
                                          <option
                                            value={value.unit_id}
                                            key={key}
                                          >
                                            {" "}
                                            Runner Weight ({
                                              value.unit_name
                                            }){" "}
                                          </option>
                                        )
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                ) : key1 === 7 ? (
                                  <select
                                    name="AreaUnit"
                                    data-fieldname={"Part_Projected_Area"}
                                    data-category={"Area"}
                                    className="form-control w-100"
                                    onChange={handleDropDownChange}
                                    value={UnitSettings.AreaUnit.unit_id}
                                  >
                                    {BaseUnitsArray.Area ? (
                                      BaseUnitsArray.Area.map((value, key) => (
                                        <option value={value.unit_id} key={key}>
                                          {" "}
                                          Part Projected Area ({value.unit_name}
                                          ){" "}
                                        </option>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                ) : key1 === 8 ? (
                                  <select
                                    name="RunnerAreaUnit"
                                    data-fieldname={"Runner_Projected_Area"}
                                    data-category={"RunnerArea"}
                                    className="form-control w-100"
                                    onChange={handleDropDownChange}
                                    value={UnitSettings.RunnerAreaUnit.unit_id}
                                  >
                                    {BaseUnitsArray.Area ? (
                                      BaseUnitsArray.Area.map((value, key) => (
                                        <option value={value.unit_id} key={key}>
                                          {" "}
                                          Runner Projected Area (
                                          {value.unit_name}){" "}
                                        </option>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                ) : (
                                  <>{value.Cavity_Data}</>
                                )}
                              </td>
                            ) : (
                              <React.Fragment key={value2.id}>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    data-index={key1}
                                    onKeyPress={(event) => {
                                      if (key1 === 2 || key1 === 5) {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      } else if (key1 === 3) {
                                        // Add any functionality here for key1 === 3 if needed
                                        if (!/^[a-zA-Z0-9]$/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                        if (/^[a-zA-Z]$/.test(event.key)) {
                                          setMaxLength(1);
                                        }
                                        if (
                                          /[0-9]/.test(event.key) &&
                                          PartData[key1][`Part${key2}`] === ""
                                        ) {
                                          setMaxLength(8);
                                        }
                                      } else if (
                                        key1 === 4 ||
                                        key1 === 6 ||
                                        key1 === 7 ||
                                        key1 === 8
                                      ) {
                                        if (!/[0.0-9.0]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }
                                    }}
                                    maxLength={
                                      key1 === 0 || key1 === 1 || key1 === 2
                                        ? null
                                        : maxLength
                                    }
                                    name={`Part${key2}`}
                                    onChange={handlePartData}
                                    value={PartData[key1][`Part${key2}`] || ""}
                                    style={{ width: 100 }}
                                  />
                                </td>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ))}
                      </tr>
                    ))}
                  </Table>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ml-3 col-md-6">
                  <div>
                    <button
                      className="btn btn-secondary btn-air-secondary mr-2"
                      onClick={ToggleEditModal}
                    >
                      Edit
                    </button>
                  </div>

                  <div className="row m-2">
                    <label> Custom Fields </label>
                  </div>

                  <div style={{ border: "1px solid black", width: "255px" }}>
                    <Table
                      id="Custom_Fields_Table"
                      className="table-responsive"
                      width={300}
                      height={200}
                      cellPadding={0}
                      cellSpacing={0}
                    >
                      <thead>
                        <tr>
                          <th align="center" style={{ width: "160px" }}>
                            Name
                          </th>

                          <th align="center" style={{ width: "160px" }}>
                            Value
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {column.map((HeaderName, HeaderKey) => (
                          <tr key={HeaderKey}>
                            <td align="left" style={{ background: "#fff" }}>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: "125px" }}
                                value={HeaderName.headerText}
                                readOnly
                              />{" "}
                            </td>

                            <td align="left" style={{ background: "#fff" }}>
                              <input
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                name={`value${HeaderKey + 1}`}
                                style={{ width: "125px" }}
                                autoComplete="off"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal isOpen={ImgModal} centered={true} style={{ width: "500" }}>
          <ModalHeader> Mold Diagram </ModalHeader>
          <ModalBody>
            <div className="ml-4">
              <img src={moldDiagram} alt="Mold Diagram" />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={ToggleImgModal}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <div>
          <Modal isOpen={EditModal} centered={true} style={{ width: "500" }}>
            <ModalHeader> Add Custom Fields </ModalHeader>
            <ModalBody>
              {EmptyAlert ? (
                <span style={{ color: "red" }}> *Please enter field name </span>
              ) : (
                ""
              )}
              {DuplicateValue ? (
                <span style={{ color: "red" }}>
                  {" "}
                  *Field name already exists{" "}
                </span>
              ) : (
                ""
              )}
              <div className="mb-1">
                <label>Field Name</label>
                <input
                  type="text"
                  className="form-control b-b-primary"
                  style={{ width: "150px" }}
                  onChange={addHeader}
                  value={header.header}
                />
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={addColumn}
                  className="mt-2 mb-4"
                >
                  Add To List
                </Button>
              </div>

              <div>
                <label>Available Categories</label>

                <div style={{ border: "1px solid black", width: "160px" }}>
                  <Table
                    id="Custom_Fields_Table"
                    className="table-responsive"
                    width={300}
                    height={200}
                    cellPadding={0}
                    cellSpacing={0}
                  >
                    <thead>
                      <tr>
                        <th
                          colSpan={2}
                          align="center"
                          style={{ width: "160px" }}
                        >
                          Name
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {column.map((HeaderName, HeaderKey) => (
                        <tr key={HeaderKey} onBlur={editColumnHeader}>
                          <td align="left" style={{ background: "#fff" }}>
                            <input
                              type="text"
                              className="form-control b-b-primary"
                              style={{ width: "155px" }}
                              onChange={addHeader}
                              value={HeaderName.headerText}
                              onFocus={() => editColumn(HeaderName.id)}
                              onClick={() => DeleteColumnId(HeaderName)}
                              readOnly
                            />{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button color="warning" onClick={toggleEditHeader}>
                {" "}
                Edit{" "}
              </Button>
              <Button
                color="warning"
                onClick={() => ToggleModalStates("DeleteConfirm")}
              >
                {" "}
                Delete{" "}
              </Button>
              <Button color="primary" onClick={ToggleEditModal}>
                {" "}
                Close{" "}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={modal} centered={true} style={{ width: "350px" }}>
            <ModalHeader toggle={toggleEditHeader}>
              {"Edit Custom Fields"}
            </ModalHeader>

            <ModalBody>
              {column.map((value, key) => (
                <div className="row" key={key}>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-2">
                        <label className="lbl_style">{key + 1}:</label>
                      </div>
                      <div className="col-md-8 mt-1" onBlur={editColumnHeader}>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter new header"
                          defaultValue={value.headerText}
                          onChange={addHeader}
                          autoComplete="off"
                          onFocus={() => editColumn(value, key)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </ModalBody>

            <ModalFooter>
              <Button color="fourth" onClick={toggleEditHeader}>
                {" "}
                Update & Close{" "}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MoldNew);
