import React, { useEffect } from "react";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  getCell,
} from "@syncfusion/ej2-react-spreadsheet";
import { getRangeIndexes } from "@syncfusion/ej2-spreadsheet";
import "../../../assets/custom-stylesheet/grid_stylecss.css";

const PostMoldGrid = ({
  rowCount,
  colCount,
  PostMoldGridData,
  setPostMoldGridData,
  PostMoldSpreadsheet,
  setRowToBeDeleted,
  PopulateMoldSheet,
  setColToBeDeleted,
  setArgs,
  column,
  setGrid2,
  setGridHeader,
}) => {
  const protectSettings = { selectCells: true };

  const scrollSettings = {
    isFinite: true,
    enableVirtualization: false,
  };

  let isPaste = false;

  const dialogBeforeOpen = (args) => {
    if (args.dialogName === "EditAlertDialog") {
      args.cancel = true;
      // args.content = 'This cell is read only';
    }

    // Edit the dialog content using the dialogBeforeOpen event.
    if (args.dialogName === "ValidationErrorDialog") {
      args.cancel = true;
    }
  };

  const getIndex = () => {
    return new Promise((resolve, reject) => {
      // Getting the index of the selected row and storing in a variable
      let cell = PostMoldSpreadsheet.current.getActiveSheet().activeCell;

      let cellIdx = getRangeIndexes(cell);

      setRowToBeDeleted(cellIdx[0]);

      if (column.length <= 2 || cellIdx[1] === 0) {
        setColToBeDeleted(null);
      } else {
        setColToBeDeleted(cellIdx[1]);
      }

    //   if (cellIdx[1] !== 0) {
    //     setGrid2(`value${cellIdx[1]}`);

    //     // setGridHeader(column[`${cellIdx[1]}`].header);
    //   } else {
    //     setGrid2(null);
    //   }

      // Getting the data from the PostMoldSpreadsheet cells
      let dataArray = [];
      let dataObj = {};

      for (let i = 0; i < rowCount; i++) {
        for (let j = 0; j < column.length; j++) {
          dataObj[`value${j}`] =
            PostMoldSpreadsheet.current.getDisplayText(
              getCell(i, j, PostMoldSpreadsheet.current.getActiveSheet())
            ) || "";
        }

        dataArray.push(dataObj);

        dataObj = {};
      }

      setPostMoldGridData(dataArray);

      resolve(dataArray);

      // console.log(dataArray)
    });
  };

  const ConvertToMin = (data) => {
    return data.map((obj, index) => {
      if (index >= 2) {
        // Process only from the 3rd object onwards
        const hours = parseInt(obj.value0) || 0;
        const mins = parseInt(obj.value1) || 0;
        obj.minutes = hours * 60 + mins;
      }
      return obj; // Return the updated object
    });
  };

  const GetData = () => {
    getIndex().then((SheetData) => {
      ConvertToMin(SheetData);

      setPostMoldGridData(SheetData);

      //   console.log(SheetData);
    });

    // console.log(PostMoldGridData)
  };

  const onCreated = () => {
    //Applies data validation to specified range in the active sheet.
    PostMoldSpreadsheet.current.addDataValidation(
      {
        type: "Decimal",
        operator: "Between",
        value1: "-1000.0",
        value2: "1000.0",
      },
      `A1:Z${PostMoldSpreadsheet.current.getActiveSheet().rowCount}`
    );

    PostMoldSpreadsheet.current.lockCells(`A3:Z100`, false);

    PostMoldSpreadsheet.current.setColumnsWidth(100, ["A:B"]);
    PostMoldSpreadsheet.current.cellFormat(
      { backgroundColor: "#21325E", color: "#fff" },
      "A1:Z1"
    );
    PostMoldSpreadsheet.current.updateCell({ value: "Hours" }, "A2");
    PostMoldSpreadsheet.current.updateCell({ value: "Mins" }, "B2");
    PostMoldSpreadsheet.current.cellFormat({ textAlign: "center" }, "A1:Z2");
    PostMoldSpreadsheet.current.merge("A1:B1");
    PostMoldSpreadsheet.current.merge("C2:Z2");
    PostMoldSpreadsheet.current.merge("C1:Z2", "Vertically");

    const alphabetArray = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    for (let i = 0; i < column.length; i++) {
      PostMoldSpreadsheet.current.updateCell(
        { value: column[i].header },
        `${alphabetArray[i]}1`
      );
    }

    PopulateMoldSheet(PostMoldGridData)

    // console.log(PostMoldGridData)
  };

  function UnlockCells() {
    PostMoldSpreadsheet.current.lockCells(`A3:Z100`, false);

    PostMoldSpreadsheet.current.setColumnsWidth(80, ["C:Z"]);
  }

  const cellEditing = (args) => {
    if (args.value !== args.oldValue && !isNaN(args.value)) {
      PostMoldSpreadsheet.current.updateCell(
        { value: args.value },
        args.address
      );

      GetData();
    }
  };

  function cellEdit(args) {
    let editorElement =
      PostMoldSpreadsheet.current.element.getElementsByClassName(
        "e-spreadsheet-edit"
      )[0];

    editorElement.innerText = args.displayText;

    // For set the cursor position after updating the display text
    if (editorElement.childNodes.length) {
      const selection = window.getSelection();

      const range = document.createRange();

      range.setStart(editorElement.childNodes[0], args.displayText.length);

      range.collapse(true);

      selection.removeAllRanges();

      selection.addRange(range);
    }
  }

  function beforeCellUpdate(args) {
    // console.log('3rd')

    // PopulateMoldSheet(PostMoldGridData)

    // Skip the cell update for paste action that contains characters, If you need you can skip this for all type of actions performed in the spreadsheet.
    if (isPaste) {
      let pastedValue = args.cell.value;

      // Match alphabets and special characters
      var regex = /[a-zA-Z]/g;

      if (pastedValue && pastedValue.toString().match(regex)) {
        args.cancel = true;
      }

      isPaste = false;
    }
  }

  function actionBegin(args) {
    if (args.args.eventArgs && args.args.eventArgs.requestType === "paste") {
      isPaste = true;
    }
  }

  const beforeCellRenderHandler = (args) => {
    // PopulateMoldSheet(PostMoldGridData)

    setArgs(args);
  };

  useEffect(() => {
    // loadData().then(() => {
    //     PopulateMoldSheet(PostMoldGridData)
    // })
    // eslint-disable-next-line
  }, [PostMoldGridData]);

  return (
    <div>
      <div
        className="Post_Mold spreadsheet"
        id="Post_Mold_Sheet"
        onMouseEnter={UnlockCells}
      >
        <SpreadsheetComponent
          ref={PostMoldSpreadsheet}
          onBlur={GetData}
          cellEdited={GetData}
          cellEditing={cellEditing}
          cellEdit={cellEdit.bind(this)}
          height={350}
          width={650}
          showFormulaBar={false}
          showSheetTabs={false}
          showRibbon={false}
          scrollSettings={scrollSettings}
          beforeCellRender={beforeCellRenderHandler}
          created={onCreated}
          dialogBeforeOpen={dialogBeforeOpen.bind(this)}
          beforeCellUpdate={beforeCellUpdate.bind(this)}
          actionBegin={actionBegin.bind(this)}
          allowAutoFill={false}
          enableContextMenu={false}
        >
          <SheetsDirective>
            <SheetDirective
              rowCount={rowCount}
              colCount={colCount}
              isProtected={true}
              showHeaders={false}
              protectSettings={protectSettings}
            ></SheetDirective>
          </SheetsDirective>
        </SpreadsheetComponent>
      </div>
    </div>
  );
};

export default PostMoldGrid;
