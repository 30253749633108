import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import { createSpinner, showSpinner } from "@syncfusion/ej2-popups";
import { useHistory, useParams } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header2 from "../common/header-component/header2";
import DataService from "../../services/ApiService";
import { connect } from "react-redux";
import Loader from "../../assets/Loader";

const EquipmentSessions = ({ user }) => {
  const history = useHistory();

  // Getting session Id from URL
  var { moldId, moldName } = useParams();

  const BackToDashboard = () => {
    history.push("/dashboard");
  };

  const [selectedMold, setselectedMold] = useState("");

  const [SessionNameModal, setSessionNameModal] = useState(false);

  const ToggleSessionNameModal = () => {
    setSessionNameModal(!SessionNameModal);
  };

  const today = new Date().toISOString().split("T")[0];

  // Redirect's to Melt Preparation of that respective session
  const handleSession = (session) => {
    // Using the "btoa" method to encrypt the URL so that the exact ID should not be visible
    var Session_Id = btoa(session.id);
    var Session_Name = btoa(session.Session_Name);
    var mold_Id = btoa(session.mold);
    var mold_Name = btoa(selectedMold);

    createSpinner({
      // Specify the target for the spinner to show
      target: document.getElementById("Container"),
    });

    showSpinner(document.getElementById("Container"));

    setTimeout(() => {
      // history.push(`/${Session_Id}/meltpreparation`)

      // Now the final Once all the Data fetching and storing in the respective Array's is done we navigate to the reports with the Data Array using the "useHistory" hook.
      history.push({
        pathname: `/${Session_Id}/${Session_Name}/${mold_Id}/${mold_Name}/equipmentqualification`,
        session: session.id,
      });
    }, 100);
  };

  // To Store the Mold's
  const [MoldData, setMoldData] = useState([]);
  const [mold_Id, setmold_Id] = useState();

  const [isLoading, setIsLoading] = useState(true);

  // To toggle Create Session modal
  const [SelectMoldModal, setSelectMoldModal] = useState(false);

  const ToggleSelectMoldModal = () => {
    setSelectMoldModal(!SelectMoldModal);
  };

  // To toggle Create Session modal
  const [SessionModal, setSessionModal] = useState();

  const ToggleSessionModal = () => {
    setSessionModal(!SessionModal);
  };

  // These are the state's which store the Session's created by the user.
  const [SessionData, setSessionData] = useState([]);

  // An Local Object to store the Session Data which is stored in the Above Session Array.
  const [addSessionData, setAddSessionData] = useState({
    Session_Name: "",
    Date: "",
  });

  const CheckForSessionName = (newSession) => {
    return new Promise((resolve, reject) => {
      let SessionNameExists;

      SessionNameExists = SessionData.some(
        (session) =>
          session.Session_Name.toLowerCase() ===
          newSession.Session_Name.toLowerCase()
      );

      resolve(SessionNameExists);
    });
  };

  // This Event store's the Local Session Object in the main Session Data array.
  const handleAddFormSubmit2 = (event) => {
    event.preventDefault();

    const newSession = {
      mold: mold_Id,
      Session_Name: addSessionData.Session_Name,
      Date: addSessionData.Date,
    };

    // console.log(newSession)

    CheckForSessionName(newSession).then((sessions) => {
      // console.log(sessions)

      if (!sessions) {
        // // This method is exported from services folder which has different method's of saving and fetching data.
        DataService.SaveEquipmentSession(newSession)
          .then((res) => {
            if (res.data.message) {
            } else {
              var Session_Id = btoa(res.data.id);
              var Session_Name = btoa(res.data.Session_Name);
              var mold_Id = btoa(res.data.mold);
              var mold_Name = btoa(selectedMold);

              setTimeout(() => {
                // Now the final Once all the Data fetching and storing in the respective Array's is done we navigate to the reports with the Data Array using the "useHistory" hook.
                history.push({
                  pathname: `/${Session_Id}/${Session_Name}/${mold_Id}/${mold_Name}/equipmentqualification`,
                  session: Session_Id,
                });
              }, 100);

              setSessionData([...SessionData, res.data]);
              setAddSessionData([]);
            }
          })
          .catch((err) => { });
      } else {
        ToggleSessionNameModal();
        setAddSessionData([]);
      }
    });
  };

  // The event to store the Session Data into the local Object
  const handleAddFormChange2 = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addSessionData };
    newFormData[fieldName] = fieldValue;

    setAddSessionData(newFormData);
  };

  let defaultDate, date;

  let NewDate = new Date();
  let TodaysDate = NewDate.toISOString().split("T")[0];

  const [error, setError] = useState(false);

  const toggle = () => {
    if (mold_Id && mold_Id !== 1) {
      ToggleSessionModal();
      setError(false);

      // console.log(mold_Id)
    } else {
      ToggleSelectMoldModal();
    }
  };

  const handleSubmit = (e) => {
    if (!addSessionData.Session_Name) {
      setError(true);
    } else if (!addSessionData.Date) {
      date = new Date();
      defaultDate = date.toDateString();
      addSessionData.Date = defaultDate;
      ToggleSessionModal();
      handleAddFormSubmit2(e);
    } else {
      ToggleSessionModal();
      handleAddFormSubmit2(e);
      addSessionData.Session_Name = "";
    }
  };

  const setDate = (e) => {
    if (e.target.value) {
      date = new Date(e.target.value);
      defaultDate = date.toDateString();
      addSessionData.Date = defaultDate;
    }
  };

  const onClose = () => {
    addSessionData.Session_Name = "";
    ToggleSessionModal();
    setError(false);
  };

  // Event to get the created mold's
  const handleGetMold = (id) => {
    DataService.FetchMold(id)
      .then((res) => {
        if (res.data.message) {
          setMoldData([]);
          setIsLoading(false);
        } else {
          setMoldData(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => { });
  };

  const handleGetSessions = (mold_Id) => {
    if (mold_Id && mold_Id != 0) {
      setIsLoading(true);

      setTimeout(() => {
        DataService.FetchEquipmentSession(mold_Id)
          .then((res) => {
            if (res.data.message) {
              setSessionData([]);
              setIsLoading(false);
            } else {
              setSessionData(res.data);
              setIsLoading(false);
            }
          })
          .catch((err) => { });
      }, 500);
    } else {
      setSessionData([]);
      setmold_Id(0);
    }
  };

  useEffect(() => {
    // First Checking for the user
    if (user) {
      // This event has a GET api in it
      handleGetMold(user.id);
    } else {
      setSessionData([]);
    }

    if (moldId && moldName) {
      handleGetSessions(atob(moldId));

      setmold_Id(atob(moldId));

      setselectedMold(atob(moldName));

      var selectElement = document.getElementById("MoldDropDown");

      for (var i = 0; i < selectElement.options.length; i++) {
        if (selectElement.options[i].text === atob(moldName)) {
          selectElement.selectedIndex = i;

          // console.log(selectElement.options[i].text)

          break;
        }
      }

      // console.log(atob(moldName))
    } else {
    }
  }, [user, moldId, moldName]);

  const setMold = (e) => {
    if (e.target.value != 0) {
      var Id = e.target.value;

      setmold_Id(Id);

      let selectedMold = MoldData.filter((mold) => {
        return mold.id === parseInt(Id);
      });

      setselectedMold(selectedMold[0].Mold_Id);
    } else {
      setSessionData([]);
      setmold_Id(0);
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}

      <Header2 Title="Current Sessions" />

      <Modal isOpen={SelectMoldModal} centered={true}>
        <ModalHeader toggle={ToggleSelectMoldModal}>
          {" "}
          Select a mold.{" "}
        </ModalHeader>
        <ModalBody>
          <span> Please select a mold before creating a session. </span>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={ToggleSelectMoldModal}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={SessionNameModal} centered={true}>
        <ModalHeader toggle={ToggleSessionNameModal}> Nautilus </ModalHeader>
        <ModalBody>
          <span> Session with this name already exists. </span>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={ToggleSessionNameModal}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex">
            <div>
              <span
                className="BreadCrum"
                onClick={BackToDashboard}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Dashboard{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "14px" }}>
                {" "}
                Equipment Qualification Sessions{" "}
              </span>
            </div>
          </div>

        </div>
      </div>
      <div id="Container" className="container-fluid">

        <div className="card ml-4 mr-4 mt-2 mb-4">

          <div className="row d-flex justify-content-evenly">
            <div className="col-md-2 m-4">
              <div className="flex-row">
                <div>
                  <button
                    className="btn btn-primary btn-air-primary mt-2"
                    type="button"
                    onClick={toggle}
                  >
                    {" "}
                    Create session{" "}
                  </button>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between m-3 col-md-6">

              <div className="col-md-10"></div>

              <div className="col-md-4 ml-4">
                <label> Select Mold: </label>

                <select
                  className="form-control"
                  id="MoldDropDown"
                  onChange={setMold}
                  onClick={() => handleGetSessions(mold_Id)}
                >
                  <option value={0}> Select a mold </option>

                  {MoldData.map((mold, key) => (
                    <option key={key} value={mold.id}>
                      {" "}
                      {mold.Mold_Id}{" "}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Grid to display the session's of the selected Mold */}
          <div className="m-2">
          <form autoComplete="off">
            <div className="viscosity_table">
              <Table striped bordered hover responsive variant="light">
                <thead>
                  <tr>
                    <th className="Pressure_Heading">
                      <span> Session Name </span>
                    </th>
                    <th className="Pressure_Heading">
                      <span> Date </span>
                    </th>
                  </tr>
                </thead>

                <tbody className="grid_style">
                  {SessionData.map((session, sessionId) => (
                    <tr key={SessionData[sessionId].id}>
                      <td>
                        {" "}
                        <input
                          type="text"
                          className="form-control session-link"
                          title="Go to Equipment Qualification"
                          name="Session_Name"
                          value={session.Session_Name}
                          onClick={() => handleSession(session)}
                          readOnly
                        />{" "}
                      </td>

                      <td>
                        {" "}
                        <input
                          type="text"
                          className="form-control"
                          name="Date"
                          value={session.Date}
                          readOnly
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </form>
          </div>        

        
            <form>
              <Modal
                isOpen={SessionModal}
                centered={true}
                toggle={ToggleSessionModal}
              >
                <ModalHeader toggle={ToggleSessionModal}>
                  {" "}
                  Add Session{" "}
                </ModalHeader>
                <ModalBody>
                  <div>
                    {" "}
                    {error ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        *Please enter session name{" "}
                      </span>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="lbl_style">Session Name :</label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        name="Session_Name"
                        type="text"
                        onKeyPress={(event) => {
                          if (/[^a-zA-Z0-9\s]/g.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={handleAddFormChange2}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="lbl_style">Date :</label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="input-group">
                        <input
                          className="form-control"
                          id="dateRequired"
                          type="date"
                          name="Date"
                          defaultValue={TodaysDate}
                          onChange={setDate}
                          max={today}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" type="submit" onClick={handleSubmit}>
                    {" "}
                    Create{" "}
                  </Button>
                  <Button color="fourth" onClick={onClose}>
                    {" "}
                    Cancel{" "}
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          

        </div>

      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(EquipmentSessions);
