import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

const ProcessEnglishunit = ({ handleChange, result, processInput }) => {
  return (
    <div>
      <Form>
        <Table className="thistable" striped bordered hover variant="light">
          <thead>
            <tr>
              <td>Parameters</td>
              <td>Value of Current Machine</td>
              <td>Values for Machine under Consideration (English Units)</td>
              <td>Values for Machine under Consideration (Metric Units 1)</td>
              <td>Values for Machine under Consideration (Metric Units 2)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Intensification Ratio (IR)</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="intensificationRatio"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.intensificationRatio}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="intensificationRatioTarget"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.intensificationRatioTarget}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>NA</td>
              <td>NA</td>
            </tr>
            <tr>
              <td>Screw Diameter in mm</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="screwDiameter"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.screwDiameter}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="screwDiameterTarget"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.screwDiameterTarget}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>NA</td>
              <td>NA</td>
            </tr>
            <tr>
              <td>Shot Size in inches</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="shotSize"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.shotSize}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>{result.shotSize}</td>
              <td>{(result.shotSize * 25.4).toFixed(2)} (mm)</td>
              <td>{(result.shotSize * 25.4).toFixed(2)} (mm)</td>
            </tr>
            <tr>
              <td>Transfer position</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="transferPosition"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.transferPosition}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>NA</td>
              <td>NA</td>
              <td>NA</td>
            </tr>
            <tr>
              <td>Shot Size Transfer</td>
              <td>{result.shotSizeTransfer}</td>
              <td>{result.shotSizeTransferCalcEng}</td>
              <td>NA</td>
              <td>NA</td>
            </tr>
            <tr>
              <td>Injection Pressure 1 (psi)</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="injectionPressure1"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.injectionPressure1}
                    onChange={handleChange}
                    size="sm"
                  />
                </Form.Group>
              </td>
              <td>{result.injectionPressure1}</td>
              <td>{(result.injectionPressure1 * 0.069).toFixed(2)} (bar)</td>
              <td>{(result.injectionPressure1 * 0.0069).toFixed(2)} (MPa)</td>
            </tr>
            <tr>
              <td>Injection Pressure 2 (psi)</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="injectionPressure2"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.injectionPressure2}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>{result.injectionPressure2}</td>
              <td>{(result.injectionPressure2 * 0.069).toFixed(2)} (bar)</td>
              <td>{(result.injectionPressure2 * 0.0069).toFixed(2)} (MPa)</td>
            </tr>
            <tr>
              <td>Injection Pressure 3 (psi)</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="injectionPressure3"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.injectionPressure3}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>{result.injectionPressure3}</td>
              <td>{(result.injectionPressure3 * 0.069).toFixed(2)} (bar)</td>
              <td>{(result.injectionPressure3 * 0.0069).toFixed(2)} (MPa)</td>
            </tr>
            <tr>
              <td>Fill Time (Sec) :</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="fillTime"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.fillTime}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>NA</td>
              <td>NA</td>
              <td>NA</td>
            </tr>
            <tr>
              <td>Calc Inj Speed On Mach 1 :</td>
              <td>{result.caloInjSpeed}</td>
              <td>{result.calcInjSpeedENG}</td>
              <td>NA</td>
              <td>NA</td>
            </tr>
            <tr>
              <td>Holding Pressure 1 (psi)</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="holdingPressure1"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.holdingPressure1}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>{result.holdingPressure1}</td>
              <td>{(result.holdingPressure1 * 0.069).toFixed(2)} (bar)</td>
              <td>{(result.holdingPressure1 * 0.0069).toFixed(2)} (MPa)</td>
            </tr>
            <tr>
              <td>Holding Pressure 2 (psi)</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="holdingPressure2"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.holdingPressure2}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>{result.holdingPressure2}</td>
              <td>{(result.holdingPressure2 * 0.069).toFixed(2)} (bar)</td>
              <td>{(result.holdingPressure2 * 0.0069).toFixed(2)} (MPa)</td>
            </tr>
            <tr>
              <td>Holding Pressure 3 (psi)</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="holdingPressure3"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.holdingPressure3}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>{result.holdingPressure3}</td>
              <td>{(result.holdingPressure3 * 0.069).toFixed(2)} (bar)</td>
              <td>{(result.holdingPressure3 * 0.0069).toFixed(2)} (MPa)</td>
            </tr>
            <tr>
              <td>Back Pressure 1 (psi)</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="backPressure"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.backPressure}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>{result.BackPressure1}</td>
              <td>{result.BackPressure2} (bar)</td>
              <td>{result.BackPressure3} (MPa)</td>
            </tr>
            <tr>
              <td>Screw rpm</td>
              <td>
                <Form.Group className="thisform" controlId="formBasicnumber">
                  <Form.Control
                    name="screwRPM"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    defaultValue={processInput.screwRPM}
                    onChange={handleChange}
                    size="sm"
                    min="0"
                  />
                </Form.Group>
              </td>
              <td>{result.screwRPM}</td>
              <td>{result.screwRPM}</td>
              <td>{result.screwRPM}</td>
            </tr>
          </tbody>
        </Table>
      </Form>
    </div>
  );
};

export default ProcessEnglishunit;
