import React from "react";

const index = () => {
  const showUploader = () => {
    document.getElementById("upload_file").click();
  };

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex">
            <div>
              <span
                className="BreadCrum"
                // onClick={BackToDashboard}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Dashboard{" "}
              </span>
            </div>

            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>

            <div>
              <span style={{ fontSize: "14px" }}> Documentation </span>
            </div>
          </div>
        </div>
      </div>

      <div className="ml-4">
        <button
          className="btn btn-warning btn-air-warning"
          type="button"
          onClick={showUploader}
        >
          {" "}
          Upload File{" "}
        </button>

        <p className="mt-2" id="fileSizeError">
          {" "}
          {"*File size should not exceed 10 MB."}{" "}
        </p>

        <input
          className="form-control mt-4 w-25"
          type="file"
          id="upload_file"
          accept=".pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .jpeg, .png"
          defaultValue={null}
          // onChange={getFileName}
        />

        <div id="dialogBox" class="hidden">
          <div class="dialog-content">
            <p>
              {" "}
              File with name
              {/* {uploadedFileName}  */}
              already exists !{" "}
            </p>
            <button
              className="btn btn-warning btn-air-warning"
              type="button"
              // onClick={closeFileDialog}
            >
              {" "}
              Close{" "}
            </button>
          </div>
        </div>

        <div id="FileDialogBox" class="hidden">
          <div class="dialog-content">
            <p> File size exceeds 10 MB. </p>

            <button
              className="btn btn-warning btn-air-warning"
              type="button"
              // onClick={closeFileDialog}
            >
              {" "}
              Upload File{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
