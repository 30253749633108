import React, { useState } from "react";
import Header2 from "../../common/header-component/header2";
// CSS
import "../../App.css";

// To generate random Id
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { connect } from "react-redux";
import DataService from "../../../services/ApiService";

import { useHistory } from "react-router-dom";
import ConvertCalcfunctions from "./ConvertInputFields";
import { CalculateAverage } from "../ConversionFunctions";
import LeftMaterialForm from "./LeftMaterialForm";
import RightMaterialForm from "./RightMaterialForm";
import CustomFieldsTable from "./CustomFieldsTable";
import BreadCrumb from "../CommonSections/BreadCrumb";
import AlertModals from "../CommonSections/AlertModals";
import ButtonSection from "./ButtonSection";
import AddandEditCustomHeader from "./AddandEditCustomHeader";

const MaterialNew = ({ user }) => {
  const history = useHistory();

  const [EditModal, setEditModal] = useState(false);

  const [header, setHeader] = useState({
    header: "",
    key: null,
  });

  const [column, setColumn] = useState([]);

  const [DuplicateValue, setDuplicatetValue] = useState(false);

  const [EmptyAlert, setEmptyAlert] = useState(false);

  const [isColumnId, setIsColumnId] = useState(null);

  const [isDeleteId, setIsDeleteId] = useState(null);

  const [FieldsExists, setFieldsExists] = useState(false);

  const [SamePage, setSamePage] = useState(false);

  // State and Event for the Edit and Add Modal
  const [modal, setModal] = useState();

  const toggleEditHeader = () => {
    setModal(!modal);
  };

  const [materialData, setMaterialData] = useState({
    Material_Id: "",
    Base_Resin: "",
    Manufacturer: "",
    Specific_Gravity: "",
    Min_Melt_Temperature: "",
    Max_Melt_Temperature: "",
    Avg_Melt_Temperature: "",
    Min_Mold_Temperature: "",
    Max_Mold_Temperature: "",
    Avg_Mold_Temperature: "",
    Drying_Temperature: "",
    Drying_Time_Min: "",
    Drying_Time_Max: "",
    Max_Residence_Time: "",
  });

  const [materialUnitData, setmaterialUnitData] = useState({
    Base_Resin: {
      value: "",
      unit_id: "",
    },
    Material_Id: {
      value: "",
      unit_id: "",
    },
    Manufacturer: {
      value: "",
      unit_id: "",
    },
    Drying_Time_Max: {
      value: "",
      unit_id: "",
    },
    Drying_Time_Min: {
      value: "",
      unit_id: "",
    },
    Max_Residence_Time: {
      value: "",
      unit_id: "",
    },
    Specific_Gravity: {
      value: "",
      unit_id: "",
    },
    Drying_Temperature: {
      value: "",
      unit_id: "",
    },
    Min_Melt_Temperature: {
      value: "",
      unit_id: "",
    },
    Max_Melt_Temperature: {
      value: "",
      unit_id: "",
    },
    Avg_Melt_Temperature: {
      value: materialData.Avg_Melt_Temperature || "",
      unit_id: "",
    },
    Avg_Mold_Temperature: {
      value: materialData.Avg_Mold_Temperature || "",
      unit_id: "",
    },
    Max_Mold_Temperature: {
      value: "",
      unit_id: "",
    },
    Min_Mold_Temperature: {
      value: "",
      unit_id: "",
    },
  });

  const [UnitSettings, setUnitSettings] = useState({
    MeltTempUnit: {},
    MoldTempUnit: {},
    DryingTempUnit: {},
    TimeUnit: {},
  });

  let StoredUnits = JSON.parse(sessionStorage.getItem("StoredUnits")) || [];
  let SelectedUnits = JSON.parse(sessionStorage.getItem("SelectedUnits")) || [];
  let StoredMaterialData =
    JSON.parse(sessionStorage.getItem("MaterialData")) || [];

  const [UserSelectedUnits, setUserSelectedUnits] = useState([]);
  const [BaseUnits, setBaseUnits] = useState([]);
  const [SelectedUnitsArray, setSelectedUnitsArray] = useState([]);
  const [BaseUnitsArray, setBaseUnitsArray] = useState([]);

  const [SelectedPart, setSelectedPart] = useState("Melt");

  const [ModalStates, setModalStates] = useState({
    MaterialIdConfirm: {
      visibility: false,
      message: "Material Id is mandatory.",
    },
    DeleteConfirm: {
      visibility: false,
      message:
        "The field may have data associated with it. Do you want to continue deleting the same ?",
    },
    MaterialIdUnique: {
      visibility: false,
      message: "Material Id should be unique. This Material Id already exists.",
    },
    ConvertConfirm: {
      visibility: false,
      message:
        "You are changing the units, do you want to change the value accordingly ?",
    },
  });

  const ToggleModalStates = (ModalKey) => {
    setModalStates({
      ...ModalStates,
      [ModalKey]: {
        ...ModalStates[ModalKey],
        visibility: !ModalStates[ModalKey].visibility,
      },
    });
  };

  const handleChange = (event) => {
    const { name, value, dataset } = event.target;

    setMaterialData({
      ...materialData,
      [name]:
        value === ""
          ? null
          : isNaN(value)
          ? value
          : parseFloat(parseFloat(value).toFixed(2)),
    });

    let data = {
      value: value,
      unit_id: dataset.unit ? dataset.unit : null,
    };

    setmaterialUnitData(
      dataset.category === "Melt"
        ? {
            ...materialUnitData,
            [name]: data,
            Max_Melt_Temperature: {
              ...materialUnitData.Max_Melt_Temperature,
              unit_id: dataset.unit,
            },
            Avg_Melt_Temperature: {
              ...materialUnitData.Avg_Melt_Temperature,
              unit_id: dataset.unit,
            },
          }
        : dataset.category === "Mold"
        ? {
            ...materialUnitData,
            [name]: data,
            Max_Mold_Temperature: {
              ...materialUnitData.Max_Mold_Temperature,
              unit_id: dataset.unit,
            },
            Avg_Mold_Temperature: {
              ...materialUnitData.Avg_Mold_Temperature,
              unit_id: dataset.unit,
            },
          }
        : {
            ...materialUnitData,
            [name]: data,
          }
    );
  };

  const handleDropDownChange = (event) => {
    const { name, value, dataset } = event.target;

    setSelectedPart(dataset.category);

    setUnitSettings({
      ...UnitSettings,
      [name]: {
        unit_name: BaseUnits.find((unit) => unit.unit_id === parseInt(value))
          .unit_name,
        unit_id: BaseUnits.find((unit) => unit.unit_id === parseInt(value))
          .unit_id,
        decimals: SelectedUnitsArray.find(
          (unit) => unit.unit_id === parseInt(value)
        )
          ? SelectedUnitsArray.find((unit) => unit.unit_id === parseInt(value))
              .decimals
          : BaseUnits.find((unit) => unit.unit_id === parseInt(value)).decimals,
      },
    });

    let data = {
      value: materialData[dataset.fieldname],
      unit_id: value ? value : null,
    };

    ToggleModalStates("ConvertConfirm");

    setmaterialUnitData(
      dataset.category === "Melt"
        ? {
            ...materialUnitData,
            [dataset.fieldname]: data,
            Max_Melt_Temperature: {
              ...materialUnitData.Max_Melt_Temperature,
              unit_id: value,
            },
            Avg_Melt_Temperature: {
              ...materialUnitData.Avg_Melt_Temperature,
              unit_id: value,
            },
          }
        : dataset.category === "Mold"
        ? {
            ...materialUnitData,
            [dataset.fieldname]: data,
            Max_Mold_Temperature: {
              ...materialUnitData.Max_Mold_Temperature,
              unit_id: value,
            },
            Avg_Mold_Temperature: {
              ...materialUnitData.Avg_Mold_Temperature,
              unit_id: value,
            },
          }
        : {
            ...materialUnitData,
            [dataset.fieldname]: data,
          }
    );
  };

  const UpdateInputConversions = () => {
    if (SelectedPart === "Drying") {
      let unit = UnitSettings[`${SelectedPart}TempUnit`].unit_id;

      let UpdatedValues = ConvertCalcfunctions.ConvertInputFields({
        UnitSettings,
        materialData,
        SelectedPart,
      });

      setMaterialData({
        ...materialData,
        ...UpdatedValues,
      });

      setmaterialUnitData({
        ...materialUnitData,
        ["Drying_Temperature"]: {
          value: UpdatedValues["Drying_Temperature"],
          unit_id: unit,
        },
      });

      ToggleModalStates("ConvertConfirm");
    } else {
      let DataKeys = {
        Min: `Min_${SelectedPart}_Temperature`,
        Max: `Max_${SelectedPart}_Temperature`,
        Avg: `Avg_${SelectedPart}_Temperature`,
      };

      let UpdatedValues = ConvertCalcfunctions.ConvertInputFields({
        DataKeys,
        UnitSettings,
        materialData,
        SelectedPart,
      });

      function countDecimalPlaces(number) {
        const numberStr = number.toString();
        if (numberStr.includes(".")) {
          return numberStr.split(".")[1].length;
        } else {
          return 0;
        }
      }

      let Decimals = countDecimalPlaces(
        UnitSettings[`${SelectedPart}TempUnit`].decimals
      );

      let unit = UnitSettings[`${SelectedPart}TempUnit`].unit_id;

      document.getElementsByName(DataKeys.Min)[0].value =
        UpdatedValues[DataKeys.Min];
      document.getElementsByName(DataKeys.Max)[0].value =
        UpdatedValues[DataKeys.Max];

      setMaterialData({
        ...materialData,
        [DataKeys.Min]: UpdatedValues[DataKeys.Min],

        [DataKeys.Max]:
          UpdatedValues[DataKeys.Max] === 0 ? "" : UpdatedValues[DataKeys.Max],

        [DataKeys.Avg]: CalculateAverage(
          UpdatedValues[DataKeys.Min],
          UpdatedValues[DataKeys.Max],
          Decimals
        ),
      });

      setmaterialUnitData({
        ...materialUnitData,
        [DataKeys.Min]: {
          value: UpdatedValues[DataKeys.Min],
          unit_id: unit,
        },
        [DataKeys.Max]: {
          value: UpdatedValues[DataKeys.Max],
          unit_id: unit,
        },
        [DataKeys.Avg]: {
          value: CalculateAverage(
            UpdatedValues[DataKeys.Min],
            UpdatedValues[DataKeys.Max],
            Decimals
          ),
          unit_id: unit,
        },
      });

      ToggleModalStates("ConvertConfirm");
    }
  };

  const UpdateInputCalculations = (category) => {
    ConvertCalcfunctions.UpdateCalculations({
      UnitSettings,
      category,
      SelectedMaterialData: materialData,
      setSelectedMaterialData: setMaterialData,
      setSelectedMaterialsUnitData: setmaterialUnitData,
      SelectedMaterialsUnitData: materialUnitData,
    });
  };

  useEffect(() => {
    // console.log(materialUnitData);
  }, [materialUnitData]);

  const SaveData = () => {
    let PostData = {
      user: user.id,
      Material_Data: materialData,
      Units: materialUnitData,
    };

    let ColumnData;

    DataService.SaveMaterialData(PostData).then((res) => {
      let RowId = btoa(parseInt(res.data.Material_Id));

      // console.log(res.data.Material_Id)

      if (SamePage) {
        history.push({
          pathname: `/database/Options/${RowId}/MaterialView`,
        });
      } else {
        history.push({
          pathname: "/database/Database",
          TabIdx: 0,
        });
      }

      if (!FieldsExists) {
        ColumnData = {
          user: user.id,
          field_to_be_updated: "Material",
          Material_Custom_Fields: column,
          Mold_Custom_Fields: [],
          Machine_Custom_Fields: [],
        };

        // console.log(ColumnData);

        DataService.SaveFieldsData(ColumnData).then((res) => {});
      } else {
        ColumnData = {
          user: user.id,
          field_to_be_updated: "Material",
          Material_Custom_Fields: column,
        };

        // console.log(ColumnData);

        DataService.UpdateFieldsData(user.id, ColumnData).then((res) => {});
      }
    });
  };

  const CheckForMaterialId = () => {
    return new Promise((resolve, reject) => {
      let allFieldsFilled = true;

      // Check each field of each material object in the array
      if (!materialData["Material_Id"]) {
        allFieldsFilled = false;
      }

      if (allFieldsFilled) {
        resolve();
      } else {
        ToggleModalStates("MaterialIdConfirm");
      }
    });
  };

  const CheckForDuplicateMaterialId = () => {
    return new Promise((resolve, reject) => {
      let MaterialIdExists, IsNumberMaterialIdExists;

      // console.log(
      //   materialData["Material_Id"],
      //   isNaN(materialData["Material_Id"])
      // );

      if (isNaN(materialData["Material_Id"])) {
        MaterialIdExists = StoredMaterialData.some(
          (material) =>
            String(material.Material_Id).toLowerCase() ===
            String(materialData["Material_Id"]).toLowerCase()
        );
      } else {
        MaterialIdExists = StoredMaterialData.some(
          (material) => material.Material_Id === materialData["Material_Id"]
        );
      }

      if (!MaterialIdExists) {
        resolve();
      } else {
        ToggleModalStates("MaterialIdUnique");
      }

      // console.log(MaterialIdExists)
    });
  };

  const SubmitData = () => {
    CheckForMaterialId().then(() => {
      CheckForDuplicateMaterialId().then(() => {
        SaveData();
      });
    });
  };

  const ToggleEditModal = () => {
    setEditModal(!EditModal);

    setHeader({
      header: "",
      key: null,
    });

    setEmptyAlert(false);

    setDuplicatetValue(false);
  };

  const addHeader = (e) => {
    
    setHeader({
      header: e.target.value,
      key: column.length > 0 ? column[column.length - 1].field.slice(5) : 0,
    });

    setEmptyAlert(false);

    setDuplicatetValue(false);
  };

  const CheckForHeaderName = () => {
    return new Promise((resolve, reject) => {
      let HeaderExists;

      // console.log(column);

      if (column.length > 0) {
        HeaderExists = column.some(
          (headers) =>
            headers.headerText?.toLowerCase() === header.header.toLowerCase()
        );
      }

      resolve(HeaderExists);
    });
  };

  const addColumn = () => {
    if (!header.header) {
      setHeader({
        header: "",
        key: null,
      });

      setEmptyAlert(!EmptyAlert);
    } else {
      CheckForHeaderName().then((HeaderExists) => {
        if (!HeaderExists) {
          const newColumn = {
            id: nanoid(),
            field: `value${parseInt(header.key) + 1}`,
            headerText: header.header,
          };

          setColumn([...column, newColumn]);

          setHeader({
            header: "",
            key: null,
          });

          setEmptyAlert(false);

          setDuplicatetValue(false);

          // console.log(column);
        } else {
          setDuplicatetValue(!DuplicateValue);

          setHeader({
            header: "",
            key: null,
          });
        }
      });
    }
  };

  const editColumn = (data, key) => {
    setIsColumnId({
      data: data,
      key: key,
    });
  };

  const DeleteColumnId = (HeaderData) => {
    setIsDeleteId(HeaderData);
  };

  const editColumnHeader = () => {
    if (header) {
      // console.log(header);

      setColumn(
        column.map((element) => {
          if (element.id === isColumnId.data.id) {
            return { ...element, headerText: header.header };
          }
          return element;
        })
      );

      setHeader({
        header: "",
        key: null,
      });

      setIsColumnId(null);
    } else {
    }
  };

  const deleteColumn = () => {
    // console.log(isDeleteId)

    setColumn((prevArray) => {
      const newArray = prevArray.filter((HeaderData) => {
        return HeaderData.id != isDeleteId.id;
      });

      return newArray;
    });

    let TempArray = [materialData];

    const newArray = TempArray.some((obj) =>
      obj.hasOwnProperty(isDeleteId.headerText)
    );

    if (newArray) {
      delete materialData[isDeleteId.field];

      setIsDeleteId(null);

      // console.log(materialData);
    }

    ToggleModalStates("DeleteConfirm");
  };

  const GetData = (id) => {
    DataService.GetFieldsData(id).then((res) => {
      if (res.data.length > 0) {
        if (res.data[0].Material_Custom_Fields) {
          setColumn(res.data[0].Material_Custom_Fields);

          setFieldsExists(true);
        }
      }
    });
  };

  useEffect(() => {
    if (user) {
      GetData(user.id);

      let allUnits = [],
        selectedUnits = [];

      // console.log(StoredUnits)

      // Iterate through each category in the data
      for (const category in StoredUnits.Unit_Data[0]) {
        // Add each unit object to the allUnits array
        allUnits = allUnits.concat(StoredUnits.Unit_Data[0][category]);
      }

      // console.log(SelectedUnits);

      // Iterate through each category in the data
      for (const category in SelectedUnits.Unit_Data[0]) {
        // Add each unit object to the allUnits array
        selectedUnits = selectedUnits.concat(
          SelectedUnits.Unit_Data[0][category]
        );
      }

      setSelectedUnitsArray(selectedUnits);
      setUserSelectedUnits(SelectedUnits.Unit_Data[0]);

      setBaseUnits(allUnits);
      setBaseUnitsArray(StoredUnits.Unit_Data[0]);
    }
  }, [user]);

  useEffect(() => {
    // console.log(BaseUnits)
    // console.log(SelectedUnitsArray)
  }, [BaseUnits]);

  useEffect(() => {
    setUnitSettings({
      ...UnitSettings,
      MeltTempUnit: {
        ...UnitSettings.MeltTempUnit,
        unit_id:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_name
            : "",
        decimals: UserSelectedUnits.Temperature
          ? UserSelectedUnits.Temperature.decimals
          : 0,
      },

      MoldTempUnit: {
        ...UnitSettings.MoldTempUnit,
        unit_id:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_name
            : "",
        decimals: UserSelectedUnits.Temperature
          ? UserSelectedUnits.Temperature.decimals
          : 0,
      },

      DryingTempUnit: {
        ...UnitSettings.MoldTempUnit,
        unit_id:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_name
            : "",
        decimals: UserSelectedUnits.Temperature
          ? UserSelectedUnits.Temperature.decimals
          : 0,
      },
    });

    setmaterialUnitData({
      ...materialUnitData,
      Drying_Time_Max: {
        value: "",
        unit_id: BaseUnitsArray.Time ? BaseUnitsArray.Time[2].unit_id : "",
      },
      Drying_Time_Min: {
        value: "",
        unit_id: BaseUnitsArray.Time ? BaseUnitsArray.Time[2].unit_id : "",
      },
      Max_Residence_Time: {
        value: "",
        unit_id: 4,
      },
      Avg_Melt_Temperature: {
        value: "",
        unit_id:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_id
            : "",
      },
      Max_Melt_Temperature: {
        value: "",
        unit_id:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_id
            : "",
      },
      Avg_Mold_Temperature: {
        value: "",
        unit_id:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_id
            : "",
      },
      Max_Mold_Temperature: {
        value: "",
        unit_id:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_id
            : "",
      },
      Drying_Temperature: {
        value: "",
        unit_id:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_id
            : "",
      },
      Min_Melt_Temperature: {
        value: "",
        unit_id:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_id
            : "",
      },
      Min_Mold_Temperature: {
        value: "",
        unit_id:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
                (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
              ).unit_id
            : "",
      },
    });

    // console.log(UserSelectedUnits)
  }, [UserSelectedUnits, BaseUnitsArray]);

  return (
    <>
      <AlertModals
        ModalStates={ModalStates}
        ToggleModalStates={ToggleModalStates}
        UpdateInputConversions={UpdateInputConversions}
        deleteColumn={deleteColumn}
      />

      <Header2 Title="Database" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <BreadCrumb DB_Name={"Material"} Current_Page={"New"} />
        </div>

        <div className="container-fluid">
          <div className="card p-3  ml-2">
            <ButtonSection
              SubmitData={SubmitData}
              history={history}
              SamePage={SamePage}
              setSamePage={setSamePage}
            />

            <div className="d-flex col-md-12">
              <div className="col-md-4">
                <div className="form-inline">
                  <LeftMaterialForm
                    SelectedMaterialsUnitData={materialUnitData}
                    handleChange={handleChange}
                    handleDropDownChange={handleDropDownChange}
                    UpdateInputCalculations={UpdateInputCalculations}
                    StoredUnits={BaseUnits}
                    BaseUnitsArray={BaseUnitsArray}
                  />
                </div>
              </div>

              <div className="ml-1  col-md-4">
                <div className="form-inline">
                  <RightMaterialForm
                    SelectedMaterialsUnitData={materialUnitData}
                    handleChange={handleChange}
                    handleDropDownChange={handleDropDownChange}
                    UpdateInputCalculations={UpdateInputCalculations}
                    StoredUnits={BaseUnits}
                    BaseUnitsArray={BaseUnitsArray}
                  />
                </div>
              </div>

              <div className="ml-3 col-md-4">
                <CustomFieldsTable
                  column={column}
                  handleChange={handleChange}
                  SelectedMaterialData={materialData}
                  ToggleEditModal={ToggleEditModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <AddandEditCustomHeader
          EditModal={EditModal}
          EmptyAlert={EmptyAlert}
          DuplicateValue={DuplicateValue}
          addHeader={addHeader}
          header={header}
          addColumn={addColumn}
          column={column}
          editColumnHeader={editColumnHeader}
          editColumn={editColumn}
          DeleteColumnId={DeleteColumnId}
          toggleEditHeader={toggleEditHeader}
          ToggleModalStates={ToggleModalStates}
          ToggleEditModal={ToggleEditModal}
          modal={modal}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MaterialNew);
