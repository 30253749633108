import React from "react";

const PackHoldGrid = ({ PPS_Data, Machine_Unit_Data }) => {
  const CreateInputData = (event) => {
    const { name, value, dataset } = event.target;

    let data = {
      paramID: dataset.id,
      paramName: name,
      paramUnit:
        dataset.section === "PHVeloc"
          ? Machine_Unit_Data.velocity.unit_id
          : dataset.section === "PHPress"
          ? Machine_Unit_Data.pressure.unit_id
          : dataset.section === "PHTime"
          ? Machine_Unit_Data.time.unit_id
          : "",
      paramValue: value,
    };

    // console.log(data)

    PPS_Data.Inputs = [
      {
        ...PPS_Data.Inputs[0],
        [dataset.section]: [
          {
            ...PPS_Data.Inputs[0][dataset.section][0],
            [name]: data,
          },
        ],
      },
    ];
  };

  return (
    <div id="PPSGrid" className="PackHoldGrid InputsGrid">
      <table>
        <thead>
          <tr>
            <th colSpan="6"> Pack & Hold Phase </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"24"}
                data-section={"PHVeloc"}
                name="fldPHVeloc6"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHVeloc[0].fldPHVeloc6?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"25"}
                data-section={"PHVeloc"}
                name="fldPHVeloc5"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHVeloc[0].fldPHVeloc5?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"26"}
                data-section={"PHVeloc"}
                name="fldPHVeloc4"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHVeloc[0].fldPHVeloc4?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"27"}
                data-section={"PHVeloc"}
                name="fldPHVeloc3"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHVeloc[0].fldPHVeloc3?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"28"}
                data-section={"PHVeloc"}
                name="fldPHVeloc2"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHVeloc[0].fldPHVeloc2?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"29"}
                data-section={"PHVeloc"}
                name="fldPHVeloc1"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHVeloc[0].fldPHVeloc1?.paramValue
                }
              />
            </td>
          </tr>
          <tr>
            <td>P/H Vel6</td>
            <td> P/H Vel5 </td>
            <td> P/H Vel4 </td>
            <td> P/H Vel3 </td>
            <td> P/H Vel2 </td>
            <td> P/H Vel1 </td>
          </tr>
          <tr>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"30"}
                data-section={"PHPress"}
                name="fldPHPress6"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHPress[0].fldPHPress6?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"31"}
                data-section={"PHPress"}
                name="fldPHPress5"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHPress[0].fldPHPress5?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"32"}
                data-section={"PHPress"}
                name="fldPHPress4"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHPress[0].fldPHPress4?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"34"}
                data-section={"PHPress"}
                name="fldPHPress3"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHPress[0].fldPHPress3?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"35"}
                data-section={"PHPress"}
                name="fldPHPress2"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHPress[0].fldPHPress2?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"36"}
                data-section={"PHPress"}
                name="fldPHPress1"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHPress[0].fldPHPress1?.paramValue
                }
              />
            </td>
          </tr>
          <tr>
            <td> P/H Press6 </td>
            <td> P/H Press5 </td>
            <td> P/H Press4 </td>
            <td> P/H Press3 </td>
            <td> P/H Press2 </td>
            <td> P/H Press1 </td>
          </tr>
          <tr>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"37"}
                data-section={"PHTime"}
                name="fldPHTime6"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHTime[0].fldPHTime6?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"38"}
                data-section={"PHTime"}
                name="fldPHTime5"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHTime[0].fldPHTime5?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"39"}
                data-section={"PHTime"}
                name="fldPHTime4"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHTime[0].fldPHTime4?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"40"}
                data-section={"PHTime"}
                name="fldPHTime3"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHTime[0].fldPHTime3?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"41"}
                data-section={"PHTime"}
                name="fldPHTime2"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHTime[0].fldPHTime2?.paramValue
                }
              />
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"42"}
                data-section={"PHTime"}
                name="fldPHTime1"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].PHTime[0].fldPHTime1?.paramValue
                }
              />
            </td>
          </tr>
          <tr>
            <td> P/H Time6 </td>
            <td> P/H Time5 </td>
            <td> P/H Time4 </td>
            <td> P/H Time3 </td>
            <td> P/H Time2 </td>
            <td> P/H Time1 </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PackHoldGrid;
