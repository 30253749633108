import React from "react";
import Block1 from "../InputImages/Block1.png";

const MoldTempGrid = ({ PPS_Data, Machine_Unit_Data, showAlert }) => {
  const CreateInputData = (event) => {
    const { name, value, dataset } = event.target;

    let data = {
      paramID: dataset.id,
      paramName: name,
      paramUnit:
        dataset.section === "fldCoolingTime"
          ? Machine_Unit_Data.time.unit_id
          : dataset.section === "MoldTempASide" ||
            dataset.section === "MoldTempBSide"
          ? Machine_Unit_Data.temperature.unit_id
          : "",
      paramValue: value,
    };

    if (dataset.section === "MoldTempASide") {
      PPS_Data.Inputs = [
        {
          ...PPS_Data.Inputs[0],
          [dataset.section]: [
            {
              ...PPS_Data.Inputs[0][dataset.section][0],
              [name]: data,
            },
          ],
        },
      ];
    } else if (dataset.section === "MoldTempBSide") {
      PPS_Data.Inputs = [
        {
          ...PPS_Data.Inputs[0],
          [dataset.section]: [
            {
              ...PPS_Data.Inputs[0][dataset.section][0],
              [name]: data,
            },
          ],
        },
      ];
    } else if (dataset.section === "MoldTempNotes") {
      PPS_Data.Inputs = [
        {
          ...PPS_Data.Inputs[0],
          [dataset.section]: [
            {
              ...PPS_Data.Inputs[0][dataset.section][0],
              [name]: data,
            },
          ],
        },
      ];
    } else if (dataset.section === "fldCoolingTime") {
      PPS_Data.Inputs = [
        {
          ...PPS_Data.Inputs[0],
          [dataset.section]: data,
        },
      ];
    }

    showAlert.current = true
  };

  return (
    <div id="PPSGrid" className="MoldTempGrid">
      <table className="mold-temp-table">
        <thead>
          <tr>
            <th>Mold Temp Notes</th>
            <th style={{ width: "5vw" }}>Sections</th>
            <th colSpan="2">Mold Temp Inputs</th> {/* Adjusting the colspan */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {" "}
              <input
                className="form-control"
                data-id={"1"}
                data-section={"MoldTempNotes"}
                defaultValue={
                  PPS_Data.Inputs[0].MoldTempNotes[0].fldMoldTempNotes3
                    ?.paramValue
                }
                name="fldMoldTempNotes3"
                onChange={CreateInputData}
                tabIndex={1}
              />
            </td>
            <td>
              {" "}
              <strong> 3 </strong>{" "}
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"2"}
                data-section={"MoldTempBSide"}
                name="fldMoldTempBSide3"
                defaultValue={
                  PPS_Data.Inputs[0].MoldTempBSide[0].fldMoldTempBSide3
                    ?.paramValue
                }
                onChange={CreateInputData}
              />{" "}
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"3"}
                data-section={"MoldTempASide"}
                name="fldMoldTempASide3"
                defaultValue={
                  PPS_Data.Inputs[0].MoldTempASide[0].fldMoldTempASide3
                    ?.paramValue
                }
                onChange={CreateInputData}
              />{" "}
            </td>
          </tr>

          {/* 2nd row */}
          <tr>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                data-id={"4"}
                data-section={"MoldTempNotes"}
                name="fldMoldTempNotes2"
                defaultValue={
                  PPS_Data.Inputs[0].MoldTempNotes[0].fldMoldTempNotes2
                    ?.paramValue
                }
                onChange={CreateInputData}
              />{" "}
            </td>
            <td>
              {" "}
              <strong> 2 </strong>{" "}
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"5"}
                data-section={"MoldTempBSide"}
                name="fldMoldTempBSide2"
                defaultValue={
                  PPS_Data.Inputs[0].MoldTempBSide[0].fldMoldTempBSide2
                    ?.paramValue
                }
                onChange={CreateInputData}
              />{" "}
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"6"}
                data-section={"MoldTempASide"}
                name="fldMoldTempASide2"
                defaultValue={
                  PPS_Data.Inputs[0].MoldTempASide[0].fldMoldTempASide2
                    ?.paramValue
                }
                onChange={CreateInputData}
              />{" "}
            </td>
          </tr>

          {/* 1st row */}
          <tr>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                data-id={"7"}
                data-section={"MoldTempNotes"}
                name="fldMoldTempNotes1"
                defaultValue={
                  PPS_Data.Inputs[0].MoldTempNotes[0].fldMoldTempNotes1
                    ?.paramValue
                }
                onChange={CreateInputData}
              />{" "}
            </td>
            <td>
              {" "}
              <strong> 1 </strong>{" "}
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"8"}
                data-section={"MoldTempBSide"}
                name="fldMoldTempBSide1"
                defaultValue={
                  PPS_Data.Inputs[0].MoldTempBSide[0].fldMoldTempBSide1
                    ?.paramValue
                }
                onChange={CreateInputData}
              />{" "}
            </td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"9"}
                data-section={"MoldTempASide"}
                name="fldMoldTempASide1"
                defaultValue={
                  PPS_Data.Inputs[0].MoldTempASide[0].fldMoldTempASide1
                    ?.paramValue
                }
                onChange={CreateInputData}
              />{" "}
            </td>
          </tr>

          <tr>
            <td colSpan={2} rowSpan={2}>
              <div className="p-1">
                <h6>
                  <strong> Process Inputs </strong>
                </h6>
              </div>
            </td>
            <td>
              <strong> B-side </strong>
            </td>
            <td>
              <strong>A-side</strong>
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <div>
                <img
                  style={{ padding: "0px" }}
                  src={Block1}
                  alt="Block1"
                  width={150}
                  height={50}
                />
              </div>
            </td>
          </tr>

          <tr>
            <td colSpan={2}>Cooling Time</td>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"10"}
                data-section={"fldCoolingTime"}
                defaultValue={PPS_Data.Inputs[0].fldCoolingTime?.paramValue}
                name="fldCoolingTime"
                onChange={CreateInputData}
              />{" "}
            </td>
            <td> Sec</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MoldTempGrid;
