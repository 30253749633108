import React, { useState } from "react";
import "./MachineInformation.css";
import "../PPS.css";
import MoldTable from "./MoldTable";

const MachineInformation = ({
  Machine_Unit_Data,
  MachineData,
  UpdateUnitData,
  BaseUnitsArray,
  BaseUnits,
  UpdateMachineData,
  Mold_Machine_Info,
  MoldData,
  GetGeneralInfo,
  GeneralInfo,
  PPS_Data,
  setTabIdx,
  showAlert,
}) => {
  const CreateInputData = (event) => {
    const { name, value } = event.target;

    if (name === "NozzleType") {
      PPS_Data.HRSett[name] = parseFloat(value);
    }

    if (name === "NozzleLength") {
      PPS_Data.HRSett[name] = parseFloat(value);
    }

    if (name === "NozzleOrificeSize") {
      PPS_Data.HRSett[name] = parseFloat(value);
    }

    showAlert.current = true;
  };

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];

  // useEffect(() => {
  //   setTabIdx(1)
  // }, [])

  return (
    <div className="Machine_info_container">
      {/* Machine Information Section */}
      {/* *********************************** 1st Section *********************************** */}
      <div className="first_quadrant">
        <div style={{ width: "40vw" }}>
          <div className="d-flex align-items-right justify-content-start">
            <div>
              <label className="label">Processor :</label>
              <input
                type="text"
                className="textBox"
                name="user"
                onChange={GetGeneralInfo}
                defaultValue={GeneralInfo.user}
              />
            </div>
            <div>
              <label className="labelDate">Date:</label>
              <input
                type="date"
                className="datePicker"
                name="Session_Date"
                onChange={GetGeneralInfo}
                defaultValue={GeneralInfo.Session_Date}
                max={today} // Set max to today's date
              />
            </div>
          </div>

          <div className="d-flex align-items-right justify-content-start mb-2">
            <label className="label">Process Sheet ID: *</label>
            <input
              type="text"
              className="textBoxWide"
              name="Session_Name"
              onChange={GetGeneralInfo}
              defaultValue={GeneralInfo?.Session_Name}
            />
          </div>

          <div className="d-flex align-items-right justify-content-start">
            <label className="label">Notes :</label>
            <textarea
              className="rtfBoxWide"
              name="Session_Notes"
              onChange={GetGeneralInfo}
              defaultValue={GeneralInfo?.Session_Notes}
            />
          </div>
        </div>
      </div>

      {/* Machine Number Section */}
      {/* *********************************** 2nd Section *********************************** */}
      <div className="first_quadrant">
        <div className="w-50">
          <div>
            <h5>Machine Information</h5>
          </div>
          <div className="formGroup">
            <label className="label mt-2">Machine Number* :</label>
            <select
              className="selectBox"
              name="Machine_Info"
              value={
                Mold_Machine_Info.SelectedMachine != null
                  ? Mold_Machine_Info.SelectedMachine.id
                  : ""
              }
              onChange={(e) => UpdateMachineData(e)}
            >
              {MachineData.length > 0 ? (
                MachineData.map((value, key) => (
                  <option value={value.id} key={key}>
                    {value.Machine_Id}
                  </option>
                ))
              ) : (
                <></>
              )}
            </select>
          </div>
          <div className="d-flex align-items-left justify-content-start">
            <label className="label mt-1"> Intensification Ratio :</label>
            <span className="m-1">
              {Mold_Machine_Info.SelectedMachine
                ? Mold_Machine_Info.SelectedMachine.IR
                : "NA"}
            </span>
          </div>
          <div className="d-flex align-items-left justify-content-start">
            <label className="label mt-1"> Screw Diameter :</label>
            <span>
              {Mold_Machine_Info.SelectedMachine
                ? Mold_Machine_Info.SelectedMachine.SD
                : "NA"}
            </span>
            <span>
              {Mold_Machine_Info.SelectedMachine
                ? Mold_Machine_Info.SelectedMachine.SD_Unit
                : "NA"}
            </span>
          </div>
        </div>
      </div>

      {/* Mold Information Section */}
      {/* *********************************** 3rd Section *********************************** */}
      <div className="second_quadrant" style={{ border: "1px solid black" }}>
        <h5>Mold Information</h5>
        <div className="m-2 d-flex" style={{ border: "1px solid black" }}>
          <span className="ml-2 mr-2">
            Mold No:{" "}
            <strong>{MoldData.length > 0 ? MoldData[0].Mold_Id : ""}</strong>
          </span>
          <span className="ml-2 mr-2" style={{ borderLeft: "1px solid black" }}>
            No of Cavity:{" "}
            <strong>{Mold_Machine_Info.SelectedMoldTotalCavities}</strong>{" "}
          </span>
          <span className="ml-2 mr-2" style={{ borderLeft: "1px solid black" }}>
            Material:{" "}
            <strong>
              {MoldData.length > 0 ? MoldData[0].Mold_Data.Material_Id : "NA"}
            </strong>
          </span>
          <span className="ml-2 mr-2" style={{ borderLeft: "1px solid black" }}>
            Total Shot Wt: <strong> {Mold_Machine_Info.TotalShotWt} </strong>
          </span>
        </div>
        <div className="mold_quadrant">
          <MoldTable
            BaseUnits={BaseUnits}
            MoldData={MoldData}
            BaseUnitsArray={BaseUnitsArray}
          />
        </div>
      </div>

      {/* Nozzle Settings Section */}
      {/* *********************************** 4th Section *********************************** */}
      <div className="nozzleSettingsContainer">
        <div className="nozzleSettings">
          {/* Existing Nozzle Settings */}
          <div className="existingNozzleSettings">
            <div className="formGroup">
              <label className="label">Pressure* :</label>
              <select
                name="pressure"
                className="selectBox"
                defaultValue={Machine_Unit_Data.pressure.unit_id}
                onChange={(e) => UpdateUnitData(e)}
              >
                {BaseUnitsArray.Pressure ? (
                  BaseUnitsArray.Pressure.map((value, key) => (
                    <option value={value.unit_id} key={key}>
                      {" "}
                      {value.unit_name}{" "}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Temperature* :</label>
              <select
                className="selectBox"
                name="temperature"
                defaultValue={Machine_Unit_Data.temperature.unit_id}
                onChange={(e) => UpdateUnitData(e)}
              >
                {BaseUnitsArray.Temperature ? (
                  BaseUnitsArray.Temperature.map((value, key) => (
                    <option value={value.unit_id} key={key}>
                      {" "}
                      {value.unit_name}{" "}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Distances* :</label>
              <select
                className="selectBox"
                name="distance"
                defaultValue={Machine_Unit_Data.distance.unit_id}
                onChange={(e) => UpdateUnitData(e)}
              >
                {BaseUnitsArray.Distance ? (
                  BaseUnitsArray.Distance.map((value, key) => (
                    <option value={value.unit_id} key={key}>
                      {" "}
                      {value.unit_name}{" "}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Time* :</label>
              <select
                className="selectBox"
                name="time"
                defaultValue={Machine_Unit_Data.time.unit_id}
                onChange={(e) => UpdateUnitData(e)}
              >
                {BaseUnitsArray.Time ? (
                  BaseUnitsArray.Time.map((value, key) => (
                    <option value={value.unit_id} key={key}>
                      {" "}
                      {value.unit_name}{" "}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Velocity* :</label>
              <select
                className="selectBox"
                name="velocity"
                defaultValue={Machine_Unit_Data.velocity.unit_id}
                onChange={(e) => UpdateUnitData(e)}
              >
                {BaseUnitsArray.Speed ? (
                  BaseUnitsArray.Speed.map((value, key) => (
                    <option value={value.unit_id} key={key}>
                      {" "}
                      {value.unit_name}{" "}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Weight* :</label>
              <select
                className="selectBox"
                name="weight"
                defaultValue={Machine_Unit_Data.weight.unit_id}
                onChange={(e) => UpdateUnitData(e)}
              >
                {BaseUnitsArray.Weight ? (
                  BaseUnitsArray.Weight.map((value, key) => (
                    <option value={value.unit_id} key={key}>
                      {" "}
                      {value.unit_name}{" "}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>

          {/* New Box on the Right */}
          <div className="nozzleSettingsBox">
            <div className="nozzleSettingsHeader">Nozzle Settings</div>
            <div className="nozzleSettingsContent">
              <div className="nozzleSettingsItem">
                <label className="nozzleSettingsLabel">Nozzle Type :</label>
                <input
                  type="text"
                  className="smallTextBox"
                  name="NozzleType"
                  defaultValue={PPS_Data.HRSett?.NozzleType}
                  onChange={CreateInputData}
                />
              </div>
              <div className="nozzleSettingsItem">
                <label className="nozzleSettingsLabel">Nozzle Length :</label>
                <input
                  type="text"
                  className="smallTextBox"
                  name="NozzleLength"
                  defaultValue={PPS_Data.HRSett?.NozzleLength}
                  onChange={CreateInputData}
                />
              </div>
              <div className="nozzleSettingsItem">
                <label className="nozzleSettingsLabel">
                  Nozzle Orifice Size :
                </label>
                <input
                  type="text"
                  className="smallTextBox"
                  name="NozzleOrificeSize"
                  defaultValue={PPS_Data.HRSett?.NozzleOrificeSize}
                  onChange={CreateInputData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineInformation;
