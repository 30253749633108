import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useHistory } from "react-router-dom";

const MainModuleCard = ({ ModuleTitle, ModulePath, Icon }) => {
  const history = useHistory();

  const NavigateToModule = (ModulePath) => {
    history.push(`${ModulePath}`);
  };

  return (
    <div>
      <Card className="card" onClick={() => NavigateToModule(`${ModulePath}`)}>
        <CardBody>
          <div className="icon-container">
            <img
              src={Icon}
              alt={ModuleTitle}
              className="menu-icon"
              style={{ width: "5rem", height: "5rem" }}
            />
          </div>
        </CardBody>
        <CardHeader style={{ fontWeight: "bold", fontSize: "1.3em" }}>
          {" "}
          {ModuleTitle}{" "}
        </CardHeader>
      </Card>
    </div>
  );
};

export default MainModuleCard;
