import React, { useState } from "react";
import "./style.css";

const Temperature = () => {
  const [inputValue, SetInputValue] = useState("");
  const [outputValue, SetOutputValue] = useState("");
  const [baseUnit, SetBaseUnit] = useState("degree celcius");
  const [resultUnit, SetResultUnit] = useState("degree celcius");
  const temperature = ["degree celcius", "degree fahrenheit"];

  let calcTemperature = (event) => {
    // function to calculate area conversions
    event.preventDefault(); //prevent submitting

    if (inputValue.isNaN) {
      alert("Please enter a value");
    } else if (baseUnit === resultUnit) {
      SetOutputValue(inputValue);
    } else {
      if (baseUnit === "degree celcius" && resultUnit === "degree fahrenheit") {
        let results = (inputValue * 1.8000) + 32;
        SetOutputValue(results);
      } else if (baseUnit === "degree fahrenheit" && resultUnit === "degree celcius") {
        let results = ((inputValue - 32) * 5) / 9;
        SetOutputValue(results);
      }
    }
  };

  let reload = () => {
    SetInputValue(0)
    SetOutputValue('')
  };

  const onDropDownChange = (e) => {

    SetResultUnit(e.target.value)
    SetOutputValue('')

  }

  return (
    <div className="d-flex justify-content-center">
      <div className="b-primary b-r-4 mb-2 col-md-7">
        <h6 className="mt-3 ml-3">Temperature</h6>

        <form onSubmit={calcTemperature}>
          <div>

            <input
              name="inputValue"
              type="text"
              onKeyPress={(event) => {
                if (!/[0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              className="input_style"
              value={inputValue}
              onChange={(e) => SetInputValue(e.target.value)}
            />

            <select
              name="baseUnit"
              value={baseUnit}
              onChange={(e) => SetBaseUnit(e.target.value)}
            >
              {temperature.map((tmp) => (
                <option key={tmp} value={tmp}>
                  {tmp}
                </option>
              ))}
            </select>

          </div>

          <div>

            <input
              name="outputValue"
              type="text"
              onKeyPress={(event) => {
                if (!/[0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              className="input_style"
              value={outputValue}
              disabled={true}
              onChange={(e) => SetOutputValue(e.target.value)}
            />


            <select
              name="resultUnit"
              value={resultUnit}
              onChange={onDropDownChange}
            >
              {temperature.map((tmp) => (
                <option key={tmp} value={tmp}>
                  {tmp}
                </option>
              ))}
            </select>

          </div>

          <button type="submit" className='btn btn-info ml-3'>Convert</button>
          <button className='btn btn-dark ml-3' onClick={reload}>Reset</button>
        </form>

        <div>
          <p>
            The Equivalent value of {inputValue} {baseUnit} is {outputValue}{" "}
            {resultUnit}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Temperature;
