import React, { useEffect } from "react";
import "../../App.css";
import "../../../assets/custom-stylesheet/grid_stylecss.css";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  RowsDirective,
  RowDirective,
  CellsDirective,
  CellDirective,
  getCell,
} from "@syncfusion/ej2-react-spreadsheet";
import { getRangeAddress, getRangeIndexes } from "@syncfusion/ej2-spreadsheet";
import AddRow from "./AddRow";

const PCGrid = ({
  DeleteModalConfirmToggle,
  addColumn,
  PCSpreadsheet,
  setPCGridData,
  PCSheetColCount,
  getIndex,
  getPCSheetData,
  PCSheetRowCount,
  deleteRow2,
  GetValues,
  ToggleAddRowModal,
  ScrewAddRowModal,
  addRow,
  increaseRow,
  handleTabChange,
  setSelectedColData,
  setBin_Interval,
  setChartData,
  CalculateBin,
  CreateChartData,
  saveData,
  UpdateData,
  showSave,
  colCount,
  PopulatePCSheet,
  setHeaderArray,
  setComputeClicked,
  SelectedColData,
  ToggleLimitValidationModal,
  ToggleSGSizeModal,
  PerformCalcs,
  SelectedCol,
}) => {
  const protectSettings = { selectCells: true };

  const scrollSettings = { isFinite: true };

  let isPaste = false;

  let subgroupRowIndex = 4,
    subgroupStartingRowIndex = 5,
    firstSubgroupColIdx = 1;

  const GetAllColData = async () => {
    try {
      let TempArray = [];

      const cells = await PCSpreadsheet.current.getData(
        PCSpreadsheet.current.getActiveSheet().name +
          "!" +
          getRangeAddress([0, 1, PCSheetRowCount, PCSheetColCount - 1])
      );

      cells.forEach((cell, key) => {
        TempArray.push(cell.value ? cell.value : null);
      });

      return TempArray;
    } catch (error) {
      console.error("Error in GetAllColData:", error);
      throw error;
    }
  };

  const ConvertToObject = async (AllColData) => {
    try {
      let TempDataArray = [];
      let NumberOfRow = 0;

      for (
        let i = 0;
        i < Math.ceil(AllColData.length / parseInt(PCSheetColCount - 1));
        i++
      ) {
        let TempDataObj = {};

        for (let j = 0; j < PCSheetColCount - 1; j++) {
          TempDataObj[`value${j + 1}`] = AllColData[j + NumberOfRow];
        }

        NumberOfRow += PCSheetColCount - 1;
        TempDataArray.push(TempDataObj);
      }

      return TempDataArray;
    } catch (error) {
      console.error("Error in ConvertToObject:", error);
      throw error;
    }
  };

  const setSubGroup = () => {
    let sheet = PCSpreadsheet.current.getActiveSheet();
    let lastRowIdx = sheet.usedRange.rowIndex;
    let lastColIdx = PCSheetColCount;

    for (
      let columnIdx = firstSubgroupColIdx;
      columnIdx <= lastColIdx;
      columnIdx++
    ) {
      let subgroupSize = parseInt(
        getCell(subgroupRowIndex, columnIdx, sheet).value,
        10
      );

      for (
        let i = subgroupStartingRowIndex;
        i <= lastRowIdx;
        i += subgroupSize * 2
      ) {
        for (let j = 0; j < subgroupSize && i + j <= lastRowIdx; j++) {
          PCSpreadsheet.current.cellFormat(
            { backgroundColor: "#F8BFA2" },
            getRangeAddress([i + j, columnIdx, i + j, columnIdx])
          );
        }

        for (
          let j = subgroupSize;
          j < subgroupSize * 2 && i + j <= lastRowIdx;
          j++
        ) {
          PCSpreadsheet.current.cellFormat(
            { backgroundColor: "#fff" },
            getRangeAddress([i + j, columnIdx, i + j, columnIdx])
          );
        }
      }
    }
  };

  const SetSubGroupSize = async (SelectedData) => {
    try {
      let cell = PCSpreadsheet.current.getActiveSheet().activeCell;

      let cellIdx = getRangeIndexes(cell);

      const alphabetArray = [
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];

      if (cellIdx[1] !== null && SelectedData.sub_group_size === null) {
        PCSpreadsheet.current.updateCell(
          { value: 1 },
          `${alphabetArray[cellIdx[1]-1]}5`
        );
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const GatherData = async () => {
    try {
      // Getting the index of the selected row/column
      await getIndex();

      // Getting the values/data entered in the selected column
      const TempArray = await getPCSheetData();

      // Getting Limits and other data of the column
      const SelectedData = await GetValues(TempArray);
      setSelectedColData(SelectedData);

      await SetSubGroupSize(SelectedData);

      // console.log()

      PerformCalcs(SelectedData);

      // Based on the column data calculating bin interval
      const binInterval = await CalculateBin(SelectedData.values);
      setBin_Interval(binInterval);

      // Now, Finally getting all the data in the sheet
      const ColData = await GetAllColData();

      // Then converting it to an array of objects
      const ObjData = await ConvertToObject(ColData);

      // Creating chart data for histogram again in array of objects format
      const HistoData = await CreateChartData(SelectedData);

      // console.log(SelectedData);

      setChartData(HistoData);
      setPCGridData(ObjData);
      PopulatePCSheet(ObjData);

      setComputeClicked(true);
      setSubGroup();

      // console.log(SelectedData);

      return SelectedData;
    } catch (error) {
      console.error("Error in GatherData:", error);
      throw error;
    }
  };

  const TabChange = () => {
    setComputeClicked(true);

    handleTabChange("2");

    GatherData();

    let TempHeaders = [];

    for (let j = 1; j < colCount; j++) {
      let cellValue = getCell(
        0,
        j,
        PCSpreadsheet.current.getActiveSheet()
      ).value;
      TempHeaders.push(cellValue ? cellValue : `Characteristics ${j}`);
    }

    setHeaderArray(TempHeaders);
  };

  const ValidateLimits = () => {
    let CheckLimits =
      !isNaN(parseFloat(SelectedColData.upper_limit)) &&
      !isNaN(parseFloat(SelectedColData.lower_limit));

    // console.log(SelectedColData);

    // if (CheckLimits) {
    //   if (
    //     SelectedColData.upper_limit !== null &&
    //     SelectedColData.lower_limit !== null &&
    //     parseFloat(SelectedColData.upper_limit) >
    //       parseFloat(SelectedColData.lower_limit)
    //   ) {
    TabChange();
    //   } else {
    //     ToggleLimitValidationModal();
    //   }
    // }
  };

  function UnlockCells() {
    PCSpreadsheet.current.getActiveSheet().colCount = colCount || 2;

    PCSpreadsheet.current.lockCells(`B1:Z${PCSheetRowCount}`, false);

    PCSpreadsheet.current.setColumnsWidth(120, ["A:Z"]);

    setSubGroup();
  }

  const cellEditing = (args) => {
    if (args.value !== args.oldValue && !isNaN(args.value)) {
      PCSpreadsheet.current.updateCell({ value: args.value }, args.address);

      GatherData();
    }
  };

  function created() {
    //Applies data validation to specified range in the active sheet.
    PCSpreadsheet.current.addDataValidation(
      {
        type: "Decimal",
        operator: "Between",
        value1: "-10000.0000",
        value2: "10000.0000",
      },
      `B2:Z${PCSpreadsheet.current.getActiveSheet().rowCount}`
    );

    PCSpreadsheet.current.lockCells(`B1:Z${PCSheetRowCount}`, false);

    PCSpreadsheet.current.setColumnsWidth(120, ["A:Z"]);

    setSubGroup();
  }

  const dialogBeforeOpen = (args) => {
    if (args.dialogName === "EditAlertDialog") {
      args.cancel = true;
    }

    // Edit the dialog content using the dialogBeforeOpen event.
    if (args.dialogName === "ValidationErrorDialog") {
      args.cancel = true;
    }
  };

  function cellSave(args) {
    let cell = PCSpreadsheet.current.getActiveSheet().activeCell;

    let cellIdx = getRangeIndexes(cell);

    // console.log(cellIdx[0])

    if (cellIdx[0] === 4) {
      if (
        args.value &&
        parseFloat(args.value) >= 1 &&
        parseFloat(args.value) <= 15
      ) {
      } else {
        PCSpreadsheet.current.updateCell({ value: 1 }, args.address);

        // console.log(args.address)

        args.cancel = true;

        ToggleSGSizeModal();
      }
    }
  }

  function beforeCellUpdate(args) {
    // Skip the cell update for paste action that contains characters, If you need you can skip this for all type of actions performed in the spreadsheet
    if (isPaste) {
      let pastedValue = args.cell.value;

      // Match alphabets and special characters
      var regex = /[a-zA-Z]/g;

      if (
        args.rowIndex != 0 &&
        pastedValue &&
        pastedValue.toString().match(regex)
      ) {
        args.cancel = true;
      }

      isPaste = false;
    }
  }

  function actionBegin(args) {
    if (args.args.eventArgs && args.args.eventArgs.requestType === "paste") {
      isPaste = true;
    }

    if (args.action === "cellSave" || args.action === "clipboard") {
      setSubGroup();

      // console.log(args.action)
    }

    if (
      args.action === "clipboard" &&
      args.args.eventArgs.requestType === "paste"
    ) {
      //Set the type to 'Values' to paste only the values.
      args.args.eventArgs.type = "Values";
    }
  }

  useEffect(() => {
    for (let i = 6; i <= PCSheetRowCount; i++) {
      let value = i - 5;

      PCSpreadsheet.current.updateCell({ value: value }, `A${i}`);
    }
  }, [PCSheetRowCount, PCSpreadsheet, PCSheetColCount]);

  return (
    <>
      <div className="b-primary b-r-4 mb-2">
        <div className="ml-3 mt-0 ml-1 mr-1">
          <div className="d-flex">
            <div className="mt-2">
              <button
                className="btn btn-info btn-air-info mr-2"
                type="button"
                onClick={addColumn}
              >
                {" "}
                Add Column{" "}
              </button>
            </div>

            <div>
              <button
                className="btn btn-warning btn-air-warning mt-2 mr-2"
                type="button"
                onClick={DeleteModalConfirmToggle}
              >
                {" "}
                Delete Column{" "}
              </button>
            </div>

            <div>
              <button
                className="btn btn-info btn-air-info mr-2 mt-2"
                type="button"
                onClick={ToggleAddRowModal}
              >
                {" "}
                Add Row{" "}
              </button>
              <AddRow
                ToggleAddRowModal={ToggleAddRowModal}
                ScrewAddRowModal={ScrewAddRowModal}
                addRow={addRow}
                increaseRow={increaseRow}
              />
            </div>

            <div>
              <button
                className="btn btn-warning btn-air-warning mt-2 mr-2"
                type="button"
                onClick={deleteRow2}
              >
                {" "}
                Delete Row{" "}
              </button>
            </div>

            <div>
              <button
                className="btn btn-primary btn-air-primary mt-2 mr-2"
                type="button"
                onClick={ValidateLimits}
              >
                {" "}
                Compute{" "}
              </button>
            </div>

            {/* <div className="mt-2">
              {showSave ? (
                <button
                  className="btn btn-secondary btn-air-secondary"
                  type="button"
                  onClick={saveData}
                >
                  {" "}
                  Save{" "}
                </button>
              ) : (
                <button
                  className="btn btn-fifth btn-air-fifth"
                  type="button"
                  onClick={UpdateData}
                >
                  {" "}
                  Update{" "}
                </button>
              )}
            </div> */}
          </div>
        </div>

        <div
          className="spreadsheet m-2"
          id="PC_Sheet"
          onMouseEnter={UnlockCells}
        >
          <SpreadsheetComponent
            ref={PCSpreadsheet}
            width={"90%"}
            height={450}
            className="pcGrid"
            onBlur={GatherData}
            cellEdited={GatherData}
            cellEditing={cellEditing}
            dialogBeforeOpen={dialogBeforeOpen.bind(this)}
            showFormulaBar={false}
            showSheetTabs={false}
            showRibbon={false}
            scrollSettings={scrollSettings}
            created={created}
            allowAutoFill={false}
            cellSave={cellSave.bind(this)}
            beforeCellUpdate={beforeCellUpdate.bind(this)}
            actionBegin={actionBegin.bind(this)}
            enableContextMenu={false}
            allowImage={false}
          >
            <SheetsDirective>
              <SheetDirective
                frozenRows={5}
                frozenColumns={1}
                showHeaders={false}
                rowCount={PCSheetRowCount}
                isProtected={true}
                protectSettings={protectSettings}
              >
                <RowsDirective>
                  <RowDirective>
                    <CellsDirective>
                      <CellDirective value="Char Name"></CellDirective>
                    </CellsDirective>
                  </RowDirective>

                  <RowDirective>
                    <CellsDirective>
                      <CellDirective value="Upper Spec limit"></CellDirective>
                    </CellsDirective>
                  </RowDirective>

                  <RowDirective>
                    <CellsDirective>
                      <CellDirective value="Target"></CellDirective>
                    </CellsDirective>
                  </RowDirective>

                  <RowDirective>
                    <CellsDirective>
                      <CellDirective value="Lower Spec limit"></CellDirective>
                    </CellsDirective>
                  </RowDirective>

                  <RowDirective>
                    <CellsDirective>
                      <CellDirective value="Sub Group Size"></CellDirective>
                    </CellsDirective>
                  </RowDirective>
                </RowsDirective>
              </SheetDirective>
            </SheetsDirective>
          </SpreadsheetComponent>
        </div>
      </div>
    </>
  );
};

export default PCGrid;
