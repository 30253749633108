import { oz_To_g, sqcm_To_sqin } from "../../FuncsForConversions/Speed/index";

import Calc from "./CalculationFunctions";

function in_To_mm(value) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 25.4;
  return isNaN(parseFloat(value)) ? "NA" : parseFloat(CalcValue);
}

export function mm_To_in(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) / 25.4;
  // console.log( value )
  return parseFloat(CalcValue);
}

const CreateProcessTGTMachineData = ({
  MachineDataList,
  MMData,
  StoredUnits,
  SelectedMachine,
  setProcessTgtMachineData,
  ProcessRefMachineData,
}) => {
  let TgtMachineData = {};

  // console.log(MachineDataList, ProcessRefMachineData)

  // 6-7, 10-11
  ProcessRefMachineData.forEach((Param) => {
    if (Param["Molding Parameter"] === "Obtained Cushion") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value:
              parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) === 6
                ? MMData.Input_Desired_Cushion_mm.value
                : MMData.Input_Desired_Cushion_in.value,

            unit_id:
              parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) === 6
                ? StoredUnits?.Unit_Data[1].find(
                    (unit) =>
                      unit.unit_id ===
                      parseInt(MMData.Input_Desired_Cushion_mm.unit_id)
                  )?.unit_name
                : StoredUnits?.Unit_Data[1].find(
                    (unit) =>
                      unit.unit_id ===
                      parseInt(MMData.Input_Desired_Cushion_in.unit_id)
                  )?.unit_name,
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Transfer") {
      const transferPromises = MachineDataList.map((Machine, idx) => {
        return Calc.CalculateMoldParam({
          Tgt_Mach_Screw_Dia:
            parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) === 6 &&
            parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) === 11
              ? in_To_mm(parseFloat(Machine.Screw_Diameter), 2)
              : parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) === 7 &&
                parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) === 10
              ? mm_To_in(parseFloat(Machine.Screw_Diameter), 2)
              : parseFloat(Machine.Screw_Diameter),

          Tgt_Mach_Unit: parseInt(
            Machine.Unit_Data.Max_Injection_Speed.unit_id
          ),

          RefParam1: ProcessRefMachineData.find(
            (MachData) => MachData["Molding Parameter"] === "Transfer"
          ).value,

          RefParam2: ProcessRefMachineData.find(
            (MachData) => MachData["Molding Parameter"] === "Obtained Cushion"
          ).value,

          TgtParam:
            parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) === 6
              ? MMData.Input_Desired_Cushion_mm.value
              : MMData.Input_Desired_Cushion_in.value,

          SelectedMachine: SelectedMachine,

          Param: "Transfer",
        }).then((CalcTransferVal) => {
          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcTransferVal[0],
            unit_id:
              parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) === 6
                ? StoredUnits?.Unit_Data[1].find(
                    (unit) =>
                      unit.unit_id ===
                      parseInt(MMData.Input_Desired_Cushion_mm.unit_id)
                  )?.unit_name
                : StoredUnits?.Unit_Data[1].find(
                    (unit) =>
                      unit.unit_id ===
                      parseInt(MMData.Input_Desired_Cushion_in.unit_id)
                  )?.unit_name,
          };

          return data;
        });
      });

      // Use Promise.all to ensure all calculations are completed
      Promise.all(transferPromises).then((transferData) => {
        TgtMachineData["Transfer"] = transferData;

        // console.log(transferData);

        ProcessRefMachineData.forEach((Param) => {
          const Posn4Promises = MachineDataList.map((Machine, idx) => {
            return Calc.CalculateMoldParam({
              Tgt_Mach_Screw_Dia:
                parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) === 6 &&
                parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) === 11
                  ? in_To_mm(parseFloat(Machine.Screw_Diameter), 2)
                  : parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) ===
                      7 &&
                    parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) === 10
                  ? mm_To_in(parseFloat(Machine.Screw_Diameter), 2)
                  : parseFloat(Machine.Screw_Diameter),

              Tgt_Mach_Unit: parseInt(
                Machine.Unit_Data.Max_Injection_Speed.unit_id
              ),

              RefParam1: ProcessRefMachineData.find(
                (MachData) => MachData["Molding Parameter"] === "Posn4"
              ).value
                ? ProcessRefMachineData.find(
                    (MachData) => MachData["Molding Parameter"] === "Posn4"
                  ).value
                : 0,

              RefParam2: ["Transfer"].reduce((acc, param) => {
                const value =
                  ProcessRefMachineData.find(
                    (MachData) => MachData["Molding Parameter"] === param
                  )?.value === "NA"
                    ? ""
                    : ProcessRefMachineData.find(
                        (MachData) => MachData["Molding Parameter"] === param
                      )?.value;

                return acc || value;
              }, 0),

              TgtParam: TgtMachineData["Transfer"][idx].value
                ? TgtMachineData["Transfer"][idx].value
                : 0,

              SelectedMachine: SelectedMachine,

              Param: "Posn4",
            }).then((Posn4Value) => {
              let data = {
                Machine: Machine.Machine_Number,

                value: Posn4Value[0],

                unit_id:
                  parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) === 6
                    ? StoredUnits?.Unit_Data[1].find(
                        (unit) =>
                          unit.unit_id ===
                          parseInt(MMData.Input_Desired_Cushion_mm.unit_id)
                      )?.unit_name
                    : StoredUnits?.Unit_Data[1].find(
                        (unit) =>
                          unit.unit_id ===
                          parseInt(MMData.Input_Desired_Cushion_in.unit_id)
                      )?.unit_name,
              };
              return data;
            });
          });

          Promise.all(Posn4Promises).then((values) => {
            TgtMachineData["Posn4"] = values;

            const Posn3Promises = MachineDataList.map((Machine, idx) => {
              return Calc.CalculateMoldParam({
                Tgt_Mach_Screw_Dia:
                  parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) ===
                    6 &&
                  parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) === 11
                    ? in_To_mm(parseFloat(Machine.Screw_Diameter), 2)
                    : parseInt(
                        Machine.Unit_Data.Max_Injection_Speed.unit_id
                      ) === 7 &&
                      parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) === 10
                    ? mm_To_in(parseFloat(Machine.Screw_Diameter), 2)
                    : parseFloat(Machine.Screw_Diameter),

                Tgt_Mach_Unit: parseInt(
                  Machine.Unit_Data.Max_Injection_Speed.unit_id
                ),

                RefParam1: ProcessRefMachineData.find(
                  (MachData) => MachData["Molding Parameter"] === "Posn3"
                ).value
                  ? ProcessRefMachineData.find(
                      (MachData) => MachData["Molding Parameter"] === "Posn3"
                    ).value
                  : 0,

                RefParam2: ["Posn4", "Transfer"].reduce((acc, param) => {
                  const value =
                    ProcessRefMachineData.find(
                      (MachData) => MachData["Molding Parameter"] === param
                    )?.value === "NA"
                      ? ""
                      : ProcessRefMachineData.find(
                          (MachData) => MachData["Molding Parameter"] === param
                        )?.value;

                  return acc || value;
                }, 0),

                TgtParam: TgtMachineData["Posn4"][idx].value
                  ? TgtMachineData["Posn4"][idx].value
                  : TgtMachineData["Transfer"][idx].value
                  ? TgtMachineData["Transfer"][idx].value
                  : 0,

                SelectedMachine: SelectedMachine,

                Param: "Posn3",
              }).then((Posn3Value) => {
                let data = {
                  Machine: Machine.Machine_Number,

                  value: Posn3Value[0],

                  unit_id:
                    parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) ===
                    6
                      ? StoredUnits?.Unit_Data[1].find(
                          (unit) =>
                            unit.unit_id ===
                            parseInt(MMData.Input_Desired_Cushion_mm.unit_id)
                        )?.unit_name
                      : StoredUnits?.Unit_Data[1].find(
                          (unit) =>
                            unit.unit_id ===
                            parseInt(MMData.Input_Desired_Cushion_in.unit_id)
                        )?.unit_name,
                };
                return data;
              });
            });

            Promise.all(Posn3Promises).then((values) => {
              TgtMachineData["Posn3"] = values;

              const Posn2Promises = MachineDataList.map((Machine, idx) => {
                return Calc.CalculateMoldParam({
                  Tgt_Mach_Screw_Dia:
                    parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) ===
                      6 &&
                    parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) === 11
                      ? in_To_mm(parseFloat(Machine.Screw_Diameter), 2)
                      : parseInt(
                          Machine.Unit_Data.Max_Injection_Speed.unit_id
                        ) === 7 &&
                        parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) ===
                          10
                      ? mm_To_in(parseFloat(Machine.Screw_Diameter), 2)
                      : parseFloat(Machine.Screw_Diameter),

                  Tgt_Mach_Unit: parseInt(
                    Machine.Unit_Data.Max_Injection_Speed.unit_id
                  ),

                  RefParam1: ProcessRefMachineData.find(
                    (MachData) => MachData["Molding Parameter"] === "Posn2"
                  ).value
                    ? ProcessRefMachineData.find(
                        (MachData) => MachData["Molding Parameter"] === "Posn2"
                      ).value
                    : 0,

                  RefParam2: ["Posn3", "Posn4", "Transfer"].reduce(
                    (acc, param) => {
                      const value =
                        ProcessRefMachineData.find(
                          (MachData) => MachData["Molding Parameter"] === param
                        )?.value === "NA"
                          ? ""
                          : ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] === param
                            )?.value;

                      return acc || value;
                    },
                    0
                  ),

                  TgtParam: TgtMachineData["Posn3"][idx].value
                    ? TgtMachineData["Posn3"][idx].value
                    : TgtMachineData["Posn4"][idx].value
                    ? TgtMachineData["Posn4"][idx].value
                    : TgtMachineData["Transfer"][idx].value
                    ? TgtMachineData["Transfer"][idx].value
                    : 0,

                  SelectedMachine: SelectedMachine,

                  Param: "Posn2",
                }).then((Posn2Value) => {
                  let data = {
                    Machine: Machine.Machine_Number,

                    value: Posn2Value[0],

                    unit_id:
                      parseInt(
                        Machine.Unit_Data.Max_Injection_Speed.unit_id
                      ) === 6
                        ? StoredUnits?.Unit_Data[1].find(
                            (unit) =>
                              unit.unit_id ===
                              parseInt(MMData.Input_Desired_Cushion_mm.unit_id)
                          )?.unit_name
                        : StoredUnits?.Unit_Data[1].find(
                            (unit) =>
                              unit.unit_id ===
                              parseInt(MMData.Input_Desired_Cushion_in.unit_id)
                          )?.unit_name,
                  };
                  return data;
                });
              });

              Promise.all(Posn2Promises).then((values) => {
                TgtMachineData["Posn2"] = values;

                const Posn1Promises = MachineDataList.map((Machine, idx) => {
                  return Calc.CalculateMoldParam({
                    Tgt_Mach_Screw_Dia:
                      parseInt(
                        Machine.Unit_Data.Max_Injection_Speed.unit_id
                      ) === 6 &&
                      parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) === 11
                        ? in_To_mm(parseFloat(Machine.Screw_Diameter), 2)
                        : parseInt(
                            Machine.Unit_Data.Max_Injection_Speed.unit_id
                          ) === 7 &&
                          parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) ===
                            10
                        ? mm_To_in(parseFloat(Machine.Screw_Diameter), 2)
                        : parseFloat(Machine.Screw_Diameter),

                    Tgt_Mach_Unit: parseInt(
                      Machine.Unit_Data.Max_Injection_Speed.unit_id
                    ),

                    RefParam1: ProcessRefMachineData.find(
                      (MachData) => MachData["Molding Parameter"] === "Posn1"
                    ).value
                      ? ProcessRefMachineData.find(
                          (MachData) =>
                            MachData["Molding Parameter"] === "Posn1"
                        ).value
                      : 0,

                    RefParam2: ["Posn2", "Posn3", "Posn4", "Transfer"].reduce(
                      (acc, param) => {
                        const value =
                          ProcessRefMachineData.find(
                            (MachData) =>
                              MachData["Molding Parameter"] === param
                          )?.value === "NA"
                            ? ""
                            : ProcessRefMachineData.find(
                                (MachData) =>
                                  MachData["Molding Parameter"] === param
                              )?.value;

                        return acc || value;
                      },
                      0
                    ),

                    TgtParam: TgtMachineData["Posn2"][idx].value
                      ? TgtMachineData["Posn2"][idx].value
                      : TgtMachineData["Posn3"][idx].value
                      ? TgtMachineData["Posn3"][idx].value
                      : TgtMachineData["Posn4"][idx].value
                      ? TgtMachineData["Posn4"][idx].value
                      : TgtMachineData["Transfer"][idx].value
                      ? TgtMachineData["Transfer"][idx].value
                      : 0,

                    SelectedMachine: SelectedMachine,

                    Param: "Posn1",
                  }).then((Posn1Value) => {
                    let data = {
                      Machine: Machine.Machine_Number,

                      value: Posn1Value[0],

                      unit_id:
                        parseInt(
                          Machine.Unit_Data.Max_Injection_Speed.unit_id
                        ) === 6
                          ? StoredUnits?.Unit_Data[1].find(
                              (unit) =>
                                unit.unit_id ===
                                parseInt(
                                  MMData.Input_Desired_Cushion_mm.unit_id
                                )
                            )?.unit_name
                          : StoredUnits?.Unit_Data[1].find(
                              (unit) =>
                                unit.unit_id ===
                                parseInt(
                                  MMData.Input_Desired_Cushion_in.unit_id
                                )
                            )?.unit_name,
                    };

                    return data;
                  });
                });

                Promise.all(Posn1Promises).then((values) => {
                  TgtMachineData["Posn1"] = values;

                  const shotSizePromises = MachineDataList.map(
                    (Machine, idx) => {
                      return Calc.CalculateMoldParam({
                        Tgt_Mach_Screw_Dia:
                          parseInt(
                            Machine.Unit_Data.Max_Injection_Speed.unit_id
                          ) === 6 &&
                          parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) ===
                            11
                            ? in_To_mm(parseFloat(Machine.Screw_Diameter), 2)
                            : parseInt(
                                Machine.Unit_Data.Max_Injection_Speed.unit_id
                              ) === 7 &&
                              parseInt(
                                Machine.Unit_Data.Screw_Diameter.unit_id
                              ) === 10
                            ? mm_To_in(parseFloat(Machine.Screw_Diameter), 2)
                            : parseFloat(Machine.Screw_Diameter),

                        Tgt_Mach_Unit: parseInt(
                          Machine.Unit_Data.Max_Injection_Speed.unit_id
                        ),

                        RefParam1: ProcessRefMachineData.find(
                          (MachData) =>
                            MachData["Molding Parameter"] === "Shot Size"
                        ).value
                          ? ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] === "Shot Size"
                            ).value
                          : "",

                        RefParam2: [
                          "Posn1",
                          "Posn2",
                          "Posn3",
                          "Posn4",
                          "Transfer",
                        ].reduce((acc, param) => {
                          const value =
                            ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] === param
                            )?.value === "NA"
                              ? ""
                              : ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] === param
                                )?.value;

                          return acc || value;
                        }, ""),

                        TgtParam: TgtMachineData["Posn1"][idx].value
                          ? TgtMachineData["Posn1"][idx].value
                          : TgtMachineData["Posn2"][idx].value
                          ? TgtMachineData["Posn2"][idx].value
                          : TgtMachineData["Posn3"][idx].value
                          ? TgtMachineData["Posn3"][idx].value
                          : TgtMachineData["Posn4"][idx].value
                          ? TgtMachineData["Posn4"][idx].value
                          : TgtMachineData["Transfer"][idx].value
                          ? TgtMachineData["Transfer"][idx].value
                          : "",

                        SelectedMachine: SelectedMachine,

                        Param: "Shot Size",
                      }).then((shotSizeValue) => {
                        let data = {
                          Machine: Machine.Machine_Number,

                          value: shotSizeValue[0],

                          unit_id:
                            parseInt(
                              Machine.Unit_Data.Max_Injection_Speed.unit_id
                            ) === 6
                              ? StoredUnits?.Unit_Data[1].find(
                                  (unit) =>
                                    unit.unit_id ===
                                    parseInt(
                                      MMData.Input_Desired_Cushion_mm.unit_id
                                    )
                                )?.unit_name
                              : StoredUnits?.Unit_Data[1].find(
                                  (unit) =>
                                    unit.unit_id ===
                                    parseInt(
                                      MMData.Input_Desired_Cushion_in.unit_id
                                    )
                                )?.unit_name,
                        };

                        return data;
                      });
                    }
                  );

                  Promise.all(shotSizePromises).then((values) => {
                    TgtMachineData["Shot Size"] = values;

                    if (Param["Molding Parameter"] === "Injection Velocity 1") {
                      TgtMachineData[Param["Molding Parameter"]] =
                        MachineDataList.map((Machine, idx) => {
                          let CalcData = Calc.CalculateIVParams({
                            RefParam1: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] === "Shot Size"
                            ).value
                              ? ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] ===
                                    "Shot Size"
                                ).value
                              : "",

                            RefParam2: [
                              "Posn1",
                              "Posn2",
                              "Posn3",
                              "Posn3",
                              "Posn4",
                              "Transfer",
                            ].reduce((acc, param) => {
                              const value =
                                ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] === param
                                )?.value === "NA"
                                  ? ""
                                  : ProcessRefMachineData.find(
                                      (MachData) =>
                                        MachData["Molding Parameter"] === param
                                    )?.value;

                              return acc || value;
                            }, ""),

                            RefParam3: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] ===
                                "Injection Velocity 1"
                            ).value
                              ? parseFloat(
                                  ProcessRefMachineData.find(
                                    (MachData) =>
                                      MachData["Molding Parameter"] ===
                                      "Injection Velocity 1"
                                  ).value
                                )
                              : "",

                            TgtParam1: TgtMachineData["Shot Size"][idx].value
                              ? TgtMachineData["Shot Size"][idx].value
                              : "",

                            TgtParam2: TgtMachineData["Posn1"][idx].value
                              ? TgtMachineData["Posn1"][idx].value
                              : TgtMachineData["Posn2"][idx].value
                              ? TgtMachineData["Posn2"][idx].value
                              : TgtMachineData["Posn3"][idx].value
                              ? TgtMachineData["Posn3"][idx].value
                              : TgtMachineData["Posn4"][idx].value
                              ? TgtMachineData["Posn4"][idx].value
                              : TgtMachineData["Transfer"][idx].value
                              ? TgtMachineData["Transfer"][idx].value
                              : "",

                            MachineParam: parseFloat(
                              Machine.Max_Injection_Speed
                            ),
                          });

                          let data = {
                            Machine: Machine.Machine_Number,
                            MachineParam: parseFloat(
                              Machine.Max_Injection_Speed
                            ),

                            value: CalcData.Calc,

                            result: CalcData.result,

                            unit_id: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] ===
                                "Injection Velocity 1"
                            ).unit
                              ? ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] ===
                                    "Injection Velocity 1"
                                ).unit
                              : "",
                          };

                          return data;
                        });
                    } else if (
                      Param["Molding Parameter"] === "Injection Velocity 2"
                    ) {
                      TgtMachineData[Param["Molding Parameter"]] =
                        MachineDataList.map((Machine, idx) => {
                          let CalcData = Calc.CalculateIVParams({
                            RefParam1: ["Posn1"].reduce((acc, param) => {
                              const value =
                                ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] === param
                                )?.value === "NA"
                                  ? ""
                                  : ProcessRefMachineData.find(
                                      (MachData) =>
                                        MachData["Molding Parameter"] === param
                                    )?.value;

                              return acc || value;
                            }, ""),

                            RefParam2: [
                              "Posn2",
                              "Posn3",
                              "Posn4",
                              "Transfer",
                            ].reduce((acc, param) => {
                              const value =
                                ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] === param
                                )?.value === "NA"
                                  ? ""
                                  : ProcessRefMachineData.find(
                                      (MachData) =>
                                        MachData["Molding Parameter"] === param
                                    )?.value;

                              return acc || value;
                            }, ""),

                            RefParam3: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] ===
                                "Injection Velocity 2"
                            ).value
                              ? parseFloat(
                                  ProcessRefMachineData.find(
                                    (MachData) =>
                                      MachData["Molding Parameter"] ===
                                      "Injection Velocity 2"
                                  ).value
                                )
                              : "",

                            TgtParam1: TgtMachineData["Posn1"][idx].value
                              ? TgtMachineData["Posn1"][idx].value
                              : TgtMachineData["Shot Size"][idx].value
                              ? TgtMachineData["Shot Size"][idx].value
                              : "",

                            TgtParam2: TgtMachineData["Posn2"][idx].value
                              ? TgtMachineData["Posn2"][idx].value
                              : TgtMachineData["Posn3"][idx].value
                              ? TgtMachineData["Posn3"][idx].value
                              : TgtMachineData["Posn4"][idx].value
                              ? TgtMachineData["Posn4"][idx].value
                              : TgtMachineData["Transfer"][idx].value
                              ? TgtMachineData["Transfer"][idx].value
                              : "",

                            MachineParam: parseFloat(
                              Machine.Max_Injection_Speed
                            ),
                          });

                          let data = {
                            Machine: Machine.Machine_Number,
                            MachineParam: parseFloat(
                              Machine.Max_Injection_Speed
                            ),

                            value: CalcData.Calc,
                            result: CalcData.result,
                            unit_id: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] ===
                                "Injection Velocity 2"
                            ).unit
                              ? ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] ===
                                    "Injection Velocity 2"
                                ).unit
                              : "",
                          };

                          return data;
                        });
                    } else if (
                      Param["Molding Parameter"] === "Injection Velocity 3"
                    ) {
                      TgtMachineData[Param["Molding Parameter"]] =
                        MachineDataList.map((Machine, idx) => {
                          let CalcData = Calc.CalculateIVParams({
                            RefParam1: ["Posn2"].reduce((acc, param) => {
                              const value =
                                ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] === param
                                )?.value === "NA"
                                  ? ""
                                  : ProcessRefMachineData.find(
                                      (MachData) =>
                                        MachData["Molding Parameter"] === param
                                    )?.value;

                              return acc || value;
                            }, ""),

                            RefParam2: ["Posn3", "Posn4", "Transfer"].reduce(
                              (acc, param) => {
                                const value =
                                  ProcessRefMachineData.find(
                                    (MachData) =>
                                      MachData["Molding Parameter"] === param
                                  )?.value === "NA"
                                    ? ""
                                    : ProcessRefMachineData.find(
                                        (MachData) =>
                                          MachData["Molding Parameter"] ===
                                          param
                                      )?.value;

                                return acc || value;
                              },
                              ""
                            ),

                            RefParam3: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] ===
                                "Injection Velocity 3"
                            ).value
                              ? parseFloat(
                                  ProcessRefMachineData.find(
                                    (MachData) =>
                                      MachData["Molding Parameter"] ===
                                      "Injection Velocity 3"
                                  ).value
                                )
                              : "",

                            TgtParam1: TgtMachineData["Posn2"][idx].value
                              ? TgtMachineData["Posn2"][idx].value
                              : TgtMachineData["Shot Size"][idx].value
                              ? TgtMachineData["Shot Size"][idx].value
                              : TgtMachineData["Posn1"][idx].value
                              ? TgtMachineData["Posn1"][idx].value
                              : "",

                            TgtParam2: TgtMachineData["Posn3"][idx].value
                              ? TgtMachineData["Posn3"][idx].value
                              : TgtMachineData["Posn4"][idx].value
                              ? TgtMachineData["Posn4"][idx].value
                              : TgtMachineData["Transfer"][idx].value
                              ? TgtMachineData["Transfer"][idx].value
                              : "",

                            MachineParam: parseFloat(
                              Machine.Max_Injection_Speed
                            ),
                          });

                          let data = {
                            Machine: Machine.Machine_Number,
                            MachineParam: parseFloat(
                              Machine.Max_Injection_Speed
                            ),

                            value: CalcData.Calc,
                            result: CalcData.result,

                            unit_id: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] ===
                                "Injection Velocity 3"
                            ).unit
                              ? ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] ===
                                    "Injection Velocity 3"
                                ).unit
                              : "",
                          };

                          return data;
                        });
                    } else if (
                      Param["Molding Parameter"] === "Injection Velocity 4"
                    ) {
                      TgtMachineData[Param["Molding Parameter"]] =
                        MachineDataList.map((Machine, idx) => {
                          let CalcData = Calc.CalculateIVParams({
                            RefParam1: ["Posn3"].reduce((acc, param) => {
                              const value =
                                ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] === param
                                )?.value === "NA"
                                  ? ""
                                  : ProcessRefMachineData.find(
                                      (MachData) =>
                                        MachData["Molding Parameter"] === param
                                    )?.value;

                              return acc || value;
                            }, ""),

                            RefParam2: ["Posn4", "Transfer"].reduce(
                              (acc, param) => {
                                const value =
                                  ProcessRefMachineData.find(
                                    (MachData) =>
                                      MachData["Molding Parameter"] === param
                                  )?.value === "NA"
                                    ? ""
                                    : ProcessRefMachineData.find(
                                        (MachData) =>
                                          MachData["Molding Parameter"] ===
                                          param
                                      )?.value;

                                return acc || value;
                              },
                              ""
                            ),

                            RefParam3: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] ===
                                "Injection Velocity 4"
                            ).value
                              ? parseFloat(
                                  ProcessRefMachineData.find(
                                    (MachData) =>
                                      MachData["Molding Parameter"] ===
                                      "Injection Velocity 4"
                                  ).value
                                )
                              : "",

                            TgtParam1: TgtMachineData["Posn3"][idx].value
                              ? TgtMachineData["Posn3"][idx].value
                              : TgtMachineData["Posn2"][idx].value
                              ? TgtMachineData["Posn2"][idx].value
                              : TgtMachineData["Posn2"][idx].value
                              ? TgtMachineData["Posn2"][idx].value
                              : TgtMachineData["Posn1"][idx].value
                              ? TgtMachineData["Posn1"][idx].value
                              : "",

                            TgtParam2: TgtMachineData["Posn4"][idx].value
                              ? TgtMachineData["Posn4"][idx].value
                              : TgtMachineData["Transfer"][idx].value
                              ? TgtMachineData["Transfer"][idx].value
                              : "",

                            MachineParam: parseFloat(
                              Machine.Max_Injection_Speed
                            ),
                          });

                          let data = {
                            Machine: Machine.Machine_Number,
                            MachineParam: parseFloat(
                              Machine.Max_Injection_Speed
                            ),

                            value: CalcData.Calc,
                            result: CalcData.result,

                            unit_id: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] ===
                                "Injection Velocity 4"
                            ).unit
                              ? ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] ===
                                    "Injection Velocity 4"
                                ).unit
                              : "",
                          };

                          return data;
                        });
                    } else if (
                      Param["Molding Parameter"] === "Injection Velocity 5"
                    ) {
                      TgtMachineData[Param["Molding Parameter"]] =
                        MachineDataList.map((Machine, idx) => {
                          let CalcData = Calc.CalculateIVParams({
                            RefParam1: ["Posn4"].reduce((acc, param) => {
                              const value =
                                ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] === param
                                )?.value === "NA"
                                  ? ""
                                  : ProcessRefMachineData.find(
                                      (MachData) =>
                                        MachData["Molding Parameter"] === param
                                    )?.value;

                              return acc || value;
                            }, ""),

                            RefParam2: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] === "Transfer"
                            ).value
                              ? parseFloat(
                                  ProcessRefMachineData.find(
                                    (MachData) =>
                                      MachData["Molding Parameter"] ===
                                      "Transfer"
                                  ).value
                                )
                              : "",

                            RefParam3: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] ===
                                "Injection Velocity 5"
                            ).value
                              ? parseFloat(
                                  ProcessRefMachineData.find(
                                    (MachData) =>
                                      MachData["Molding Parameter"] ===
                                      "Injection Velocity 5"
                                  ).value
                                )
                              : "",

                            TgtParam1: TgtMachineData["Posn4"][idx].value
                              ? TgtMachineData["Posn4"][idx].value
                              : "",

                            TgtParam2: TgtMachineData["Transfer"][idx].value
                              ? TgtMachineData["Transfer"][idx].value
                              : "",

                            MachineParam: parseFloat(
                              Machine.Max_Injection_Speed
                            ),
                          });

                          let data = {
                            Machine: Machine.Machine_Number,
                            MachineParam: parseFloat(
                              Machine.Max_Injection_Speed
                            ),

                            value: CalcData.Calc,
                            result: CalcData.result,

                            unit_id: ProcessRefMachineData.find(
                              (MachData) =>
                                MachData["Molding Parameter"] ===
                                "Injection Velocity 5"
                            ).unit
                              ? ProcessRefMachineData.find(
                                  (MachData) =>
                                    MachData["Molding Parameter"] ===
                                    "Injection Velocity 5"
                                ).unit
                              : "",
                          };

                          return data;
                        });
                    }
                  });
                });
              });
            });
          });
        });
      });
    } else if (Param["Molding Parameter"] === "Screw Recovery Speed (rpm)") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.CalculateScrewRot({
            Tgt_Mach_Unit: parseInt(
              Machine.Unit_Data.Max_Injection_Speed.unit_id
            ),

            RefParam1: parseFloat(SelectedMachine.Screw_Diameter),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Screw Recovery Speed (rpm)"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] ===
                      "Screw Recovery Speed (rpm)"
                  ).value
                )
              : "",

            TgtParam:
              parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) === 6 &&
              parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) === 11
                ? in_To_mm(parseFloat(Machine.Screw_Diameter), 2)
                : parseInt(Machine.Unit_Data.Max_Injection_Speed.unit_id) ===
                    7 &&
                  parseInt(Machine.Unit_Data.Screw_Diameter.unit_id) === 10
                ? mm_To_in(parseFloat(Machine.Screw_Diameter), 2)
                : parseFloat(Machine.Screw_Diameter),

            MachineParam: parseFloat(Machine.Max_Screw_Rotation_Speed),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            MachineParam: parseFloat(Machine.Max_Screw_Rotation_Speed),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Screw Recovery Speed (rpm)"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] ===
                    "Screw Recovery Speed (rpm)"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Injection Pressure 1") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Injection Pressure 1"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Injection Pressure 1"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Injection Pressure 1"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Injection Pressure 1"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Injection Pressure 2") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Injection Pressure 2"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Injection Pressure 2"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Injection Pressure 2"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Injection Pressure 2"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Injection Pressure 3") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Injection Pressure 3"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Injection Pressure 3"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Injection Pressure 3"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Injection Pressure 3"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Injection Pressure 4") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Injection Pressure 4"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Injection Pressure 4"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Injection Pressure 4"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Injection Pressure 4"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Injection Pressure 5") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Injection Pressure 5"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Injection Pressure 5"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Injection Pressure 5"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Injection Pressure 5"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Pack/Hold Pressure 6") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 6"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Pack/Hold Pressure 6"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 6"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Pack/Hold Pressure 6"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Pack/Hold Pressure 1") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 1"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Pack/Hold Pressure 1"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 1"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Pack/Hold Pressure 1"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Pack/Hold Pressure 2") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 2"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Pack/Hold Pressure 2"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 2"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Pack/Hold Pressure 2"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Pack/Hold Pressure 3") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 3"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Pack/Hold Pressure 3"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 3"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Pack/Hold Pressure 3"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Pack/Hold Pressure 4") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 4"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Pack/Hold Pressure 4"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 4"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Pack/Hold Pressure 4"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Pack/Hold Pressure 5") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 5"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Pack/Hold Pressure 5"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] === "Pack/Hold Pressure 5"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Pack/Hold Pressure 5"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (Param["Molding Parameter"] === "Back Pressure") {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) => MachData["Molding Parameter"] === "Back Pressure"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] === "Back Pressure"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) => MachData["Molding Parameter"] === "Back Pressure"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] === "Back Pressure"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else if (
      Param["Molding Parameter"] === "Actual Peak Injection Pressure"
    ) {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let CalcData = Calc.ComputeIRFields({
            RefParam1: parseFloat(SelectedMachine.Intensification_Ratio),

            RefParam2: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] ===
                "Actual Peak Injection Pressure"
            ).value
              ? parseFloat(
                  ProcessRefMachineData.find(
                    (MachData) =>
                      MachData["Molding Parameter"] ===
                      "Actual Peak Injection Pressure"
                  ).value
                )
              : "",

            TgtParam: parseFloat(Machine.Intensification_Ratio),

            MachineParam: parseFloat(Machine.Max_Machine_Pressure),
          });

          let data = {
            Machine: Machine.Machine_Number,
            MachineParam: parseFloat(Machine.Max_Machine_Pressure),

            value: CalcData.Calc,
            result: CalcData.result,

            unit_id: ProcessRefMachineData.find(
              (MachData) =>
                MachData["Molding Parameter"] ===
                "Actual Peak Injection Pressure"
            ).unit
              ? ProcessRefMachineData.find(
                  (MachData) =>
                    MachData["Molding Parameter"] ===
                    "Actual Peak Injection Pressure"
                ).unit
              : "",
          };

          return data;
        }
      );
    } else {
      TgtMachineData[Param["Molding Parameter"]] = MachineDataList.map(
        (Machine, idx) => {
          let data = {
            Machine: Machine.Machine_Number,

            value: "",
            unit_id: "",
          };

          return data;
        }
      );
    }
  });

  setProcessTgtMachineData(TgtMachineData);
};

const GetData = ({
  TempMachineDataArray,
  StoredMachineData,
  TempMoldDataArray,
  ToggleModalStates,
}) => {
  return new Promise((resolve, reject) => {
    if (TempMoldDataArray.length > 0) {
      // console.log(StoredMachineData);

      for (let i = 0; i < StoredMachineData.length; i++) {
        TempMachineDataArray.push({
          ...StoredMachineData[i].Machine_Data,
          id: StoredMachineData[i].id,
          Material_Name: TempMoldDataArray[0].Material_Name,
          Material_Id: StoredMachineData[
            i
          ].Machine_Units_Data.Selected_Materials.Materials_Ids.filter(
            (Ids) => {
              return Ids === parseInt(TempMoldDataArray[0].Material_Id);
            }
          ),
          Unit_Data: StoredMachineData[i].Machine_Units_Data,
        });
      }

      resolve([TempMoldDataArray, TempMachineDataArray]);
    } else {
      ToggleModalStates("SelectMold");
    }
  });
};

const PerformCalculations = ({ MMData, TempMoldDataArray }) => {
  return new Promise((resolve, reject) => {
    let PartData = {
      Number_Of_Cavities: TempMoldDataArray[0].Part_Data[2],
      Weight_Of_One_Part: TempMoldDataArray[0].Part_Data[4],
      Number_Of_Runners: TempMoldDataArray[0].Part_Data[5],
      Runner_Weight: TempMoldDataArray[0].Part_Data[6],
      Part_Projected_Area: TempMoldDataArray[0].Part_Data[7],
      Runner_Projected_Area: TempMoldDataArray[0].Part_Data[8],
    };

    let PartArea = [],
      RunnerArea = [],
      RunnerWt = [],
      PartWeight = [];
    let CalcTonnage = null,
      CalcShotWt = null;

    // console.log(TempMoldDataArray[0]);

    for (let i = 1; i < TempMoldDataArray[0].Part_Details.length; i++) {
      // Calculate Part Area
      let partAreaValue =
        parseInt(TempMoldDataArray[0].Unit_Data.Part_Projected_Area.unit_id) ===
        2
          ? parseFloat(PartData.Part_Projected_Area[`Part${i}`]) *
            parseFloat(PartData.Number_Of_Cavities[`Part${i}`])
          : sqcm_To_sqin(
              parseFloat(PartData.Part_Projected_Area[`Part${i}`]),
              2
            ) * parseFloat(PartData.Number_Of_Cavities[`Part${i}`]);

      if (!isNaN(partAreaValue) && partAreaValue !== 0) {
        PartArea.push(partAreaValue);
      }

      // Calculate Runner Area
      let runnerAreaValue =
        parseInt(
          TempMoldDataArray[0].Unit_Data.Runner_Projected_Area.unit_id
        ) === 2
          ? parseFloat(PartData.Runner_Projected_Area[`Part${i}`]) *
            parseFloat(PartData.Number_Of_Runners[`Part${i}`])
          : sqcm_To_sqin(
              parseFloat(PartData.Runner_Projected_Area[`Part${i}`]),
              2
            ) * parseFloat(PartData.Number_Of_Runners[`Part${i}`]);

      if (!isNaN(runnerAreaValue) && runnerAreaValue !== 0) {
        RunnerArea.push(runnerAreaValue);
      }

      // Calculate Part Weight
      let partWeightValue =
        parseInt(TempMoldDataArray[0].Unit_Data.Weight_of_one_Part.unit_id) ===
        8
          ? parseFloat(PartData.Weight_Of_One_Part[`Part${i}`]) *
            parseFloat(PartData.Number_Of_Cavities[`Part${i}`])
          : oz_To_g(parseFloat(PartData.Weight_Of_One_Part[`Part${i}`]), 2) *
            parseFloat(PartData.Number_Of_Cavities[`Part${i}`]);

      if (!isNaN(partWeightValue) && partWeightValue !== 0) {
        PartWeight.push(partWeightValue);
      }

      // Calculate Runner Weight
      let runnerWtValue =
        parseInt(TempMoldDataArray[0].Unit_Data.Runner_Weight.unit_id) === 8
          ? parseFloat(PartData.Runner_Weight[`Part${i}`]) *
            parseFloat(PartData.Number_Of_Runners[`Part${i}`])
          : oz_To_g(parseFloat(PartData.Runner_Weight[`Part${i}`]), 2) *
            parseFloat(PartData.Number_Of_Runners[`Part${i}`]);

      if (!isNaN(runnerWtValue) && runnerWtValue !== 0) {
        RunnerWt.push(runnerWtValue);
      }

      // Update Calculated Values only if valid values were pushed
      if (PartArea[i - 1] && RunnerArea[i - 1]) {
        CalcTonnage +=
          parseFloat(PartArea[i - 1]) + parseFloat(RunnerArea[i - 1]);
      }

      if (PartWeight[i - 1] && RunnerWt[i - 1]) {
        CalcShotWt +=
          parseFloat(PartWeight[i - 1]) + parseFloat(RunnerWt[i - 1]);
      }
    }

    let MoldTonnage =
      CalcTonnage && MMData.Required_Tonnage
        ? parseFloat(MMData.Required_Tonnage) * CalcTonnage
        : "*";

    let MoldShotWtGPPS =
      CalcShotWt && TempMoldDataArray[0]
        ? (parseFloat(CalcShotWt) /
            parseFloat(TempMoldDataArray[0].Material_Data.Specific_Gravity)) *
          1.06
        : "*";

    let MoldShotWtACT =
      CalcShotWt && TempMoldDataArray[0] ? parseFloat(CalcShotWt) : "*";

    resolve({
      Moldtonnage: MoldTonnage,
      MoldshotWeightGPPS: MoldShotWtGPPS,
      MoldshotWeightACT: MoldShotWtACT,
    });
  });
};

export default {
  GetData,
  PerformCalculations,
  CreateProcessTGTMachineData,
};
